import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { LessonState } from '../models/lesson';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchLatestLastLesson = createAsyncThunk(
  'lesson/lastLesson',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const time = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const { data } = await request.get(
        `/api/v2/player/lessons/latest?timeZone=${time}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: LessonState = {
  loading: false,
  error: null,
  detail: null,
  code: null,
  isRedirect: '',
  isArchived: false,
  lessonDetail: [],
  webLessonId: '',
};

export const lastLessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLatestLastLesson.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchLatestLastLesson.fulfilled, (state, action) => {
        state.detail = action.payload.data;
        state.code = action.payload.code;
        state.loading = false;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
          state.loading = false;
        }
      );
  },
});

export const selectLatestLessonData = (state: RootState) =>
  state.lastLesson.detail;
export const loadingLatestLessonData = (state: RootState) =>
  state.lastLesson.loading;
export const selectCodeLoadLessonData = (state: RootState) =>
  state.lastLesson.code;
export default lastLessonSlice.reducer;
