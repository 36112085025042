import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BannerCategoryState } from '../models/shop';
import request from '../utils/request';
import { RootState } from '../utils/store';

interface BannerCategory {
  catID: any;
}

export const fetchBannerCategory = createAsyncThunk(
  'banner/bannerCategory',
  async (payload: BannerCategory, thunkAPI) => {
    try {
      const { data }= await request.get(
        `/api/v3/shop/category/layout/${payload.catID}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: BannerCategoryState = {
  loading: false,
  error: null,
  data: null
};

export const bannerCategorySlice = createSlice({
  name: 'dynamic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBannerCategory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchBannerCategory.fulfilled, (state, action) => {
        state.data = action.payload.data.data;
        state.loading = false;
      });
  }
});

export const selectBannerCategory = (state: RootState) => state.bannerCategory.data;
export const loadingBannerCategory = (state: RootState) => state.bannerCategory.loading;
export default bannerCategorySlice.reducer;