import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const requestSupport = createAsyncThunk(
  'support/requestSupport',
  async (params: FormData, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/helpdesk/ticket`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAboutGolftec = createAsyncThunk(
  'support/fetchAboutGolftec',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/v3/layout/ABOUT_GOLFTEC`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchGolftecCare = createAsyncThunk(
  'support/fetchGolftecCare',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/v3/layout/GOLFTEC_CARES`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchGolftecFAQ = createAsyncThunk(
  'support/fetchGolftecFAQ',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/v3/layout/FAQ`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const submitAnswer = createAsyncThunk(
  'support/submitAnswer',
  async (params: { rating: number; comment: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/survey/satisfaction`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchUserAgreement = createAsyncThunk(
  'support/fetchUserAgreement',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/v3/layout/USER_AGREEMENT`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface DataFAQ {
  tabTitle: string;
  description: DataFAQChild[];
}

export interface DataFAQChild {
  heading: string;
  description: string;
}

type SupportState = {
  statusSupportModal: boolean;
  dataAboutGolftec: null | string;
  dataGolftecCare: null | string;
  dataGolftecFAQ: null | DataFAQ[];
  dataUserAgreement: null | string;
  modeEmbedOnProd: boolean;
  modeEmbedOnQA: boolean;
};

const initialState: SupportState = {
  statusSupportModal: false,
  dataAboutGolftec: null,
  dataGolftecCare: null,
  dataGolftecFAQ: null,
  dataUserAgreement: null,
  modeEmbedOnProd: false,
  modeEmbedOnQA: false,
};

export const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    changeSupportModalStatus: (state, action) => {
      state.statusSupportModal = action.payload;
    },
    changeStatusEmbedOnProd: (state, action) => {
      state.modeEmbedOnProd = action.payload;
    },
    changeStatusEmbedOnQA: (state, action) => {
      state.modeEmbedOnQA = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAboutGolftec.fulfilled, (state, action) => {
      if (action.payload.data.desktop.length > 0)
        state.dataAboutGolftec = action.payload.data.desktop[0].data.content;
    });
    builder.addCase(fetchGolftecCare.fulfilled, (state, action) => {
      if (action.payload.data.desktop.length > 0)
        state.dataGolftecCare = action.payload.data.desktop[0].data.content;
    });
    builder.addCase(fetchGolftecFAQ.fulfilled, (state, action) => {
      if (action.payload.data.desktop.length > 0)
        state.dataGolftecFAQ = action.payload.data.desktop[0].data;
    });
    builder
      .addCase(fetchUserAgreement.fulfilled, (state, action) => {
        if (action.payload.data.desktop.length > 0)
          state.dataUserAgreement = action.payload.data.desktop[0].data.content;
      })
      .addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  changeSupportModalStatus,
  changeStatusEmbedOnProd,
  changeStatusEmbedOnQA,
} = supportSlice.actions;

export const selectSupportModalStatus = (state: RootState) =>
  state.support.statusSupportModal;
export const selectDataAboutGolftec = (state: RootState) =>
  state.support.dataAboutGolftec;
export const selectDataGolftecCare = (state: RootState) =>
  state.support.dataGolftecCare;
export const selectDataGolftecFAQ = (state: RootState) =>
  state.support.dataGolftecFAQ;
export const selectDataUserAgreement = (state: RootState) =>
  state.support.dataUserAgreement;
export const selectStatusEmbedOnProd = (state: RootState) =>
  state.support.modeEmbedOnProd;
export const selectStatusEmbedOnQA = (state: RootState) =>
  state.support.modeEmbedOnQA;

export default supportSlice.reducer;
