import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchAboutData = createAsyncThunk(
  'about/aboutData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('api/my-game/about');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface AboutDataState {
  question: { id :number; value:string;type:string};
  defaultOption: {
    id:number;
    value:string;
  }[];
  answers: string[];
}

interface AboutData {
  data:  AboutDataState[];
  isAnswered: boolean;
  isOver90days: boolean;
}

interface AboutState {
  loading: boolean;
  error: null | number;
  data: null | AboutData;
}

const initialState: AboutState = {
  loading: false,
  error: null,
  data: null,
};

export const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAboutData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAboutData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.data = data;
        state.loading = false;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
        }
      );
  },
});

export const selectAboutData = (state: RootState) =>
  state.about.data as AboutData;
export const loadingAboutData = (state: RootState) => state.about.loading;
export default aboutSlice.reducer;
