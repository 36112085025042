import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export interface DownloadVideoData {
  lessonId: string;
  videoType: string;
  id: string;
}

export const fetchDownloadVideo = createAsyncThunk(
  'download/downloadLesson',
  async (payload: DownloadVideoData, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/download/lesson-video?lessonId=${payload.lessonId}&videoType=${payload.videoType}&videoId=${payload.id}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface DownloadVideoState {
  loading: boolean;
  error: any;
  data: any | DownloadVideoData;
}

const initialState: DownloadVideoState = {
  loading: false,
  error: null,
  data: null,
};

export const downloadVideoSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDownloadVideo.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchDownloadVideo.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
        }
      );
  },
});

export const loadingDownloadVideo = (state: RootState) =>
  state.downloadVideo.loading;
export default downloadVideoSlice.reducer;
