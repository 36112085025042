import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';
import { setMonth, setYear, startOfMonth } from 'date-fns';
import {
  BookedAppointment,
  fetchCenterListParams,
  fetchNearbyCentersParams,
  fetchOpenSlotParams,
  ScheduleState,
  Slot,
} from '../models/schedule';
import request from '../utils/request';
import type { RootState } from '../utils/store';
import { signOut } from './user';
import { AppointmentData } from './appointment';

export const fetchAllLessonType = createAsyncThunk(
  'schedule/fetchAllLessonType',
  async (arg, { rejectWithValue }) => {
    try {
      const response = await request('/api/v2/lesson/types');
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchCenterList = createAsyncThunk(
  'schedule/fetchCenterList',
  async (params: fetchCenterListParams, { rejectWithValue }) => {
    try {
      const url = `api/centers/nearest`;
      const response = await request.get(url, { params });
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchNearbyCenters = createAsyncThunk(
  'schedule/fetchNearbyCenters',
  async (params: fetchNearbyCentersParams, { getState, rejectWithValue }) => {
    try {
      const url = `api/closest-centers`;
      const userData = (getState() as RootState).user.data;
      const response = await request.get(url, {
        params: {
          ...params,
          ip: userData?.playerLocation.playerIp,
          locrequest: 'locservice',
          pagever: 'prod',
          scope: '',
        },
      });
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchOpenSlot = createAsyncThunk(
  'schedule/fetchAllOpenSlot',
  async (params: fetchOpenSlotParams, { rejectWithValue }) => {
    try {
      const url = `v2/schedule/getOpenings`;
      const response = await request.get(url, {
        params: {
          ...params,
          suppressLogging: true,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      });
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchSuggestedSlots = createAsyncThunk(
  'schedule/fetchSuggestedSlots',
  async (slots: Slot[], { rejectWithValue }) => {
    try {
      if (slots.length) {
        const response = await request.post('api/schedule/suggestions', slots);
        return response.data;
      } else {
        return rejectWithValue({ code: 'selectedSlotEmpty' });
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const sendBookingRequest = createAsyncThunk(
  'schedule/sendBookingRequest',
  async (slots: Slot[], { rejectWithValue }) => {
    try {
      const response = await request.post(
        'v2/schedule/bookAppointments',
        slots.map((i) => ({
          ...i,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }))
      );
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const overridePracticeBookingRequest = createAsyncThunk(
  'schedule/overridePracticeBookingRequest',
  async (
    params: {
      slot: Slot;
      replaceBookedAppointment: BookedAppointment[];
      wuci: string;
      resAppoinmentSchedule?: AppointmentData;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.post(
        'v2/schedule/bookAppointments',
        params.resAppoinmentSchedule
          ? [
              {
                ...params.slot,
                centerId: params.slot.requestedPayload.centerId,
                playerId: params.slot.requestedPayload.playerId,
                handedness: params.slot.requestedPayload.handedness,
                appointmentType: params.slot.requestedPayload.appointmentType,
                coachId: params.slot.requestedPayload.coachId,
                wuci: params.wuci,
                slotNumber: params.slot.requestedPayload.slotNumber,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                replaceBookedAppointments: [
                  ...params.replaceBookedAppointment,
                  params.resAppoinmentSchedule,
                ],
              },
            ]
          : [
              {
                ...params.slot,
                centerId: params.slot.requestedPayload.centerId,
                playerId: params.slot.requestedPayload.playerId,
                handedness: params.slot.requestedPayload.handedness,
                appointmentType: params.slot.requestedPayload.appointmentType,
                coachId: params.slot.requestedPayload.coachId,
                wuci: params.wuci,
                slotNumber: params.slot.requestedPayload.slotNumber,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                replaceBookedAppointments: params.replaceBookedAppointment,
              },
            ]
      );
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const reScheduleBookingRequest = createAsyncThunk(
  'schedule/reScheduleBookingRequest',
  async (
    params: {
      slot: Slot;
      replaceBookedAppointment: { id: String }[];
      wuci: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await request.post('v2/schedule/bookAppointments', [
        {
          ...params.slot,
          wuci: params.wuci,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          replaceBookedAppointments: params.replaceBookedAppointment,
        },
      ]);
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const fetchAllLastBooking = createAsyncThunk(
  'schedule/fetchAllLastBooking',
  async (arg, { rejectWithValue }) => {
    try {
      const response = await request('api/player/lesson/last-booking?size=5');
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const unPauseLessonPlan = createAsyncThunk(
  'schedule/unPauseLessonPlan',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.post('/api/player/game-plan/pause', {
        status: 'stop',
      });
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getInfoCountry = createAsyncThunk(
  'schedule/getInfoCountry',
  async (_, { rejectWithValue }) => {
    try {
      const response = await request.get('/api/info-country');
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState: ScheduleState = {
  centerList: null,
  nearbyCenters: [],
  lessonTypesLoading: false,
  lessonTypes: [],
  scheduleModalStatus: false,
  scheduleState: 'selection',
  availableSlotsLoading: false,
  openSlotLoadingError: null,
  openSlotLoadingMessage: null,
  availableSlots: [],
  selectedCenter: null,
  selectedCoach: null,
  selectedLessonType: null,
  selectedSlots: [],
  startDate: Date.now(),
  selectedDated: '',
  timeOfDay: '',
  suggestedSlots: [],
  suggestedLoading: false,
  locationUser: null,
  selectedLastBooking: null,
  lastBookingData: null,
  lastBookingLoading: false,
  bookNonStudentSelectSlot: false,
  sendBookingRequest: null,
  overrideBookingPractice: null,
  selectSlotMain: null,
  errorCenterList: false,
  scheduleCountryData: null,
  scheduleCenterFilter: null,
  statusFilterCenter: false,
  statusOpenCenter: false,
  statusOpenCoach: false,
  rescheduleAppointment: null,
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    onClickGoToMonth: (state, action) => {
      state.startDate = startOfMonth(
        setMonth(state.startDate, action.payload)
      ).getTime();
    },
    onClickGoToYear: (state, action) => {
      if (
        new Date(
          startOfMonth(setYear(state.startDate, action.payload))
        ).getFullYear() === new Date().getFullYear() &&
        new Date(
          startOfMonth(setYear(state.startDate, action.payload))
        ).getMonth() < new Date().getMonth()
      ) {
        state.startDate = Date.now();
      } else {
        state.startDate = startOfMonth(
          setYear(state.startDate, action.payload)
        ).getTime();
      }
    },
    changeScheduleFilterCenter: (state, action) => {
      state.scheduleCenterFilter = action.payload;
    },
    changeOpenFilterCenter: (state, action) => {
      state.statusFilterCenter = action.payload;
    },
    changeStatusOpenCenter: (state, action) => {
      state.statusOpenCenter = action.payload;
    },
    changeStatusOpenCoach: (state, action) => {
      state.statusOpenCoach = action.payload;
    },
    changeOpenSlotLoadingError: (state, action) => {
      state.openSlotLoadingError = action.payload;
    },
    changeSelectedSlots: (state, action) => {
      state.selectedSlots = action.payload;
    },
    changeScheduleState: (state, action) => {
      state.scheduleState = action.payload;
    },
    changeSelectedCoach: (state, action) => {
      state.selectedCoach = action.payload;
    },
    changeSelectedCenter: (state, action) => {
      state.selectedCenter = action.payload;
    },
    changeSelectedLessonType: (state, action) => {
      state.selectedLessonType = action.payload;
    },

    changeScheduleModalStatus: (state, action) => {
      state.scheduleModalStatus = action.payload;
    },
    changeStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    changeSelectedDate: (state, action) => {
      state.selectedDated = action.payload;
    },
    changeSuggestedSlots: (state, action) => {
      state.suggestedSlots = action.payload;
    },
    changeTimeOfDayFilter: (state, action) => {
      state.timeOfDay = action.payload;
    },
    changeCurrentCoordinator: (state, action) => {
      state.locationUser = action.payload;
    },
    changeBookNonStudentSelectSlot: (state, action) => {
      state.bookNonStudentSelectSlot = action.payload;
    },
    changeRescheduleAppointment: (state, action) => {
      state.rescheduleAppointment = action.payload;
    },
    resetScheduleBook: (state) => ({
      ...initialState,
      scheduleModalStatus: true,
      lessonTypes: state.lessonTypes,
      lastBookingData: state.lastBookingData,
      locationUser: state.locationUser,
      scheduleCountryData: state.scheduleCountryData,
    }),
    changeSelectedLastBooking: (state, action) => {
      state.selectedLastBooking = action.payload;
    },
    changeSelectSlotMain: (state, action) => {
      state.selectSlotMain = action.payload;
    },
    resetOpenSlotLoadingError: (state) => {
      state.openSlotLoadingError = null;
      state.openSlotLoadingMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLessonType.pending, (state) => {
        state.lessonTypesLoading = true;
      })
      .addCase(fetchAllLessonType.fulfilled, (state, action) => {
        state.lessonTypesLoading = false;
        state.lessonTypes = action.payload.data;
      });
    builder.addCase(fetchCenterList.fulfilled, (state, action) => {
      state.centerList = action.payload.data;
      state.errorCenterList = false;
    });
    builder.addCase(fetchCenterList.rejected, (state, action) => {
      state.errorCenterList = true;
    });
    builder.addCase(fetchNearbyCenters.fulfilled, (state, action) => {
      state.nearbyCenters = action.payload.data.centers;
    });
    builder.addCase(fetchOpenSlot.pending, (state) => {
      state.availableSlotsLoading = true;
    });
    builder.addCase(fetchOpenSlot.fulfilled, (state, action) => {
      state.availableSlotsLoading = false;
      state.availableSlots = action.payload.data;
      if (action.payload.code !== 0) {
        state.openSlotLoadingError = action.payload.code;
        state.openSlotLoadingMessage = action.payload.message;
      }
    });
    builder.addCase(fetchOpenSlot.rejected, (state, action) => {
      state.availableSlotsLoading = false;
      if (action.payload) {
        state.openSlotLoadingError = (action.payload as { code: number }).code;
        state.openSlotLoadingMessage = (
          action.payload as { message: string }
        ).message;
      }
    });
    builder
      .addCase(fetchAllLastBooking.pending, (state) => {
        state.lastBookingLoading = true;
      })
      .addCase(fetchAllLastBooking.fulfilled, (state, action) => {
        state.lastBookingData = action.payload.data;
      });
    builder.addCase(fetchSuggestedSlots.pending, (state) => {
      state.suggestedLoading = true;
    });
    builder.addCase(fetchSuggestedSlots.fulfilled, (state, action) => {
      state.suggestedLoading = false;
      state.suggestedSlots = action.payload.data;
    });
    builder.addCase(sendBookingRequest.fulfilled, (state, action) => {
      state.sendBookingRequest = action.payload.data;
    });
    builder.addCase(reScheduleBookingRequest.fulfilled, (state, action) => {
      state.sendBookingRequest = action.payload.data;
    });
    builder.addCase(
      overridePracticeBookingRequest.fulfilled,
      (state, action) => {
        state.overrideBookingPractice = action.payload.data;
      }
    );
    builder.addCase(getInfoCountry.fulfilled, (state, action) => {
      state.scheduleCountryData = action.payload.data;
    });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  changeScheduleFilterCenter,
  changeOpenFilterCenter,
  changeStatusOpenCenter,
  changeStatusOpenCoach,
  onClickGoToMonth,
  onClickGoToYear,
  changeOpenSlotLoadingError,
  changeSelectedSlots,
  changeScheduleState,
  changeSelectedCoach,
  changeSelectedCenter,
  changeSelectedLessonType,
  changeScheduleModalStatus,
  changeStartDate,
  changeSelectedDate,
  changeSuggestedSlots,
  changeTimeOfDayFilter,
  resetScheduleBook,
  changeSelectedLastBooking,
  changeCurrentCoordinator,
  changeBookNonStudentSelectSlot,
  resetOpenSlotLoadingError,
  changeSelectSlotMain,
  changeRescheduleAppointment,
} = scheduleSlice.actions;

export const selectLessonTypeList = (state: RootState) =>
  state.schedule.lessonTypes;

export const selectCenterList = (state: RootState) => state.schedule.centerList;

export const selectNearbyCenters = (state: RootState) =>
  state.schedule.nearbyCenters;

export const selectScheduleBookStatus = (state: RootState) =>
  state.schedule.scheduleModalStatus;

export const selectScheduleStep = (state: RootState) =>
  state.schedule.scheduleState;

export const selectAvailableSlotsLoading = (state: RootState) =>
  state.schedule.availableSlotsLoading;

export const selectOpenSlotsLoadingError = (state: RootState) =>
  state.schedule.openSlotLoadingError;

export const selectOpenSlotsLoadingMessage = (state: RootState) =>
  state.schedule.openSlotLoadingMessage;

export const selectAvailableSlots = (state: RootState) =>
  state.schedule.availableSlots;

export const selectStartDate = (state: RootState) => state.schedule.startDate;

export const selectSelectedDate = (state: RootState) =>
  state.schedule.selectedDated;

export const selectSelectedCenter = (state: RootState) =>
  state.schedule.selectedCenter;

export const selectSelectedCoach = (state: RootState) =>
  state.schedule.selectedCoach;

export const selectSelectedCoachList = (state: RootState) => {
  if (state.schedule.selectedCenter) {
    return state.schedule.selectedCenter.coaches;
  }
  return [];
};

export const selectSelectedLessonType = (state: RootState) =>
  state.schedule.selectedLessonType;

export const selectSelectedSlots = (state: RootState) =>
  state.schedule.selectedSlots;

export const selectSuggestedSlots = (state: RootState) =>
  state.schedule.suggestedSlots;

export const selectTimeOfDay = (state: RootState) => state.schedule.timeOfDay;

export const selectSuggestedLoading = (state: RootState) =>
  state.schedule.suggestedLoading;

export const selectLocationUser = (state: RootState) =>
  state.schedule.locationUser;

export const selectLastBookingData = (state: RootState) =>
  state.schedule.lastBookingData;

export const selectSelectedLastBooking = (state: RootState) =>
  state.schedule.selectedLastBooking;

export const selectBookNonStudentSelectSlot = (state: RootState) =>
  state.schedule.bookNonStudentSelectSlot;

export const selectResponseBookingRequest = (state: RootState) =>
  state.schedule.sendBookingRequest;

export const selectOverrideBookingPractice = (state: RootState) =>
  state.schedule.overrideBookingPractice;

export const selectSlotMain = (state: RootState) =>
  state.schedule.selectSlotMain;

export const selectErrorCenterList = (state: RootState) =>
  state.schedule.errorCenterList;

export const selectScheduleCountry = (state: RootState) =>
  state.schedule.scheduleCountryData;

export const selectStatusFilterCenter = (state: RootState) =>
  state.schedule.statusFilterCenter;

export const selectStatusOpenCenter = (state: RootState) =>
  state.schedule.statusOpenCenter;

export const selectStatusOpenCoach = (state: RootState) =>
  state.schedule.statusOpenCoach;

export const selectScheduleCenterFilter = (state: RootState) =>
  state.schedule.scheduleCenterFilter;

export const selectRescheduleAppointment = (state: RootState) =>
  state.schedule.rescheduleAppointment;

export default scheduleSlice.reducer;
