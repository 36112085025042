import { gql } from 'graphql.macro';
import {
  BillingAddressSubscriptionFragment,
  CartFields,
  CartPageFragment,
  ItemsAddressSubscriptionFragment,
  OrderItem,
  ProductDetail,
  ProductRelatedUpSell,
  ShippingAddressSubscriptionFragment,
} from './fragments';

export const GET_CUSTOMER_CART = gql`
  query {
    customerCart {
      alert {
        type
        message
        icon
      }

      ...CartPageFragment
      ...CartFields
    }
  }

  ${CartFields}
  ${CartPageFragment}
`;

export const GET_COUNTRY_BY_ID = gql`
  query ($id: String!) {
    country(id: $id) {
      available_regions {
        code
        id
        name
      }
    }
  }
`;

export const GET_CUSTOMER_INFO = gql`
  query {
    customer {
      addresses {
        city
        company
        country_code
        default_billing
        default_shipping
        extension_attributes {
          attribute_code
          value
        }
        fax
        firstname
        id
        lastname
        middlename
        postcode
        prefix
        region {
          region
          region_code
          region_id
        }
        region_id
        street
        suffix
        telephone
        vat_id
      }
      email
    }
  }
`;

export const GET_PRODUCT_BY_CATEGORY_ID = gql`
  query GetProductByCategoryId(
    $id: [String]!
    $pageSize: Int!
    $currentPage: Int
  ) {
    categories(filters: { ids: { in: $id } }) {
      items {
        id
        uid
        name
        golftec_category_code
        products(
          pageSize: $pageSize
          currentPage: $currentPage
          sort: { position: ASC }
        ) {
          total_count
          page_info {
            current_page
            page_size
          }
          items {
            golftec_product_tags {
              value
            }
            golftec_product_holes_number
            id
            name
            sku
            short_description {
              html
            }
            description {
              html
            }
            special_price
            price {
              regularPrice {
                amount {
                  currency
                  value
                }
              }
            }
            price_range {
              minimum_price {
                final_price {
                  value
                  currency
                }
                regular_price {
                  value
                  currency
                }
              }
              maximum_price {
                final_price {
                  value
                  currency
                }
                regular_price {
                  value
                  currency
                }
              }
            }
            price_tiers {
              quantity
              final_price {
                currency
                value
              }
              discount {
                amount_off
                percent_off
              }
            }
            thumbnail {
              url
              label
            }
            media_gallery {
              url
              label
              ... on ProductVideo {
                video_content {
                  media_type
                  video_provider
                  video_url
                  video_title
                  video_description
                  video_metadata
                }
              }
            }
            url_key
            __typename
            stock_status
            ... on SimpleProduct {
              options {
                ... on CustomizableDropDownOption {
                  uid
                  dropdown_type {
                    type
                  }
                  value {
                    title
                    uid
                    price_type
                    option_type_id
                    subscription {
                      length
                      installment_price
                      adjustment_price
                    }
                  }
                }
              }
            }
            ... on VirtualProduct {
              options {
                ... on CustomizableDropDownOption {
                  option_id
                  uid
                  title
                  __typename
                  dropdown_type {
                    type
                  }
                  value {
                    title
                    uid
                    price_type
                    option_type_id
                    subscription {
                      length
                      installment_price
                      adjustment_price
                    }
                  }
                }
              }
            }
            ... on ConfigurableProduct {
              options {
                ... on CustomizableDropDownOption {
                  uid
                  dropdown_type {
                    type
                  }
                  value {
                    title
                    uid
                    price_type
                    option_type_id
                    subscription {
                      length
                      installment_price
                      adjustment_price
                    }
                  }
                }
              }
              variants {
                product {
                  price_range {
                    minimum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                    maximum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                  }
                  price_tiers {
                    quantity
                    final_price {
                      currency
                      value
                    }
                    discount {
                      amount_off
                      percent_off
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORIES_BY_ID = gql`
  query getCategoriesById($id: [String!]) {
    categories(filters: { ids: { in: $id } }) {
      items {
        id
        name
        gallery_image {
          urlImage
        }
        image
        description
        url_key
        category_custom_url
      }
    }
  }
`;

export const GET_RESULTS_SEARCH_PRODUCT = gql`
  query getResultsSearchProduct(
    $inputText: String!
    $urlKeys: [String]
    $catUid: [String]
    $pageSize: Int
    $currentPage: Int
  ) {
    # Limit results to first three.
    products(
      search: $inputText
      filter: { url_key: { in: $urlKeys }, category_uid: { in: $catUid } }
      pageSize: $pageSize
      currentPage: $currentPage
      sort: { name: ASC }
    ) {
      total_count
      page_info {
        current_page
        page_size
      }
      items {
        ...ProductDetail
      }
    }
  }
  ${ProductDetail}
`;

export const GET_PRODUCT_BY_CATEGORY_ID_RELEVANCE = gql`
  query GetProductByCategoryId(
    $id: [String]!
    $pageSize: Int!
    $currentPage: Int
    $sortEnum: SortEnum
  ) {
    categories(filters: { ids: { in: $id } }) {
      items {
        id
        uid
        name
        golftec_category_code
        filterable_attribute {
          attribute_code
          attribute_label
          attribute_type
          attribute_values {
            value
            label
          }
        }
        products(
          pageSize: $pageSize
          currentPage: $currentPage
          sort: { relevance: $sortEnum }
        ) {
          total_count
          page_info {
            current_page
            page_size
          }
          items {
            golftec_product_tags {
              value
            }
            golftec_product_holes_number
            id
            name
            sku
            short_description {
              html
            }
            description {
              html
            }
            special_price
            price {
              regularPrice {
                amount {
                  currency
                  value
                }
              }
            }
            price_range {
              minimum_price {
                final_price {
                  value
                  currency
                }
                regular_price {
                  value
                  currency
                }
              }
              maximum_price {
                final_price {
                  value
                  currency
                }
                regular_price {
                  value
                  currency
                }
              }
            }
            price_tiers {
              quantity
              final_price {
                currency
                value
              }
              discount {
                amount_off
                percent_off
              }
            }
            thumbnail {
              url
              label
            }
            media_gallery {
              url
              label
              ... on ProductVideo {
                video_content {
                  media_type
                  video_provider
                  video_url
                  video_title
                  video_description
                  video_metadata
                }
              }
            }
            url_key
            __typename
            stock_status
            ... on SimpleProduct {
              options {
                ... on CustomizableDropDownOption {
                  uid
                  dropdown_type {
                    type
                  }
                  value {
                    title
                    uid
                    price_type
                    option_type_id
                    subscription {
                      length
                      installment_price
                      adjustment_price
                    }
                  }
                }
              }
            }
            use_center_section
            ... on GiftCardProduct {
              gift_card_type
              gift_type
              gift_price_type
              gift_from
              gift_to
              gift_price
              gift_value
              gift_dropdown
              gift_template_ids
              gift_templates {
                giftcard_template_id
                template_name
                notes
                style_color
                text_color
                design_pattern
                images
                created_at
                updated_at
                status
              }
            }
            ... on VirtualProduct {
              options {
                ... on CustomizableDropDownOption {
                  option_id
                  uid
                  title
                  __typename
                  dropdown_type {
                    type
                  }
                  value {
                    title
                    uid
                    price_type
                    option_type_id
                    subscription {
                      length
                      installment_price
                      adjustment_price
                    }
                  }
                }
              }
            }
            ... on ConfigurableProduct {
              options {
                ... on CustomizableDropDownOption {
                  uid
                  dropdown_type {
                    type
                  }
                  value {
                    title
                    uid
                    price_type
                    option_type_id
                    subscription {
                      length
                      installment_price
                      adjustment_price
                    }
                  }
                }
              }
              variants {
                product {
                  price_range {
                    minimum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                    maximum_price {
                      regular_price {
                        value
                        currency
                      }
                      final_price {
                        value
                        currency
                      }
                    }
                  }
                  price_tiers {
                    quantity
                    final_price {
                      currency
                      value
                    }
                    discount {
                      amount_off
                      percent_off
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_DETAIL = gql`
  query postProductDetail($urlKey: String!) {
    products(filter: { url_key: { eq: $urlKey }, return_parent_product: {eq: "false"} }) {
      items {
        id
        ...ProductDetail
        ...ProductRelatedUpSell
      }
      ${ProductDetail}
      ${ProductRelatedUpSell}
    }
  }

  
`;

export const GET_ROOT_CATEGORY_ID = gql`
  query getRootCategoryId {
    storeConfig {
      id
      root_category_id
    }
  }
`;

export const GET_NAVIGATION_MENU = gql`
  query GetNavigationMenu($id: Int!) {
    category(id: $id) {
      id
      name
      children {
        golftec_category_code
        id
        name
        image
        position
      }
    }
  }
`;

export const GET_SUB_CATEGORY = gql`
  query GetSubCategory($id: Int!) {
    category(id: $id) {
      id
      name
      uid
      children {
        id
        uid
        name
        image
        children {
          uid
          id
          name
          image
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_HISTORY = gql`
query SubScriptionHistory {
  subscriptions {
    additional {
      key
      value
    }
    created_at
    customer_id
    description
    entity_id
    frequency_count
    frequency_unit
    increment_id
    last_notified
    last_run
    length
     next_run
    quote_id
    run_count
    status
    store_id
    subtotal
    updated_at
    logs {
      agent_id
      created_at
      description
      log_id
      order_id
      order_increment_id
      status
    }
   
    quote {
    ...BillingAddressSubscriptionFragment
    customer_email
    entity_id
    grand_total
    ...ItemsAddressSubscriptionFragment
    payment {
      cc_exp_month
      cc_exp_year
      cc_last_4
      cc_owner
      cc_ss_issue
      cc_ss_owner
      cc_ss_start_month
      cc_ss_start_year
      cc_type
      entity_id
      method
      paypal_correlation_id
      paypal_payer_id
      paypal_payer_status
      po_number
      tokenbase_id
    }
    quote_currency_code
    ...ShippingAddressSubscriptionFragment
    subtotal
  }  
  }
  ${BillingAddressSubscriptionFragment}
  ${ShippingAddressSubscriptionFragment}
  ${ItemsAddressSubscriptionFragment}
  storeConfig{
    timezone
  }
}
`;

export const GET_SPECIAL_CATEGORIES = gql`
  query GetSpecialCategories($id: String!) {
    categoryList(
      filters: {
        ids: { in: [$id] }
        golftec_category_code: { match: "mega-menu-category" }
      }
    ) {
      id
      name
      golftec_category_code
      children {
        golftec_category_code
        id
        name
        image
        position
        children {
          id
          name
          image
          golftec_category_code
        }
      }
    }
    storeConfig {
      id
      root_category_id
    }
  }
`;

export const GET_PRODUCT_BY_SPECIAL_CATEGORY_ID = gql`
  query GetProductBySpecialCategoryId(
    $id: [String]!
    $pageSize: Int!
    $featuredPageSize: Int!
    $currentPage: Int
  ) {
    categories(filters: { ids: { in: $id } }) {
      items {
        id
        golftec_featured_products(pageSize: $featuredPageSize) {
          name
          url_key
          image
          position
        }
        featured_products
        products(pageSize: $pageSize, currentPage: $currentPage, sort: {}) {
          items {
            id
            name
            sku
            short_description {
              html
            }
            thumbnail {
              url
              label
            }
            url_key
            created_at
            uid
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_URL_KEY = gql`
  query getProductsByUrlKey($url_key: [String]!) {
    products(filter: { url_key: { in: $url_key } }) {
      items {
        ...ProductDetail
      }
    }
  }
  ${ProductDetail}
`;

export const GET_RETURNABLE_ORDERS = gql`
  query {
    getAmRmaOrderList {
      order_id
      label
      store_code
    }
  }
`;

export const GET_AMRMA_SETTING = gql`
  query {
    getAmRmaSettings {
      isEnabled
      isChatEnabled
      isReturnPolicyEnabled
      maxAttachmentSize
      isShowAdministratorContact
      shippingCarriers {
        code
        label
      }
      administratorPhoneNumber
      administratorEmail
      policyUrl
      urlPrefix
      customFieldsBlockTitle
      customFields {
        code
        title
      }
      allReasons {
        reason_id
        label
        payer
        position
      }
      allConditions {
        condition_id
        label
        payer
        position
      }
      allResolutions {
        resolution_id
        label
        status
        position
      }
    }
  }
`;

export const GET_CUSTOMER_ORDER_BY_ID = gql`
  query GetCustomerOrderById($number: String!) {
    customer {
      orders(filter: { number: { eq: $number } }) {
        items {
          ...OrderItem
        }
      }
    }
  }
  ${OrderItem}
`;

export const GET_AM_RMA_RETURNS = gql`
  query {
    getAmRmaReturnsForCurrentCustomer {
      request_id
      url_hash
      order_id
      order_increment_id
      store_id
      created_at
      modified_at
      status {
        label
      }
      customer_id
      customer_name
      manager_id
      custom_fields
      rating
      rating_comment
      note
      tracking_numbers {
        tracking_id
        request_id
        tracking_code
        tracking_number
        is_customer
      }
      shipping_label
      shipping_label_url
      packing_slip
      packing_slip_url
      ticket_number
      ticket_number_url
      level_cost
      status {
        status_id
        state
        label
        title
        color
      }
      request_items {
        request_item_id
        request_id
        reason_id
        condition_id
        resolution_id
        order_item_id
        qty
        item_status
        request_qty
        product_name
        product_url_key
      }
    }
  }
`;

export const GET_RETURN_MESSAGE = gql`
  query ($hash: String!, $last_message_id: Int!) {
    getAmRmaRequestMessages(hash: $hash, last_message_id: $last_message_id) {
      created
      files {
        filename
        filepath
      }
      is_manager
      is_system
      message
      message_id
      username
    }
  }
`;

export const GET_COUNTRIES = gql`
  query {
    countries {
      id
      two_letter_abbreviation
      three_letter_abbreviation
      full_name_locale
      full_name_english
      available_regions {
        id
        code
        name
      }
    }
  }
`;
