import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { fetchAboutData } from './getAbout';
import { signOut } from './user';

interface AddAboutData {
  dataBody: any;
}

export const fetchAddAbout = createAsyncThunk(
  'addAbout/addAboutData',
  async (payload: AddAboutData, { dispatch, rejectWithValue }) => {
    try {
      const response = await request.post(
        '/api/my-game/about',
        payload.dataBody
      );
      let data = await response;
      if (response.status === 200) {
        dispatch(fetchAboutData());
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface AddAboutState {
  loading: boolean;
  success: boolean;
}

const initialState: AddAboutState = {
  loading: false,
  success: false,
};

export const addAboutSlice = createSlice({
  name: 'addAbout',
  initialState,
  reducers: {
    resetAddAbout: ()  => ({...initialState})
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddAbout.pending, (state, action) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(fetchAddAbout.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.loading = false;
          state.success = false;
        }
      );
  },
});

export const {
  resetAddAbout
} = addAboutSlice.actions;

export const loadingAddAbout = (state: RootState) => state.addAbout.loading;
export const addAboutSuccess = (state: RootState) => state.addAbout.success;
export default addAboutSlice.reducer;
