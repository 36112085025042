import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchContentHome = createAsyncThunk(
  'dynamicHome/fetchContentHome',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('api/v3/layout/home');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface DynamicHome {
  desktop: [];
}

interface DynamicHomeState {
  loading: boolean;
  error: null | number;
  data: null | DynamicHome;
  statusRenderBraze: boolean;
}

const initialState: DynamicHomeState = {
  loading: false,
  error: null,
  data: null,
  statusRenderBraze: false,
};

export const dynamicHomeSlice = createSlice({
  name: 'dynamicHome',
  initialState,
  reducers: {
    setStatusContentCards: (state, action) => {
      state.statusRenderBraze = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentHome.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchContentHome.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.data = data;
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { setStatusContentCards } = dynamicHomeSlice.actions;

export const selectContentDynamicHome = (state: RootState) =>
  state.dynamicHome.data;
export const loadingDynamicHome = (state: RootState) =>
  state.dynamicHome.loading;
export const selectStatusContentCards = (state: RootState) =>
  state.dynamicHome.statusRenderBraze;

export default dynamicHomeSlice.reducer;
