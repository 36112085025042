import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchLessonPlan = createAsyncThunk(
  'lessonPlan/fetchLessonPlan',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('api/v3/player/game-plan');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const actionPauseLessonPlan = createAsyncThunk(
  'lessonPlan/unPauseLessonPlan',
  async (status: string, { rejectWithValue }) => {
    try {
      const response = await request.post('/api/player/game-plan/pause', {
        status: status,
      });
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error.response?.data);
    }
  }
);

export interface lessonPlan {
  name: string;
  type: string;
  totalPrice: string;
  isPending: boolean;
  isExpired: boolean;
  isPracticeAvailable: boolean;
  planEndDate: string;
  lessonPack: {
    monthToTake: number;
    numberOfLessons: string;
    numberOfTaken: string;
  };
  practicePack: { monthToTake: number };
  tecfitPack: { numberOfLessons: string };
}

export interface lessonPlanState {
  loading: boolean;
  data: lessonPlan[] | null;
  status: 'IN_PROGRESS' | 'RESCINDING' | 'PAUSING' | 'PAUSE_COMPLETED';
}

const initialState: lessonPlanState = {
  loading: false,
  data: null,
  status: 'IN_PROGRESS',
};

export const lessonPlanSlice = createSlice({
  name: 'lessonPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLessonPlan.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchLessonPlan.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data.items;
        if (action.payload.data.status) {
          state.status = action.payload.data.status;
        }
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectLessonPlanData = (state: RootState) => state.lessonPlan.data;
export const selectLoadingLessonPlanData = (state: RootState) =>
  state.lessonPlan.loading;
export const selectStatusLessonPlan = (state: RootState) =>
  state.lessonPlan.status;
export default lessonPlanSlice.reducer;
