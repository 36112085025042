import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { WebLessonsState } from '../models/lesson';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchLessonHistory = createAsyncThunk(
  'history/lessonHistory',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const time = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const { data } = await request.get(
        `/api/v2/player/lessons/history?timeZone=${time}`
      );

      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: WebLessonsState = {
  loading: false,
  code: null,
  error: null,
  lessons: null,
  lessonType: '',
  viewArchived: true,
  selectedLesson: null,
  selectTabLessonDetail: '',
};

export const lessonHistorySlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    changeLessonType: (state, action) => {
      state.lessonType = action.payload;
    },
    changeSelectViewArchived: (state, action) => {
      state.viewArchived = action.payload;
    },

    changeSelectTabLessonDetail: (state, action) => {
      state.selectTabLessonDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLessonHistory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchLessonHistory.fulfilled, (state, action) => {
        state.lessons = action.payload.data;
        state.code = action.payload.code;
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loading = false;
      }
    );
  },
});

export const {
  changeLessonType,
  changeSelectViewArchived,
  changeSelectTabLessonDetail,
} = lessonHistorySlice.actions;

export const selectLessonType = (state: RootState) => state.history.lessonType;
export const selectViewArchived = (state: RootState) =>
  state.history.viewArchived;
export const selectLessonHistoryData = (state: RootState) =>
  state.history.lessons;
export const loadingLessonHistoryData = (state: RootState) =>
  state.history.loading;
export const selectCodeLessonHistoryData = (state: RootState) =>
  state.history.code;
export const selectTabLessonDetail = (state: RootState) =>
  state.history.selectTabLessonDetail;
export default lessonHistorySlice.reducer;
