import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import {
  AddAmRmaTrackingNumberInput,
  DeleteAmRmaMessageInput,
  RateAmRmaReturnRequest,
  RemoveAmRmaTrackingNumberInput,
  ReturnsState,
} from '../models/shopReturnAmRma';
import {
  GET_AMRMA_SETTING,
  GET_AM_RMA_RETURNS,
  GET_CUSTOMER_ORDER_BY_ID,
  GET_PRODUCTS_BY_URL_KEY,
  GET_RETURNABLE_ORDERS,
} from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';
import {
  PlaceAmRmaReturnRequestInput,
  SendAmRmaMessageInput,
} from './../models/shopReturnAmRma';
import {
  ADD_TRACKING_NUMBER,
  CLOSE_RETURN_REQUEST,
  DELETE_RETURN_MESSAGE,
  PLACE_AM_RMA_CUSTOMER_RETURN_REQUEST,
  REMOVE_TRACKING_NUMBER,
  SEND_RATE_REQUEST,
  SEND_RETURN_MESSAGE,
} from './../utils/mutations';
import { GET_RETURN_MESSAGE } from './../utils/queries';
import { signOut } from './user';

export const getReturnableOrders = createAsyncThunk(
  'getReturns/getReturnableOrders',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_RETURNABLE_ORDERS),
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCustomerOrderById = createAsyncThunk(
  'getReturns/getCustomerOrders',
  async (number: string, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_CUSTOMER_ORDER_BY_ID),
        variables: {
          number,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAmRmaSetting = createAsyncThunk(
  'getReturns/getAmRmaSetting',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_AMRMA_SETTING),
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getProductByUrlKeySkytrak = createAsyncThunk(
  'getReturns/getProductByUrlKeySkytrak',
  async (url_key: string[], { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_PRODUCTS_BY_URL_KEY),
        variables: {
          url_key,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createAmRmaReturnRequest = createAsyncThunk(
  'getReturns/createReturnRequest',
  async (input: PlaceAmRmaReturnRequestInput, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(PLACE_AM_RMA_CUSTOMER_RETURN_REQUEST),
        variables: {
          input,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getReturnsAmRma = createAsyncThunk(
  'getReturns/getReturnsAmRma',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_AM_RMA_RETURNS),
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const closeReturnsAmRmaRequest = createAsyncThunk(
  'getReturns/closeReturnsAmRma',
  async (requestId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(CLOSE_RETURN_REQUEST),
        variables: {
          id: requestId,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addTrackingNumberReturn = createAsyncThunk(
  'getReturns/addTrackingNumberReturn',
  async (input: AddAmRmaTrackingNumberInput, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(ADD_TRACKING_NUMBER),
        variables: {
          input,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeTrackingNumberReturn = createAsyncThunk(
  'getReturns/removeTrackingNumberReturn',
  async (input: RemoveAmRmaTrackingNumberInput, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(REMOVE_TRACKING_NUMBER),
        variables: {
          input,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getReturnMessages = createAsyncThunk(
  'getReturns/getReturnMessages',
  async (
    payload: { hash: string; last_message_id: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_RETURN_MESSAGE),
        variables: {
          hash: payload.hash,
          last_message_id: payload.last_message_id,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendReturnMessage = createAsyncThunk(
  'getReturns/sendReturnMessage',
  async (input: SendAmRmaMessageInput, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(SEND_RETURN_MESSAGE),
        variables: {
          input,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteReturnMessage = createAsyncThunk(
  'getReturns/deleteReturnMessage',
  async (input: DeleteAmRmaMessageInput, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(DELETE_RETURN_MESSAGE),
        variables: {
          input,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendRateReturn = createAsyncThunk(
  'getReturns/sendRateReturn',
  async (input: RateAmRmaReturnRequest, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(SEND_RATE_REQUEST),
        variables: {
          input,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: ReturnsState = {
  statusOpenCreateReturn: false,
  getAmRmaOrderList: null,
  getAllReturns: null,
  loadingGetAllReturns: false,
  getOrderById: null,
  productByUrlKey: null,
  amRmaSetting: null,
  productInReturnRequest: [],
  statusReturns: 'all',
  sortReturnsByProperties: 'createdAt',
  fromDateReturns: '',
  toDateReturns: '',
  loadingGetProductByUrlKey: false,
  returnMessages: null,
};

const getReturnsSlice = createSlice({
  name: 'getReturnsSlice',
  initialState: initialState,
  reducers: {
    changePropertyReturnsSortBy: (state, action) => {
      state.sortReturnsByProperties = action.payload;
    },
    changeFromDateReturns: (state, action) => {
      state.fromDateReturns = action.payload;
    },
    changeToDateReturns: (state, action) => {
      state.toDateReturns = action.payload;
    },
    changeStatusOpenCreateReturn: (state, action) => {
      state.statusOpenCreateReturn = action.payload;
    },
    changeProductInReturnRequest: (state, action) => {
      state.productInReturnRequest = action.payload;
    },
    changeStatusReturns: (state, action) => {
      state.statusReturns = action.payload;
    },
    resetPropertyReturnsFilter: (state) => {
      state.fromDateReturns = '';
      state.toDateReturns = '';
      state.sortReturnsByProperties = 'createdAt';
      state.statusReturns = 'all';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReturnsAmRma.pending, (state, action) => {
        state.loadingGetAllReturns = true;
      })
      .addCase(getReturnsAmRma.fulfilled, (state, action) => {
        state.getAllReturns =
          action.payload.data.getAmRmaReturnsForCurrentCustomer;
        state.loadingGetAllReturns = false;
      })
      .addCase(getAmRmaSetting.fulfilled, (state, action) => {
        state.amRmaSetting = action.payload.data.getAmRmaSettings;
      })
      .addCase(getReturnableOrders.fulfilled, (state, action) => {
        state.getAmRmaOrderList = action.payload.data.getAmRmaOrderList;
      })
      .addCase(getCustomerOrderById.fulfilled, (state, action) => {
        if (action.payload.data?.customer?.orders?.items?.length > 0) {
          state.getOrderById = action.payload.data.customer.orders.items[0];
        }
      })
      .addCase(getProductByUrlKeySkytrak.pending, (state, action) => {
        state.loadingGetProductByUrlKey = true;
      })
      .addCase(getProductByUrlKeySkytrak.fulfilled, (state, action) => {
        if (action.payload.data?.products?.items?.length > 0) {
          state.productByUrlKey = action.payload.data?.products?.items;
        }
        state.loadingGetProductByUrlKey = false;
      })
      .addCase(getReturnMessages.fulfilled, (state, action) => {
        state.returnMessages = action.payload.data.getAmRmaRequestMessages;
      })
      .addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loadingGetAllReturns = false;
        state.loadingGetProductByUrlKey = false;
      }
    );
  },
});

export const {
  changePropertyReturnsSortBy,
  changeFromDateReturns,
  changeToDateReturns,
  changeStatusOpenCreateReturn,
  changeProductInReturnRequest,
  changeStatusReturns,
  resetPropertyReturnsFilter,
} = getReturnsSlice.actions;

export const selectStatusOpenCreateReturn = (state: RootState) =>
  state.returns.statusOpenCreateReturn;
export const selectAmRmaOrderList = (state: RootState) =>
  state.returns.getAmRmaOrderList;
export const selectOrderById = (state: RootState) => state.returns.getOrderById;
export const selectProductByUrlKey = (state: RootState) =>
  state.returns.productByUrlKey;
export const selectAmRmaSetting = (state: RootState) =>
  state.returns.amRmaSetting;
export const selectProductInReturnRequest = (state: RootState) =>
  state.returns.productInReturnRequest;
export const selectAllReturns = (state: RootState) =>
  state.returns.getAllReturns;
export const selectLoadingGetAllReturns = (state: RootState) =>
  state.returns.loadingGetAllReturns;
export const selectStatusReturns = (state: RootState) =>
  state.returns.statusReturns;
export const selectPropertySortByReturns = (state: RootState) =>
  state.returns.sortReturnsByProperties;
export const selectFromDateReturns = (state: RootState) =>
  state.returns.fromDateReturns;
export const selectToDateReturns = (state: RootState) =>
  state.returns.toDateReturns;
export const selectLoadingGetProductByUrlKey = (state: RootState) =>
  state.returns.loadingGetProductByUrlKey;
export const selectReturnMessages = (state: RootState) =>
  state.returns.returnMessages;

export default getReturnsSlice.reducer;
