import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchListBuck = createAsyncThunk(
  'listBuck/fetchListBuck',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/bucks');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ListBuckData {
  unUsedBucks: [];
  usedBucks: [];
}

interface ListBuckState {
  loading: boolean;
  error: null | number;
  data: null | ListBuckData;
}

const initialState: ListBuckState = {
  loading: true,
  error: null,
  data: null,
};

export const listBuckSlice = createSlice({
  name: 'listBuck',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListBuck.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchListBuck.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.data = data;
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectListBucks = (state: RootState) => state.listBuck.data;
export const loadingListBucks = (state: RootState) => state.listBuck.loading;
export default listBuckSlice.reducer;
