import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { REMOVE_ITEM_IN_CART } from '../utils/mutations';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { getCustomerCart, selectCustomerCart } from './shop';
import { signOut } from './user';

interface RemoveItemInCart {
  itemId: string;
}

export const removeItemInCart = createAsyncThunk(
  'removeItem/removeItemInCart',
  async (payload: RemoveItemInCart, { getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as any);
      if (cart) {
        const {
          data: { data },
        } = await request.post('graphql', {
          query: print(REMOVE_ITEM_IN_CART),
          variables: {
            cartId: cart.id,
            itemId: payload.itemId,
          },
        });
        if (data.cart) {
          return data.cart;
        }
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface RemoveItemInCartState {
  loading: boolean;
  isRemove: string;
}

const initialState: RemoveItemInCartState = {
  loading: false,
  isRemove: '',
};

export const removeItemInCartSlice = createSlice({
  name: 'removeItem',
  initialState,
  reducers: {
    changeStatusRemovedItem: (state, action) => {
      state.isRemove = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeItemInCart.pending, (state, action) => {
        state.loading = true;
        state.isRemove = 'wait';
      })
      .addCase(removeItemInCart.fulfilled, (state, action) => {
        state.loading = false;
        state.isRemove = 'removed';
      })
      .addCase(getCustomerCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCustomerCart.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(signOut, () => ({ ...initialState }));
  },
});

export const { changeStatusRemovedItem } = removeItemInCartSlice.actions;

export const selectRemoveItem = (state: RootState) => state.removeItem.isRemove;
export const loadingRemoveItem = (state: RootState) => state.removeItem.loading;
export default removeItemInCartSlice.reducer;
