import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface ProductDescription {
  productID: number;
}

export const fetchProductDescription = createAsyncThunk(
  'productDescription/fetchProductDescription',
  async (payload: ProductDescription, thunkAPI) => {
    try {
      const response = await request.get(
        `/api/v3/shop/product/layout/${payload.productID}`
      );
      let data = await response;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

interface ProductDescriptionData {
  desktop: [];
}

interface ProductDescriptionState {
  loading: boolean;
  error: any;
  data: any | ProductDescriptionData;
}

const initialState: ProductDescriptionState = {
  loading: true,
  error: null,
  data: null,
};

export const productDescriptionSlice = createSlice({
  name: 'productDescription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductDescription.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchProductDescription.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.data.data.desktop.length > 0) {
          state.data = action.payload.data.data.desktop[0].data;
        } else {
          state.data = null;
        }
      })
      .addCase(fetchProductDescription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectProductDescriptionData = (state: RootState) =>
  state.productDescription.data;
export const loadingProductDescription = (state: RootState) =>
  state.productDescription.loading;
export default productDescriptionSlice.reducer;
