import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const requestGamePlansJapan = createAsyncThunk(
  'renewalJapan/requestGamePlans',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/renewal/game-plan`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestCouponsJapan = createAsyncThunk(
  'renewalJapan/requestCouponsJapan',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/coupon?status=0`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestRenewalOrder = createAsyncThunk(
  'renewalJapan/requestRenewalOrder',
  async (params: ParamsRenewalJapanOrder, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/payment/invoke`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestRenewalOrderStatus = createAsyncThunk(
  'renewalJapan/requestRenewalOrderStatus',
  async (renewalJapanSessionId: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/payment/status?requestId=${renewalJapanSessionId}`
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface ParamsRenewalJapanOrder {
  storeId: number;
  type: string;
  buck: number;
  coupons: CouponJapan[];
  ticket: GamePlanDataJapan;
}

export interface CustomTaxJapan {
  taxName: string;
  taxValue: number;
}

export interface CouponJapan {
  amount: number;
  id: number;
  state: string;
  providedDate: string;
  availableDate: string;
  expiryDate: string;
  couponCode: string;
  displayName: string;
}

export interface ReveicedOrder {
  id: string;
  url: string;
  total: number;
  email: string;
  bucks: 0;
  coupons: CouponJapan[];
  subTotal: number;
  tax: number;
  discount: number;
  ticket: GamePlanDataJapan;
  totalCoupon: number;
}

export interface GamePlanDataJapan {
  isLastPurchase: boolean;
  isOnSale: boolean;
  id: string;
  name: string;
  price: number;
  salePrice: number;
  term: number;
  numLesson: number;
  shName: string;
  customTax: CustomTaxJapan[];
  nameKanji: string;
}

type GamePlanJapanState = {
  buck: number;
  coupon: CouponJapan[] | null;
  gamePlans: null | GamePlanDataJapan[];
  lessonPacks: null | GamePlanDataJapan[];
  tax: number;
  taxName: string;
  selectPackage: null | GamePlanDataJapan;
  receivedOrder: null | ReveicedOrder;
  loadingRenewalJapan: boolean;
};

const initialState: GamePlanJapanState = {
  buck: 0,
  coupon: null,
  gamePlans: null,
  lessonPacks: null,
  tax: 0,
  taxName: '',
  selectPackage: null,
  receivedOrder: null,
  loadingRenewalJapan: false,
};

export const renewalJapanSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    setSelectPackageJapan: (state, action) => {
      state.selectPackage = action.payload;
    },
    setReceivedOrderJapan: (state, action) => {
      state.receivedOrder = action.payload;
    },
    resetRenewalJapan: (state) => ({
      ...initialState,
      gamePlans: state.gamePlans,
      lessonPacks: state.lessonPacks,
      tax: state.tax,
      taxtName: state.taxName,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestGamePlansJapan.pending, (state, action) => {
      state.loadingRenewalJapan = true;
    });
    builder.addCase(requestGamePlansJapan.fulfilled, (state, action) => {
      state.gamePlans = action.payload.data.gamePlans;
      state.lessonPacks = action.payload.data.lessonPacks;
      state.tax = action.payload.data.tax;
      state.taxName = action.payload.data.taxName;
      state.loadingRenewalJapan = false;
    });
    builder.addCase(requestCouponsJapan.fulfilled, (state, action) => {
      state.coupon = action.payload.data.coupon;
      state.buck = action.payload.data.buck;
    });

    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  setSelectPackageJapan,
  setReceivedOrderJapan,
  resetRenewalJapan,
} = renewalJapanSlice.actions;

export const selectGamePlansJapan = (state: RootState) =>
  state.renewalJapan.gamePlans;
export const selectedPackageRenewalJapan = (state: RootState) =>
  state.renewalJapan.selectPackage;
export const selectLessonPacksJapan = (state: RootState) =>
  state.renewalJapan.lessonPacks;
export const selectBucksJapan = (state: RootState) => state.renewalJapan.buck;
export const selectTaxRenewalJapan = (state: RootState) =>
  state.renewalJapan.tax;
export const selectTaxNameJapan = (state: RootState) =>
  state.renewalJapan.taxName;
export const selectCouponJapan = (state: RootState) =>
  state.renewalJapan.coupon;
export const selectReceivedOrderJapan = (state: RootState) =>
  state.renewalJapan.receivedOrder;
export const selectLoadingRenewalJapan = (state: RootState) =>
  state.renewalJapan.loadingRenewalJapan;

export default renewalJapanSlice.reducer;
