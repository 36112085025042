import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { LessonState } from '../models/lesson';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';



export const fetchLessonById = createAsyncThunk(
  'lesson/lessonById',
  async (id: string, { dispatch, rejectWithValue }) => {
    try {
      const time = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const { data } = await request.get(
        `/api/player/lessons?id=${id}&timeZone=${time}`
      );
     
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: LessonState = {
  loading: false,
  error: null,
  detail: null,
  code: null,
  isRedirect: '',
  isArchived: false,
  lessonDetail:[],
  webLessonId:''
};

export const lessonByIdSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    redirectLessonFromSC: (state, action) => {
      state.isRedirect = action.payload;
    },
    changeSelectLessonId : (state,action) => {
      state.webLessonId = action.payload;
    },
    addLastestLesson : (state,action) => {
      state.lessonDetail.push(action.payload);
    },
    clearLessonDetailById: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLessonById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchLessonById.fulfilled, (state, action) => {
        if(action.payload.code !== 30001){
        state.code = action.payload.code;
        const idx = state.lessonDetail.findIndex(
          (i) => i.id === action.payload.data.id
        );
        if (idx >= 0) {
          state.lessonDetail[idx] = action.payload.data;
        } else {
          state.lessonDetail.push(action.payload.data);
        }
        }
        state.loading = false;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
        }
      );
  },
});

export const {addLastestLesson,changeSelectLessonId, redirectLessonFromSC, clearLessonDetailById } =
  lessonByIdSlice.actions;
export const statusRedirectLessonFromSC = (state: RootState) =>
  state.lessonById.isRedirect;
export const selectLessonByIdCode = (state:RootState) => state.lessonById.code;
export const selectLessonByIdData = (state: RootState) =>
{
  return state.lessonById.lessonDetail.find(
    (i) => i.id === state.lessonById.webLessonId
  );
};
export const loadingLessonByIdData = (state: RootState) =>
  state.lessonById.loading;
  export const selectLessonDetail = (state: RootState) =>
  state.lessonById.lessonDetail;
  export const selectWebLessonId = (state: RootState) =>
  state.lessonById.webLessonId;    
  
export default lessonByIdSlice.reducer;
