import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchConfigurationData = createAsyncThunk(
  'configuration/fetchConfigurationData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/configuration');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const checkFamilyAccounts = createAsyncThunk(
  'configuration/checkFamilyAccounts',
  async (email: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/player/family-account?email=${email}`
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchPublicConfigurationData = createAsyncThunk(
  'configuration/fetchPublicConfigurationData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/public/configuration`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ConfigurationData {
  gameTrackingVideo: string;
  gameTrackingThumbnail: string;
  bad3PuttVideo: string;
  nearTheGreenVideo: string;
  scramlingVideo: string;
  terribleTeeShotVideo: string;
  social: string;
  referrerMailSubject: string;
  referrerMailBody: string;
  gdoHistoryPurchaseUrl: string;
  nearTheGreenVideoSubtitle: string;
  gameTrackingVideoSubtitle: string;
  bad3PuttsVideoSubtitle: string;
  scramblingVideoSubtitle: string;
  terribleTeeShotsVideoSubtitle: string;
  imageAccountFamilyAccount: string;
  imageAccountListBuck: string;
  imageAccountMembership: string;
  imageAccountPurchaseHistory: string;
  imageAccountReferralsRewards: string;
  imageAccountUserAgreements: string;
  imageHelpKnowledge: string;
  imageHelpReleaseNotes: string;
  imageHelpSupportChat: string;
  imageHelpSupportTickets: string;
  imageImproveClubFitting: string;
  imageImproveLessonPlan: string;
  imageImproveLessons: string;
  imageImproveMySwings: string;
  imageImproveRecommendedGear: string;
  imageImproveSchedule: string;
  imageImproveSkillChallenges: string;
  imageImproveTecputt: string;
  imageImproveVideoLibrary: string;
  imageImproveActivities: string;
  imageProfileLanguage: string;
  imageProfileMessaging: string;
  imageProfilePassword: string;
  imageProfilePersonalInfo: string;
  imageStatsAchievements: string;
  imageStatsMyBag: string;
  imageStatsMyRounds: string;
  imageStatsMyStats: string;
  imageSkytrakAccountSetting: string;
  imageSkytrakAccountBilling: string;
  imageSkytrakAccountPurchaseHistory: string;
  imageSkytrakAccountMembership: string;
  imageSkytrakAccountActivity: string;
  dateLogicUpgradePIPSkytrak: string;
  guideMyStatsVideo: string;
  myStatsSkillScoreExplainVideo: string;
}

export interface TermsPrivacyPolicy {
  id: string;
  name: string;
  style: string;
  subType: string;
  content: string;
}

export interface FamilyAccount {
  firstname: string;
  wuci: string;
}

export interface VideoRegisterPromotion {
  mobileRegisterVideoPromotion: string;
}

interface ConfigurationState {
  loading: boolean;
  error: null | number;
  data: null | ConfigurationData;
  familyAccountInfos: [] | FamilyAccount[];
  videoRegister: null | VideoRegisterPromotion;
  golftecPrivacy: undefined | string;
  golftecTerms: undefined | string;
  golftecRegister: undefined | string;
  statusOpenModalPrivacy: boolean;
  namePrivacyTerms: string;
}

const initialState: ConfigurationState = {
  loading: false,
  error: null,
  data: null,
  familyAccountInfos: [],
  videoRegister: null,
  golftecPrivacy: undefined,
  golftecTerms: undefined,
  golftecRegister: undefined,
  statusOpenModalPrivacy: false,
  namePrivacyTerms: '',
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    changeStatusOpenModalPrivacy: (state, action) => {
      state.statusOpenModalPrivacy = action.payload;
    },
    changeNamePrivacyTerms: (state, action) => {
      state.namePrivacyTerms = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfigurationData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(checkFamilyAccounts.fulfilled, (state, action) => {
        if (action.payload.data.length > 0) {
          state.familyAccountInfos = action.payload.data[0].familyAccountInfos;
        } else {
          state.familyAccountInfos = [];
        }
      })
      .addCase(fetchConfigurationData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.data = { ...data };

        state.loading = false;
      })
      .addCase(fetchPublicConfigurationData.fulfilled, (state, action) => {
        state.videoRegister = action.payload.data;
        state.golftecPrivacy = action.payload.data['golftec-privacy'];
        state.golftecTerms = action.payload.data['golftec-terms'];
        state.golftecRegister = action.payload.data['golftec-register'];
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
        }
      );
  },
});

export const { changeStatusOpenModalPrivacy, changeNamePrivacyTerms } =
  configurationSlice.actions;

export const selectConfigurationData = (state: RootState) =>
  state.configuration.data;
export const loadingConfigurationData = (state: RootState) =>
  state.configuration.loading;
export const selectFamilyAccountInfos = (state: RootState) =>
  state.configuration.familyAccountInfos;
export const selectVideoRegister = (state: RootState) =>
  state.configuration.videoRegister;
export const selectGolftecPrivacy = (state: RootState) =>
  state.configuration.golftecPrivacy;
export const selectGolftecTerms = (state: RootState) =>
  state.configuration.golftecTerms;
export const selectGolftecRegister = (state: RootState) =>
  state.configuration.golftecRegister;
export const selectStatusOpenModalPrivacy = (state: RootState) =>
  state.configuration.statusOpenModalPrivacy;
export const selectChangeNamePrivacyTerms = (state: RootState) =>
  state.configuration.namePrivacyTerms;

export default configurationSlice.reducer;
