import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { UPDATE_QUANTITY_MINI_CART } from '../utils/mutations';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { getCustomerCart, selectCustomerCart } from './shop';
import { signOut } from './user';

interface UpdateQuantityMiniCart {
  quantity: number;
  itemId: string;
}

export const updateQuantityMiniCart = createAsyncThunk(
  'updateQuantity/updateQuantityMiniCart',
  async (payload: UpdateQuantityMiniCart, { getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as any);
      if (cart) {
        const {
          data: { data, errors }
        } = await request.post('graphql', {
          query: print(UPDATE_QUANTITY_MINI_CART),
          variables: {
            cartId: cart.id,
            quantity: payload.quantity,
            itemId: payload.itemId
          }
        });
        if (errors) {
          return errors;
        } else {
          return data;
        }
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface UpdateQuantityMiniCartState {
  loading: boolean;
  isUpdate: string;
  data: any;
}

const initialState: UpdateQuantityMiniCartState = {
  loading: false,
  isUpdate: '',
  data: null
};

export const updateQuantityMiniCartSlice = createSlice({
  name: 'updateQuantity',
  initialState,
  reducers: {
    changeStatusUpdateItem: (state, action) => {
      state.isUpdate = action.payload;
    },
    clearUpdateMiniCart: (state) => {
      state.data = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateQuantityMiniCart.pending, (state, action) => {
        state.loading = true;
        state.isUpdate = 'wait';
      })
      .addCase(updateQuantityMiniCart.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.isUpdate = 'updated';
      })
      .addCase(getCustomerCart.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCustomerCart.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(signOut, () => ({ ...initialState }));
  }
});

export const { changeStatusUpdateItem, clearUpdateMiniCart } = updateQuantityMiniCartSlice.actions;

export const selectUpdateItem = (state: RootState) =>
  state.updateQuantity.isUpdate;
export const selectDataUpdatedMiniCart = (state: RootState) =>
  state.updateQuantity.data;
export const loadingUpdateQuantity = (state: RootState) =>
  state.updateQuantity.loading;
export default updateQuantityMiniCartSlice.reducer;
