import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface ReadNotification {
  sourceId: string[];
}

export const fetchReadNotification = createAsyncThunk(
  'notification/fetchReadNotification',
  async (payload: ReadNotification, { rejectWithValue }) => {
    try {
      const { data } = await request.post(
        'api/v2/tracking/notification',
        payload.sourceId.map((notification: string) => ({
          action: 'read',
          sourceId: notification,
        }))
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchNotificationData = createAsyncThunk(
  'notification/fetchNotificationData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('api/v2/player/notification');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface NotificationData {
  id:                   string;
  playerId:             string;
  page:                 null;
  type:                 string;
  content:              NotificationContent;
  nativeContent:        NotificationContent;
  style:                null;
  started:              null;
  messageId:            string;
  title:                string;
  created:              Date;
  owner:                NotificationOwner;
  status:               number;
  url:                  string;
  image:                string;
  waitingSlotId:        string;
  timezone:             string;
  timeZoneAbbreviation: string;
  shortUrl:             string;
}

export interface NotificationContent {
  html:  string;
  style: string;
}

export interface NotificationOwner {
  id:     string;
  name:   string;
  avatar: string;
}


interface NotificationState {
  loading: boolean;
  error: null | number;
  data: null | NotificationData[];
  isRead: string;
}

const initialState: NotificationState = {
  loading: false,
  error: null,
  data: null,
  isRead: '',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    changeChangeStatusNotification: (state, action) => {
      state.isRead = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReadNotification.pending, (state, action) => {
        state.isRead = 'wait';
      })
      .addCase(fetchReadNotification.fulfilled, (state, action) => {
        state.isRead = 'read';
      });

    builder
      .addCase(fetchNotificationData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchNotificationData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { changeChangeStatusNotification } = notificationSlice.actions;

export const selectIsReadNotification = (state: RootState) =>
  state.notification.isRead;
export const selectNotificationData = (state: RootState) =>
  state.notification.data;
export const loadingNotificationData = (state: RootState) =>
  state.notification.loading;
export default notificationSlice.reducer;
