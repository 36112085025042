import { ProductRelated } from './../models/shop';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { ProductDetailState } from '../models/shop';
import { GET_PRODUCT_DETAIL } from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { clearValueAddCart } from './getValueAddToCart';
import { signOut } from './user';

interface CategoryId {
  url_key: string;
}

export const getProductDetail = createAsyncThunk(
  'product/productDetail',
  async (payload: CategoryId, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_PRODUCT_DETAIL),
        variables: {
          urlKey: payload.url_key,
        },
      });
      // const summaryDetail = data?.data.products.items.filter((d: {url_key: string}) => d.url_key === payload.url_key);
      dispatch(clearValueAddCart());
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState: ProductDetailState = {
  detail: null,
  openQuickView: false,
  loading: false,
  urlKey: '',
  viewDetails: '',
  selectedProduct: '',
  error: null,
  detailRelated: [],
  detailUpSell: [],
};

const productDetailSlice = createSlice({
  name: 'productByCategoryId',
  initialState,
  reducers: {
    changeOpenQuickView: (state, action) => {
      state.openQuickView = action.payload;
    },
    changeQuickViewUrlKey: (state, action) => {
      state.urlKey = action.payload;
    },
    resetDataProductDetail: (state, action) => {
      state.detail = action.payload;
    },
    changeViewProductDetails: (state, action) => {
      state.viewDetails = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductDetail.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProductDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = action.payload.data.products.items[0];
        state.viewDetails = '';
        if (action.payload.data.products.items.length > 0) {
          if (
            action.payload.data.products.items[0].upsell_products.length > 0
          ) {
            state.detailUpSell =
              action.payload.data.products.items[0].upsell_products;
          } else {
            state.detailUpSell =
              action.payload.data.products.items[0].upsell_products;
          }
          if (
            action.payload.data.products.items[0].related_products.length > 0
          ) {
            state.detailRelated =
              action.payload.data.products.items[0].related_products;
          } else {
            state.detailRelated =
              action.payload.data.products.items[0].related_products;
          }
        }
      })
      .addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  changeOpenQuickView,
  changeQuickViewUrlKey,
  resetDataProductDetail,
  changeViewProductDetails,
  setSelectedProduct,
} = productDetailSlice.actions;

export const selectChangeViewProductDetails = (state: RootState) =>
  state.productDetail.viewDetails;

export const selectSelectedProduct = (state: RootState) =>
  state.productDetail.selectedProduct;
export const selectQuickViewUrlKey = (state: RootState) =>
  state.productDetail.urlKey;
export const selectOpenQuickView = (state: RootState) =>
  state.productDetail.openQuickView;
export const selectProductDetail = (state: RootState) =>
  state.productDetail.detail;
export const loadingProductDetail = (state: RootState) =>
  state.productDetail.loading;
export const selectProductRelated = (state: RootState) =>
  state.productDetail.detailRelated;
export const selectProductUpSell = (state: RootState) =>
  state.productDetail.detailUpSell;

export default productDetailSlice.reducer;
