import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchListCoupon = createAsyncThunk(
  'listCoupon/fetchListCoupon',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/coupon');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ListCouponData {
  coupon: [];
}

interface ListCouponState {
  loading: boolean;
  error: null | number;
  data: null | ListCouponData;
}

const initialState: ListCouponState = {
  loading: true,
  error: null,
  data: null,
};

export const listCouponSlice = createSlice({
  name: 'listBuck',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListCoupon.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchListCoupon.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.data = data;
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectListCoupon = (state: RootState) => state.listCoupon.data;
export const loadingListCoupon = (state: RootState) => state.listCoupon.loading;
export default listCouponSlice.reducer;
