import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { ProductState } from '../models/shopCustom';
import { GET_RESULTS_SEARCH_PRODUCT } from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const getProductByUrlKey = createAsyncThunk(
  'searchProduct/getProductByUrlKey',
  async (payload: { urlKey: string[] }, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_RESULTS_SEARCH_PRODUCT),
        variables: {
          inputText: '',
          urlKeys: payload.urlKey ? payload.urlKey : '',
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getResultsSearchProduct = createAsyncThunk(
  'searchProduct/getResultsSearchProduct',
  async (payload: { inputText: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_RESULTS_SEARCH_PRODUCT),
        variables: {
          inputText: payload.inputText,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getResultsSearchProductByCategoryId = createAsyncThunk(
  'searchProduct/getResultsSearchProductByCategoryId',
  async (
    payload: {
      inputText: string;
      catUid: string;
      pageSize: number;
      currentPage: number;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_RESULTS_SEARCH_PRODUCT),
        variables: {
          inputText: payload.inputText,
          catUid: payload.catUid ? payload.catUid : '',
          pageSize: payload.pageSize,
          currentPage: payload.currentPage,
        },
      });
      dispatch(setStatusFilterCategory(false));
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const searchProductSlice = createSlice({
  name: 'searchProduct',
  initialState: {
    detail: null,
    detailProductByCategory: null,
    productSearch: null,
    loadingUrlKey: false,
    loadingProductSearch: false,
    loadingProductSearchByCat: false,
    isSearchProduct: false,
    isSearchProductByCat: false,
    keySearchProductByCategory: '',
    chooseCategoryGreenCard: '',
    chooseCategoryUidGreenCard: '',
    keyShopSearch: '',
    cancelSearch: '',
    productSearchPageSize: 10,
    startSearch: false,
    isFilterCategory: false,
    productsBySku: null,
  } as ProductState,
  reducers: {
    changeKeySearchProduct: (state, action) => {
      state.isSearchProduct = action.payload;
    },
    changeKeySearchProductByCategory: (state, action) => {
      state.isSearchProductByCat = action.payload;
    },
    setKeySearchProductByCategory: (state, action) => {
      state.keySearchProductByCategory = action.payload;
    },
    cancelSearchProductByCategory: (state, action) => {
      state.cancelSearch = action.payload;
    },
    setChooseCategoryGreenCart: (state, action) => {
      state.chooseCategoryGreenCard = action.payload;
    },
    setChooseCategoryUidGreenCart: (state, action) => {
      state.chooseCategoryUidGreenCard = action.payload;
    },
    setKeyShopSearch: (state, action) => {
      state.keyShopSearch = action.payload;
    },
    setStatusStartSearch: (state, action) => {
      state.startSearch = action.payload;
    },
    setStatusFilterCategory: (state, action) => {
      state.isFilterCategory = action.payload;
    },
    resetDetailProductByCategory: (state, action) => {
      state.detailProductByCategory = action.payload;
    },
    resetLoadingDetailProductByCategory: (state, action) => {
      state.loadingProductSearchByCat = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResultsSearchProduct.pending, (state, action) => {
        state.loadingProductSearch = true;
      })
      .addCase(getResultsSearchProduct.fulfilled, (state, action) => {
        state.loadingProductSearch = false;
        state.productSearch = action.payload.data.products;
        state.cancelSearch = '';
      });
    builder.addCase(getProductByUrlKey.pending, (state, action) => {
      state.loadingUrlKey = true;
    });
    builder.addCase(getProductByUrlKey.fulfilled, (state, action) => {
      if (action.payload.data?.products) {
        state.detail = action.payload.data.products;
      }
      state.loadingUrlKey = false;
    });
    builder.addCase(
      getResultsSearchProductByCategoryId.pending,
      (state, action) => {
        state.loadingProductSearchByCat = true;
      }
    );
    builder.addCase(
      getResultsSearchProductByCategoryId.fulfilled,
      (state, action) => {
        state.detailProductByCategory = action.payload.data.products;
        state.loadingProductSearchByCat = false;
        state.startSearch = false;
      }
    );
  },
});

export const {
  changeKeySearchProduct,
  changeKeySearchProductByCategory,
  setKeySearchProductByCategory,
  setChooseCategoryGreenCart,
  setChooseCategoryUidGreenCart,
  setKeyShopSearch,
  cancelSearchProductByCategory,
  setStatusStartSearch,
  setStatusFilterCategory,
  resetDetailProductByCategory,
  resetLoadingDetailProductByCategory,
} = searchProductSlice.actions;

export const selectResultSearchProductByUrlKey = (state: RootState) =>
  state.searchProduct.detail;
export const selectStatusCancelSearchProduct = (state: RootState) =>
  state.searchProduct.cancelSearch;
export const selectResultsSearchProductByCategory = (state: RootState) =>
  state.searchProduct.detailProductByCategory;
export const selectResultSearchProduct = (state: RootState) =>
  state.searchProduct.productSearch;
export const loadingSearchProduct = (state: RootState) =>
  state.searchProduct.loadingProductSearch;
export const loadingProductByUrlKey = (state: RootState) =>
  state.searchProduct.loadingUrlKey;
export const selectIsSearchProduct = (state: RootState) =>
  state.searchProduct.isSearchProduct;
export const selectIsSearchProductByCategory = (state: RootState) =>
  state.searchProduct.isSearchProductByCat;
export const selectLoadingSearchProductByCategory = (state: RootState) =>
  state.searchProduct.loadingProductSearchByCat;
export const selectResultSearchProductByCat = (state: RootState) =>
  state.searchProduct.keySearchProductByCategory;
export const selectChooseCategoryGreenCard = (state: RootState) =>
  state.searchProduct.chooseCategoryGreenCard;
export const selectChooseCategoryUidGreenCard = (state: RootState) =>
  state.searchProduct.chooseCategoryUidGreenCard;
export const selectKeyShopSearch = (state: RootState) =>
  state.searchProduct.keyShopSearch;
export const selectProductSearchPageSize = (state: RootState) =>
  state.searchProduct.productSearchPageSize;
export const selectStatusStartSearch = (state: RootState) =>
  state.searchProduct.startSearch;
export const selectStatusFilterCategory = (state: RootState) =>
  state.searchProduct.isFilterCategory;
export const selectProductsBySku = (state: RootState) =>
  state.searchProduct.productsBySku;

export default searchProductSlice.reducer;
