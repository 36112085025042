import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SharedVideoLessonState } from '../models/sharedVideoLesson';
import { dataCol } from '../pages/SharedVideoLesson/data';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface ShareVideoData {
  id: string;
}

export const fetchVideoLesson = createAsyncThunk(
  'purchaseHistory/fetchPurchasedHistory',
  async (payload: ShareVideoData, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`api/share?id=${payload.id}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchContentSharedVideoLesson = createAsyncThunk(
  'referral/fetchContentSharedVideoLesson',
  async (_, thunkAPI) => {
    try {
      return dataCol;
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: SharedVideoLessonState = {
  loading: false,
  error: null,
  data: null,
  videoLesson: null,
};

export const sharedVideoLessonSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentSharedVideoLesson.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchContentSharedVideoLesson.fulfilled, (state, action) => {
        if (action.payload.data.desktop.length > 0) {
          state.data = action.payload.data.desktop[0];
        }
        state.loading = false;
      })
      .addCase(fetchVideoLesson.fulfilled, (state, action) => {
        state.videoLesson = action.payload.data;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectVideoLesson = (state: RootState) =>
  state.sharedVideo.videoLesson;
export const selectSharedVideoData = (state: RootState) =>
  state.sharedVideo.data;
export const loadingSharedVideo = (state: RootState) =>
  state.sharedVideo.loading;
export default sharedVideoLessonSlice.reducer;
