import { gql } from 'graphql.macro';

export const CountryFields = gql`
  fragment CountryFields on CartAddressCountry {
    code
    label
  }
`;

export const DiscountFields = gql`
  fragment DiscountFields on Discount {
    amount {
      ...MoneyFields
    }
    label
  }
`;

export const MoneyFields = gql`
  fragment MoneyFields on Money {
    currency
    value
  }
`;

export const RegionFields = gql`
  fragment RegionFields on CartAddressRegion {
    code
    label
    region_id
  }
`;

export const ProductCrossSell = gql`
  fragment ProductCrossSell on ProductInterface {
    __typename
    crosssell_products {
      categories {
        id
        name
        breadcrumbs {
          category_id
        }
      }
      description {
        html
      }
      short_description {
        html
      }
      id
      media_gallery {
        url
        label
      }
      meta_description
      name
      price {
        regularPrice {
          amount {
            currency
            value
          }
        }
      }
      price_range {
        minimum_price {
          final_price {
            value
            currency
          }
          regular_price {
            value
            currency
          }
        }
        maximum_price {
          final_price {
            value
            currency
          }
          regular_price {
            value
            currency
          }
        }
      }
      price_tiers {
        quantity
        final_price {
          currency
          value
        }
        discount {
          amount_off
          percent_off
        }
      }
      special_price
      sku
      small_image {
        url
      }
      url_key
      stock_status
    }
  }
`;

export const CartFields = gql`
  fragment CartFields on Cart {
    billing_address {
      city
      company
      country {
        ...CountryFields
      }
      customer_address_id
      firstname
      lastname
      postcode
      region {
        ...RegionFields
      }
      street
      telephone
    }
    id
    items {
      id
      uid
      prices {
        price {
          ...MoneyFields
        }
        row_total_including_tax {
          ...MoneyFields
        }
      }

      product {
        recently_ordered
        categories {
          id
          name

          breadcrumbs {
            category_id
          }
        }
        golftec_product_holes_number

        golftec_product_tags {
          value
        }

        ... on BundleProduct {
          items {
            option_id
            options {
              id
              product {
                small_image {
                  url
                }
              }
            }
          }
        }
        image {
          url
        }
        description {
          html
        }
        name
        price_range {
          minimum_price {
            final_price {
              ...MoneyFields
            }
            regular_price {
              ...MoneyFields
            }
          }
        }
        price_tiers {
          discount {
            amount_off
          }
          final_price {
            ...MoneyFields
          }
          quantity
        }
        special_price
        sku
        uid
        url_key
        ...ProductCrossSell
      }
      quantity
      ... on SimpleCartItem {
        subscription: customizable_options {
          label
          values {
            label
            price {
              type
              units
              value
            }
          }
        }
        customizable_options {
          label
          values {
            label
            price {
              type
              units
              value
            }
          }
        }
      }
      ... on ConfigurableCartItem {
        subscription_options: customizable_options {
          label
          values {
            label
            price {
              type
              units
              value
            }
          }
        }
        configurable_options {
          option_label
          value_label
        }
      }
      ... on BundleCartItem {
        subscription: customizable_options {
          label
          values {
            label
            price {
              type
              units
              value
            }
          }
        }
        bundle_options {
          label
          values {
            id
            uid
            label
            quantity
            price
          }
        }
      }
    }
    prices {
      ms_gift_voucher_info {
        base_gift_voucher_discount
        base_giftvoucher_discount_for_shipping
        gift_voucher_discount
        gift_voucher_gift_codes
        giftcodes_applied_discount {
          base_discount
          code
          discount
        }
        giftcodes_applied_discount_for_shipping {
          base_discount
          code
          discount
        }
        giftvoucher_base_hidden_tax_amount
        giftvoucher_base_shipping_hidden_tax_amount
        giftvoucher_discount_for_shipping
        giftvoucher_hidden_tax_amount
        giftvoucher_shipping_hidden_tax_amount
      }
      applied_taxes {
        amount {
          ...MoneyFields
        }
        label
      }
      discount {
        amount {
          ...MoneyFields
        }
        label
      }
      discounts {
        ...DiscountFields
      }
      grand_total {
        ...MoneyFields
      }
      subtotal_excluding_tax {
        ...MoneyFields
      }
      subtotal_including_tax {
        ...MoneyFields
      }
      subtotal_with_discount_excluding_tax {
        ...MoneyFields
      }
    }
    shipping_addresses {
      available_shipping_methods {
        amount {
          ...MoneyFields
        }
        available
        carrier_code
        carrier_title
        error_message
        method_code
        method_title
        price_excl_tax {
          ...MoneyFields
        }
        price_incl_tax {
          ...MoneyFields
        }
      }
      customer_address_id
      city
      company
      country {
        ...CountryFields
      }
      customer_notes
      firstname
      lastname
      pickup_location_code
      postcode
      region {
        ...RegionFields
      }
      selected_shipping_method {
        amount {
          ...MoneyFields
        }
        base_amount {
          ...MoneyFields
        }
        carrier_code
        carrier_title
        method_code
        method_title
      }
      street
      telephone
    }
    total_quantity
  }
  ${ProductCrossSell}
  ${CountryFields}
  ${DiscountFields}
  ${MoneyFields}
  ${RegionFields}
`;

export const ProductDetail = gql`
  fragment ProductDetail on ProductInterface {
    __typename
    golftec_product_tags {
      value
    }
    golftec_product_holes_number
    ... on CustomizableProductInterface {
      options {
        ... on CustomizableOptionInterface {
          option_id
          required
          sort_order
          title
          ... on CustomizableCheckboxOption {
            option_id
            required
            sort_order
            title
            value {
              ... on CustomizableCheckboxValue {
                option_type_id
                price
                price_type
                sku
                sort_order
                title
                uid
              }
            }
            __typename
          }
        }
      }
    }
    categories {
      id
      name
      product_count
      breadcrumbs {
        category_id
      }
    }
    description {
      html
    }
    short_description {
      html
    }
    id
    media_gallery {
      url
      label
      ... on ProductVideo {
        video_content {
          media_type
          video_provider
          video_url
          video_title
          video_description
          video_metadata
        }
      }
    }
    meta_description
    name
    price {
      regularPrice {
        amount {
          currency
          value
        }
      }
    }
    price_range {
      minimum_price {
        regular_price {
          value
          currency
        }
        final_price {
          value
          currency
        }
      }
      maximum_price {
        regular_price {
          value
          currency
        }
        final_price {
          value
          currency
        }
      }
    }
    price_tiers {
      quantity
      final_price {
        currency
        value
      }
      discount {
        amount_off
        percent_off
      }
    }
    special_price
    sku
    small_image {
      url
    }
    stock_status
    url_key
    ... on SimpleProduct {
      options {
        ... on CustomizableDropDownOption {
          uid
          dropdown_type {
            type
          }
          value {
            title
            uid
            price_type
            option_type_id
            subscription {
              length
              installment_price
              adjustment_price
            }
          }
        }
      }
    }
    ... on BundleProduct {
      options {
        ... on CustomizableDropDownOption {
          uid
          dropdown_type {
            type
          }
          value {
            title
            uid
            price_type
            option_type_id
            subscription {
              length
              installment_price
              adjustment_price
            }
          }
        }
      }
      dynamic_sku
      dynamic_price
      dynamic_weight
      price_view
      ship_bundle_items
      items {
        option_id
        title
        sku
        type
        required
        position
        options {
          id
          label
          quantity
          can_change_quantity
          price
          price_type
          is_default
          position
          product {
            id
            name
            sku
            stock_status
            __typename
            categories {
              id
              name
              breadcrumbs {
                category_id
              }
            }
            description {
              html
            }
            short_description {
              html
            }
            id
            media_gallery {
              url
              label
            }
            meta_description
            name
            price {
              regularPrice {
                amount {
                  currency
                  value
                }
              }
            }
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
              maximum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
            }
            price_tiers {
              quantity
              final_price {
                currency
                value
              }
              discount {
                amount_off
                percent_off
              }
            }
            special_price
            sku
            small_image {
              url
            }
            stock_status
            url_key
          }
        }
      }
    }
    ... on ConfigurableProduct {
      options {
        ... on CustomizableDropDownOption {
          uid
          dropdown_type {
            type
          }
          value {
            title
            uid
            price_type
            option_type_id
            subscription {
              length
              installment_price
              adjustment_price
            }
          }
        }
      }
      configurable_options {
        attribute_code
        attribute_id
        id
        label
        values {
          default_label
          label
          store_label
          use_default_value
          value_index
          swatch_data {
            ... on ImageSwatchData {
              thumbnail
            }
            value
            __typename
          }
        }
      }
      variants {
        attributes {
          code
          value_index
        }
        product {
          id
          media_gallery {
            disabled
            label
            position
            url
            ... on ProductVideo {
              video_content {
                media_type
                video_provider
                video_url
                video_title
                video_description
                video_metadata
              }
            }
          }
          sku
          stock_status
          price {
            regularPrice {
              amount {
                currency
                value
              }
            }
          }
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
            }
          }
          price_tiers {
            quantity
            final_price {
              currency
              value
            }
            discount {
              amount_off
              percent_off
            }
          }
          special_price
        }
      }
    }
  }
`;

export const CartPageFragment = gql`
  fragment CartPageFragment on Cart {
    id
    total_quantity
    applied_coupons {
      code
    }
    prices {
      alert {
        type
        message
        icon
      }
      subtotal_excluding_tax {
        currency
        value
      }
      subtotal_with_discount_excluding_tax {
        currency
        value
      }
    }
  }
`;

export const BillingAddressSubscriptionFragment = gql`
  fragment BillingAddressSubscriptionFragment on SubscriptionQuote {
    billing_address {
      address_id
      city
      company
      country_id
      customer_address_id
      fax
      firstname
      lastname
      middlename
      postcode
      prefix
      region
      region_id
      street
      suffix
      telephone
      vat_id
    }
  }
`;

export const ShippingAddressSubscriptionFragment = gql`
  fragment ShippingAddressSubscriptionFragment on SubscriptionQuote {
    shipping_address {
      address_id
      city
      company
      country_id
      customer_address_id
      customer_balance_amount
      customer_notes
      discount_amount
      discount_description
      discount_tax_compensation_amount
      fax
      firstname
      free_shipping
      grand_total
      lastname
      middlename
      postcode
      prefix
      region
      region_id
      same_as_billing
      shipping_amount
      shipping_description
      shipping_discount_amount
      shipping_discount_tax_compensation_amount
      shipping_incl_tax
      shipping_method
      shipping_tax_amount
      street
      subtotal
      subtotal_incl_tax
      subtotal_with_discount
      suffix
      tax_amount
      telephone
      vat_id
      weight
    }
  }
`;

export const ItemsAddressSubscriptionFragment = gql`
  fragment ItemsAddressSubscriptionFragment on SubscriptionQuote {
    items {
      custom_price
      description
      discount_amount
      discount_tax_compensation_amount
      free_shipping
      is_virtual
      item_id
      name
      original_custom_price
      parent_item_id
      price
      price_incl_tax
      product_id
      product_type
      qty
      row_total
      row_total_incl_tax
      row_total_with_discount
      sku
      tax_amount
      tax_percent
      weee_tax_applied_amount
      weee_tax_applied_row_amount
      weee_tax_disposition
      weee_tax_row_disposition
      weight
    }
  }
`;

export const ProductRelatedUpSell = gql`
  fragment ProductRelatedUpSell on ProductInterface {
    related_products {
      options_container
      uid
      name
      stock_status
      url_key
      small_image {
        url
      }
      price {
        regularPrice {
          amount {
            currency
            value
          }
        }
      }
      price_range {
        minimum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
        maximum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
      }
      price_tiers {
        quantity
        final_price {
          currency
          value
        }
        discount {
          amount_off
          percent_off
        }
      }
    }
    upsell_products {
      uid
      name
      url_key
      stock_status
      small_image {
        url
      }
      price {
        regularPrice {
          amount {
            currency
            value
          }
        }
      }
      price_range {
        minimum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
        maximum_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
      }
      price_tiers {
        quantity
        final_price {
          currency
          value
        }
        discount {
          amount_off
          percent_off
        }
      }
    }
  }
`;

export const Order = gql`
  fragment Order on Customer {
    orders {
      items {
        id
        number
        order_date
        status
        amrma_is_returnable
        items {
          amrma_order_item {
            order_item_id
            available_qty
            purchased_qty
            is_returnable
            no_returnable_reason
            already_returned_requests
            resolutions {
              resolution_id
              label
              status
              position
            }
          }
          product_name
          product_sku
          product_url_key
          product_sale_price {
            value
          }
          product_sale_price {
            value
            currency
          }
          quantity_ordered
          quantity_invoiced
          quantity_shipped
          ... on BundleOrderItem {
            bundle_options {
              id
              label
              values {
                id
                product_name
                product_sku
                quantity
                price {
                  value
                  currency
                }
              }
            }
          }
          ... on DownloadableOrderItem {
            downloadable_links {
              uid
              title
            }
          }
        }
        carrier
        shipments {
          id
          number
          items {
            product_name
            quantity_shipped
          }
        }
        total {
          base_grand_total {
            value
            currency
          }
          grand_total {
            value
            currency
          }
          total_tax {
            value
          }
          subtotal {
            value
            currency
          }
          taxes {
            amount {
              value
              currency
            }
            title
            rate
          }
          total_shipping {
            value
          }
          shipping_handling {
            amount_including_tax {
              value
            }
            amount_excluding_tax {
              value
            }
            total_amount {
              value
            }
            taxes {
              amount {
                value
              }
              title
              rate
            }
          }
          discounts {
            amount {
              value
              currency
            }
            label
          }
        }
      }
    }
  }
`;

export const OrderItem = gql`
  fragment OrderItem on CustomerOrder {
    id
    number
    order_date
    increment_id
    order_number
    status
    amrma_is_returnable
    items {
      amrma_order_item {
        order_item_id
        available_qty
        purchased_qty
        is_returnable
        no_returnable_reason
        already_returned_requests
        resolutions {
          resolution_id
          label
          status
          position
        }
      }
      product_name
      product_sku
      product_url_key
      product_type
      product_sale_price {
        value
      }
      product_sale_price {
        value
        currency
      }
      quantity_ordered
      quantity_invoiced
      quantity_shipped
      ... on BundleOrderItem {
        bundle_options {
          id
          label
          values {
            id
            product_name
            product_sku
            quantity
            price {
              value
              currency
            }
          }
        }
      }
      ... on DownloadableOrderItem {
        downloadable_links {
          uid
          title
        }
      }
    }
    carrier
    shipments {
      id
      number
      items {
        product_name
        quantity_shipped
      }
      tracking {
        carrier
        number
        title
      }
    }
    payment_methods {
      name
      type
      additional_data {
        name
        value
      }
      cc_last_4
      cc_exp_month
      cc_exp_year
      cc_type
      card_name
    }
    total {
      base_grand_total {
        value
        currency
      }
      grand_total {
        value
        currency
      }
      total_tax {
        value
      }
      subtotal {
        value
        currency
      }
      taxes {
        amount {
          value
          currency
        }
        title
        rate
      }
      total_shipping {
        value
      }
      shipping_handling {
        amount_including_tax {
          value
        }
        amount_excluding_tax {
          value
        }
        total_amount {
          value
        }
        taxes {
          amount {
            value
          }
          title
          rate
        }
      }
      discounts {
        amount {
          value
          currency
        }
        label
      }
    }
  }
`;

export const AmRmaReturns = gql`
  fragment AmRmaReturns on Customer {
    amrma_returns {
      items {
        request_id
        url_hash
        order_id
        store_id
        created_at
        modified_at
        status {
          label
        }
        customer_id
        customer_name
        manager_id
        custom_fields
        rating
        rating_comment
        note
        tracking_numbers {
          tracking_id
          request_id
          tracking_code
          tracking_number
          is_customer
        }
        shipping_label
        status {
          color
          label
        }
        request_items {
          request_item_id
          request_id
          reason_id
          condition_id
          resolution_id
          order_item_id
          qty
          item_status
          request_qty
          product_name
          product_url_key
        }
      }
    }
  }
`;
