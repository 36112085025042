import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import add from 'date-fns/add';
import isPast from 'date-fns/isPast';
import isSameDay from 'date-fns/isSameDay';
import startOfMonth from 'date-fns/startOfMonth';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface CancelAppointment {
  id: string;
  appointmentState: string;
  note: string;
  on24HConfirmed: number;
  isWaitingList: boolean;
}

export const fetchAllAppointments = createAsyncThunk(
  'appointment/fetchAllAppointments',
  async (_, { rejectWithValue }) => {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const { data } = await request.get(`/api/v2/appointments?timeZone=${tz}`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchCancelAppointment = createAsyncThunk(
  'cancel/fetchCancelAppointment',
  async (payload: CancelAppointment, thunkAPI) => {
    try {
      const response = await request.post('schedule/cancelAppointment', {
        id: payload.id,
        appointmentState: payload.appointmentState,
        note: payload.note,
        on24HConfirmed: payload.on24HConfirmed,
        isWaitingList: payload.isWaitingList,
      });
      let data = await response;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export interface AppointmentData {
  on24HConfirmed: number;
  id: string;
  centerId: string;
  centerTimezone: string;
  centerName: string;
  centerPhone: string;
  centerLongitude: number;
  centerLatitude: number;
  coachId: string;
  coachName: string;
  coachPhone: string;
  coachEmail: string;
  studentId: string;
  lessonType: string;
  dateTime: string;
  date: string;
  icsUrl: string;
  vcsUrl: string;
  vcsUrl2: string;
  state: string;
  street1: string;
  street2: string;
  city: string;
  zipCode: string;
  country: string;
  appointmentState: string;
  note: string;
  lessonTypeId: string;
  duration: number;
  startTime: string;
  endTime: string;
  lessonTypeCn: string;
  centerNameKanji: string;
  location: string;
  isPassed: boolean;
  isAddAdditionalFee: boolean;
  isReadyMeeting: boolean;
  isWaitingList: boolean;
  isPractice: boolean;
  isAvailable: boolean;
  coachAvatar: string;
}

interface AppointmentState {
  error: null | number;
  data: AppointmentData[];
  selectedDate: number;
  selectedDateAppointments: AppointmentData[];
  startDate: number;
  toDay: number;
  cancelLesson: boolean;
  cancelLessonDetail: AppointmentData | null;
  isCancel: string;
  loading: boolean;
  isCurrentDate: boolean;
}

const initialState: AppointmentState = {
  error: null,
  data: [],
  selectedDate: Date.now(),
  selectedDateAppointments: [],
  startDate: Date.now(),
  toDay: Date.now(),
  cancelLesson: false,
  cancelLessonDetail: null,
  isCancel: '',
  loading: false,
  isCurrentDate: true,
};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    goToNextMonth: (state) => {
      state.isCurrentDate = false;
      state.startDate = startOfMonth(
        add(state.startDate, { months: 1 })
      ).getTime();
    },
    goToNextMonthSchedule: (state, action) => {
      state.isCurrentDate = false;
      state.startDate = startOfMonth(
        add(action.payload, { months: 1 })
      ).getTime();
    },
    goToPreviousMonth: (state) => {
      const newDate = add(state.startDate, { months: -1 });
      if (isPast(newDate)) {
        state.isCurrentDate = true;
        state.startDate = Date.now();
      } else {
        state.isCurrentDate = false;
        state.startDate = startOfMonth(newDate).getTime();
      }
    },
    setAppointmentSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
      state.selectedDateAppointments = state.data.filter((i) =>
        isSameDay(new Date(i.date), action.payload)
      );
    },
    setAppointmentStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    changeCancelLessonAppointment: (state, action) => {
      state.cancelLesson = action.payload.cancelStatus;
      state.cancelLessonDetail = action.payload.cancelLessonDetail;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAppointments.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAllAppointments.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
        state.isCancel = '';
        if (action.payload.data.length) {
          const data = action.payload.data.find(
            (i: AppointmentData) => new Date(i.date) >= new Date()
          );
          if (data) {
            state.selectedDate = new Date(data.date).getTime();
          } else {
            state.selectedDate = new Date(
              action.payload.data[0].date
            ).getTime();
          }
        }
        state.selectedDateAppointments = state.data.filter((i) =>
          isSameDay(new Date(i.date), state.selectedDate)
        );
      })
      .addCase(fetchCancelAppointment.pending, (state, action) => {
        state.isCancel = 'wait';
      })
      .addCase(fetchCancelAppointment.fulfilled, (state, action) => {
        state.isCancel = 'canceled';
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
        }
      );
  },
});

export const {
  goToNextMonthSchedule,
  setAppointmentStartDate,
  goToNextMonth,
  goToPreviousMonth,
  setAppointmentSelectedDate,
  changeCancelLessonAppointment,
} = appointmentSlice.actions;

export const selectCancelLesson = (state: RootState) =>
  state.appointment.cancelLesson;

export const selectCancelLessonDetail = (state: RootState) =>
  state.appointment.cancelLessonDetail;

export const selectLoadingAppointmentData = (state: RootState) =>
  state.appointment.loading;

export const selectAppointmentData = (state: RootState) =>
  state.appointment.data;

export const selectAppointmentSelectedDate = (state: RootState) =>
  state.appointment.selectedDate;

export const selectAppointmentCurrentDate = (state: RootState) =>
  state.appointment.isCurrentDate;

export const selectAppointmentStartDate = (state: RootState) =>
  state.appointment.startDate;

export const selectSelectedDateAppointments = (state: RootState) =>
  state.appointment.selectedDateAppointments;

export const statusCancelLesson = (state: RootState) =>
  state.appointment.isCancel;

export default appointmentSlice.reducer;
