import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export interface ShareVideoData {
  id: string;
  videoUrl: string;
  sharingItemType: string;
  thumbnailUrl: string;
  title: string;
}

export const fetchShareVideo = createAsyncThunk(
  'share/shareLesson',
  async (payload: ShareVideoData, thunkAPI) => {
    try {
      const dataObj = {
        sourceId: payload.id,
        url: payload.videoUrl,
        type: payload.sharingItemType,
        thumbnail: payload.thumbnailUrl,
        title: payload.title,
        description: payload.title,
      };

      const response = await request.post('/api/share', dataObj);
      let data = await response;
      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

interface ShareVideoState {
  loading: boolean;
  error: number | null;
  data: null | {shortenUrl: string};
  openModal: boolean;
  shortenUrl: string | null;
}

const initialState: ShareVideoState = {
  loading: false,
  error: null,
  data: null,
  openModal: false,
  shortenUrl: null,
};

export const shareVideoSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    changeOpenSocialShare: (state, action) => {
      state.openModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShareVideo.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchShareVideo.fulfilled, (state, action) => {
        state.error = null;
        state.data = action.payload.data;
        state.shortenUrl = action.payload.data.shortenUrl;
        state.loading = false;
       
      })
      .addCase(fetchShareVideo.rejected, (state, action) => {
        state.data = null;
        state.loading = false;
        if (action.payload) {
          state.error = (action.payload as { code: number }).code;
        }
      })
      .addCase(signOut, () => ({ ...initialState }));
  },
});

export const { changeOpenSocialShare } = shareVideoSlice.actions;
export const selectOpenSocialShare = (state: RootState) =>
  state.shareVideo.openModal;
export const selectDataShareVideo = (state: RootState) => state.shareVideo.data;
export const selectShortenUrlShare = (state: RootState) =>
  state.shareVideo.shortenUrl;
export const loadingShareVideo = (state: RootState) => state.shareVideo.loading;
export const selectErrorCodeShareVideo = (state: RootState) =>
  state.shareVideo.error;
export default shareVideoSlice.reducer;
