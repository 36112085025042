import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { NearstCenterData, RegisterData, RegisterJapanData } from '../models/register';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const signUp = createAsyncThunk(
  'register/signUp',
  async (params: RegisterData, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/v2/player`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const signUpJapan = createAsyncThunk(
  'register/signUpJapan',
  async (params: RegisterJapanData, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/v2/player`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const validateEmailUsed = createAsyncThunk(
  'register/validateEmailUsed',
  async (email: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/validate/email?email=${email}`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const detectCountryInfo = createAsyncThunk(
  'register/detectCountryInfo',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/centers/contact`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const detectZipCode = createAsyncThunk(
  'register/detectZipCode',
  async (zipCode: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/centers/closest?postal=${zipCode}`
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const verificationCode = createAsyncThunk(
  'register/verificationCode',
  async (params: VerifyCode, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/v2/confirmation-code`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const verifyCodeEmail = createAsyncThunk(
  'register/verifyCodeEmail',
  async (params: { email: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/v2/player/email`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const methodOfVerifyCode = createAsyncThunk(
  'register/methodOfVerifyCode',
  async (params: { email: string, phone: string, verifyMethod: number }, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/player/verify`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestChangeEmail = createAsyncThunk(
  'register/requestChangeEmail',
  async (params: { email: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/change-email/verify`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const verifyChangeEmail = createAsyncThunk(
  'register/verifyChangeEmail',
  async (params: { code: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.put(`/api/change-email/verify`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateChangeEmail = createAsyncThunk(
  'register/updateChangeEmail',
  async (
    params: { email: string; emailSubscription: string[] },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(`/api/change-email/update`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateTermsOfUser = createAsyncThunk(
  'register/updateTermsOfUser',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.put(`/api/v2/player`, {
        fields: { readTermOfUse: { key: 'readTermOfUse', value: true } }
      });
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateBirthDay = createAsyncThunk(
  'register/updateBirthDay',
  async (
    params: { day: number; month: number; year: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(`/api/v2/player`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateAgeAffirmation = createAsyncThunk(
  'register/updateAgeAffirmation',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.put(`/api/v2/player`, {
        ageAffirmation: true
      });
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);



interface Location {
  region: string | '';
  postalColde: string | '';
  centers: NearstCenterData[] | null;
}

interface VerifyCode {
  code: string | '';
  type: string | '';
  email: string | '';
}

interface RegisterState {
  data: {} | null;
  errorRegister: null | number;
  locations: Location | null;
  centers: NearstCenterData[] | null;
  method: number;
}

const initialState: RegisterState = {
  data: null,
  errorRegister: null,
  locations: null,
  centers: null,
  method: 2
};

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    changeMethodVerifyAccount: (state, action) => {
      state.method = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(detectCountryInfo.fulfilled, (state, action) => {
      state.locations = action.payload.data;
    });
    builder.addCase(detectZipCode.fulfilled, (state, action) => {
      state.centers = action.payload.data;
    });
  }
});

export const { changeMethodVerifyAccount } = registerSlice.actions;

export const selectMethodVerifyAccount = (state: RootState) => state.register.method;

export const selectLocations = (state: RootState) => state.register.locations;

export const selectNearsCenterByZipCode = (state: RootState) =>
  state.register.centers;

export default registerSlice.reducer;
