import axios from 'axios';
import returnBaseUrl from '../components/CommonFunction/baseUrl';
import { changeAppToken, signOut } from '../slices/user';
import { store } from './store';

const baseUrl = returnBaseUrl();

const request = axios.create({
  baseURL: baseUrl,
});

request.interceptors.request.use(
  function (config) {
    const state = store.getState();
    const { tokenChildren } = state.user;
    let {
      token,
      eToken,
      eStoreCode,
      eUrl,
      eStoreCodeChildren,
      eUrlChildren,
      eTokenChildren,
    } = state.user;
    const { locale } = state.language;
    let queryParameters = window.location.search;
    let params = new URLSearchParams(queryParameters);
    let subAccount = params.get('sub-account');

    if (!config.url?.match(/api\/translation/)) {
      config.headers['accept-language'] = locale;
    }
    const childrenToken = sessionStorage.getItem('childrenToken');
    if (tokenChildren && childrenToken) {
      token = tokenChildren;
      eToken = eTokenChildren;
      eUrl = eUrlChildren;
      // eUrl = 'https://release-ecommerce.golftec.com';
      eStoreCode = eStoreCodeChildren;
    }

    const dynamicString = 'rest/V1/giftcard/check';
    const regex = new RegExp(`${dynamicString}`);

    if (
      config.url?.match(/graphql|STSVEN|STSVCA/i) ||
      (config.url && regex.test(config.url))
    ) {
      if (eUrl) {
        config.baseURL = eUrl;
      } else {
        if (baseUrl.match(/api-clubhousefieldtest|api-clubhouseprod/)) {
          config.baseURL = 'https://ecommerce.golftec.com';
        } else if (baseUrl.match(/api-clubhousestag/)) {
          config.baseURL = 'https://qa-ecommerce.golftec.com';
        }
      }
      if (regex.test(config.url)) {
        if (baseUrl.match(/api-clubhousefieldtest|api-clubhouseprod/)) {
          config.headers.authorization = `Bearer ls1qmu2u3kkbl73jbyuae1e4n9bj5qqu`;
        } else if (baseUrl.match(/api-clubhousestag/)) {
          config.headers.authorization = `Bearer ux22xxst842gbenituv43jb2c89rv5vz`;
        }
      } else if (eToken) {
        config.headers.authorization = `Bearer ${eToken}`;
      }
      if (eStoreCode) {
        config.headers.store = eStoreCode;
      }
    } else if (!config.url?.match(/api\/translation/) && token) {
      config.headers.authorization = `Bearer ${token}`;
    } else if (subAccount !== null && token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

interface subscriberCallback {
  (token: string): void;
}

let isAlreadyFetchingAccessToken = false;
let restSubscribers: subscriberCallback[] = [];
let graphqlSubscribers: subscriberCallback[] = [];

function onAccessTokenFetched(token: string) {
  restSubscribers = restSubscribers.filter((callback) => callback(token));
}

function onEcommerceTokenFetched(token: string) {
  graphqlSubscribers = graphqlSubscribers.filter((callback) => callback(token));
}

function addRestSubscriber(callback: subscriberCallback) {
  restSubscribers.push(callback);
}

function addGraphqlSubscriber(callback: subscriberCallback) {
  graphqlSubscribers.push(callback);
}

const refreshAuthToken = async () => {
  try {
    const state = store.getState();
    const url = new URL('api/v2/player/refresh-token', baseUrl);
    const { refreshToken } = state.user;
    const {
      data: { data },
    } = await axios.post(url.href, {
      refresh_token: refreshToken,
    });
    store.dispatch(
      changeAppToken({
        token: data.access_token,
        refreshToken: data.refresh_token,
        eToken: data.eToken,
        eStoreCode: data.eStoreCode,
        eUrl: data.eUrl,
        // eUrl: 'https://release-ecommerce.golftec.com',
      })
    );
    return data;
  } catch {
    store.dispatch(signOut());
  }
};

request.interceptors.response.use(
  async function (response) {
    const { config, data, status } = response;
    const originalRequest = config;

    // if (
    //   status === 200 &&
    //   data.errors &&
    //   data.errors.length &&
    //   data.errors[0].extensions.category === 'graphql-authorization'
    // ) {
    //   if (!isAlreadyFetchingAccessToken) {
    //     try {
    //       isAlreadyFetchingAccessToken = true;

    //       const data = await refreshAuthToken();

    //       isAlreadyFetchingAccessToken = false;
    //       onEcommerceTokenFetched(data.eToken);
    //     } catch {
    //       store.dispatch(signOut());
    //     }
    //   }

    //   return new Promise((resolve) => {
    //     addGraphqlSubscriber((token) => {
    //       originalRequest.headers.authorization = 'Bearer ' + token;
    //       resolve(axios(originalRequest));
    //     });
    //   });
    // }

    return response;
  },
  async function (error) {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    if (status === 401 && store.getState().user.isAuthenticated) {
      if (!isAlreadyFetchingAccessToken) {
        try {
          isAlreadyFetchingAccessToken = true;

          const data = await refreshAuthToken();

          isAlreadyFetchingAccessToken = false;
          onAccessTokenFetched(data.access_token);
        } catch {
          store.dispatch(signOut());
        }
      }

      return new Promise((resolve) => {
        addRestSubscriber((token) => {
          originalRequest.headers.authorization = 'Bearer ' + token;
          resolve(axios(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export default request;
