import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  MembershipPack,
  MembershipPlan,
  PlayerMembership,
} from '../models/renewal';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const requestGamePlans = createAsyncThunk(
  'renewal/requestGamePlans',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/renewal/game-plan`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestGamePlansV2 = createAsyncThunk(
  'renewal/requestGamePlansV2',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/renewal/v2/game-plan`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestConfirmOrderRenewal = createAsyncThunk(
  'renewal/requestConfirmOrderRenewal',
  async (params: object, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/v2/renewal`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getRenewalMembership = createAsyncThunk(
  'renewal/getRenewalMembership',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/renewal/membership`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getPlayerMembership = createAsyncThunk(
  'renewal/getPlayerMembership',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/membership`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const checkGiftCodeRenewal = createAsyncThunk(
  'renewal/checkGiftCodeRenewal',
  async (giftCode: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/gift-code/${giftCode}`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface CustomTax {
  taxName: string;
  taxValue: number;
}

export interface GiftCodeRenewal {
  giftCode: string;
  balance: string;
  status: number;
}

export interface GamePlanData {
  isLastPurchase: boolean;
  isOnSale: boolean;
  id: string;
  name: string;
  price: number;
  salePrice: number;
  term: number;
  numLesson: number;
  shName: string;
  customTax: CustomTax[];
  isMemberShip?: boolean;
  membershipDiscountLoyalty?: string;
  membershipDiscountGOLFTECProShop?: string;
  membershipIncludeGOLFTECApp?: string;
  membershipLessonDuration?: string;
  membershipLessonPerMonth?: string;
  membershipLessonPractice?: string;
  membershipPack: [] | MembershipPack[];
  packsNoPractice: [] | MembershipPack[];
  packsWithPractice: [] | MembershipPack[];
  isRecommended?: boolean;
  packByTerm: MembershipPlan;
  isIncludePractice?: boolean;
  loyaltyExpireDate?: string;
  loyaltyPrice?: number;
  loyaltyDiscount?: number;
  proDiscount?: number;
}

export interface RenewMembershipPlan {
  numLesson: number;
  term: number;
  isIncludePractice: boolean;
}

type GamePlanState = {
  buck: number;
  gamePlans: null | GamePlanData[];
  isMemberShip: boolean;
  lessonPacks: null | GamePlanData[];
  tax: number;
  taxName: string;
  selectPackage: null | GamePlanData;
  loadingRenewal: boolean;
  paymentStatus: boolean;
  membershipData: null | MembershipPack[];
  playerMembership: null | PlayerMembership[];
  membershipPlanName: string;
  loadingPlayerMembership: boolean;
  renewPlan: null | RenewMembershipPlan;
  giftCodeUsage: GiftCodeRenewal[];
  statusUsageBucks: boolean;
};

const initialState: GamePlanState = {
  buck: 0,
  gamePlans: null,
  isMemberShip: false,
  lessonPacks: null,
  tax: 0,
  taxName: '',
  selectPackage: null,
  loadingRenewal: false,
  paymentStatus: false,
  membershipData: [],
  playerMembership: null,
  membershipPlanName: '',
  loadingPlayerMembership: false,
  renewPlan: null,
  giftCodeUsage: [],
  statusUsageBucks: false,
};

export const renewalSlice = createSlice({
  name: 'renewal',
  initialState,
  reducers: {
    renewMemberShipPlan: (state, action) => {
      state.renewPlan = action.payload;
    },
    setSelectMembershipPlan: (state, action) => {
      state.membershipPlanName = action.payload;
    },
    setSelectPackage: (state, action) => {
      state.selectPackage = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    changeStatusUsageBucks: (state, action) => {
      state.statusUsageBucks = action.payload;
    },
    removeGiftCodeRenewal: (state, action) => {
      state.giftCodeUsage = state.giftCodeUsage.filter(
        (i) => i.giftCode !== action.payload
      );
    },
    resetRenewal: (state) => ({
      ...initialState,
      gamePlans: state.gamePlans,
      lessonPacks: state.lessonPacks,
      tax: state.tax,
      buck: state.buck,
      taxName: state.taxName,
      membershipData: state.membershipData,
      playerMembership: state.playerMembership,
      isMemberShip: state.isMemberShip,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestGamePlans.pending, (state, action) => {
      state.loadingRenewal = true;
    });
    builder.addCase(requestGamePlans.fulfilled, (state, action) => {
      state.gamePlans = action.payload.data.gamePlans;
      state.lessonPacks = action.payload.data.lessonPacks;
      state.buck = action.payload.data.buck;
      state.tax = action.payload.data.tax;
      state.taxName = action.payload.data.taxName;
      state.loadingRenewal = false;
    });
    builder
      .addCase(requestGamePlansV2.pending, (state, action) => {
        state.loadingRenewal = true;
      })
      .addCase(requestGamePlansV2.fulfilled, (state, action) => {
        if (action.payload.data.membershipPacks?.length > 0) {
          state.isMemberShip = true;
          state.gamePlans = action.payload.data.membershipPacks;
        } else {
          state.isMemberShip = false;
          state.gamePlans = action.payload.data.gamePlans;
        }
        state.lessonPacks = action.payload.data.lessonPacks;
        state.buck = action.payload.data.buck;
        state.tax = action.payload.data.tax;
        state.taxName = action.payload.data.taxName;
        state.loadingRenewal = false;
      });
    builder.addCase(getRenewalMembership.fulfilled, (state, action) => {
      state.membershipData = action.payload.data.membershipPacks;
      state.buck = action.payload.data.buck;
      state.tax = action.payload.data.tax;
      state.taxName = action.payload.data.taxName;
    });
    builder
      .addCase(getPlayerMembership.pending, (state, action) => {
        state.loadingPlayerMembership = true;
      })
      .addCase(getPlayerMembership.fulfilled, (state, action) => {
        state.loadingPlayerMembership = false;
        state.playerMembership = action.payload.data;
      });
    builder.addCase(checkGiftCodeRenewal.fulfilled, (state, action) => {
      state.giftCodeUsage = [...state.giftCodeUsage, action.payload.data];
    });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  setSelectMembershipPlan,
  setSelectPackage,
  resetRenewal,
  setPaymentStatus,
  renewMemberShipPlan,
  changeStatusUsageBucks,
  removeGiftCodeRenewal,
} = renewalSlice.actions;

export const selectGamePlans = (state: RootState) => state.renewal.gamePlans;
export const selectedPackageRenewal = (state: RootState) =>
  state.renewal.selectPackage;
export const selectLessonPacks = (state: RootState) =>
  state.renewal.lessonPacks;
export const selectBucks = (state: RootState) => state.renewal.buck;
export const selectTaxRenewal = (state: RootState) => state.renewal.tax;
export const selectTaxName = (state: RootState) => state.renewal.taxName;
export const selectLoadingRenewal = (state: RootState) =>
  state.renewal.loadingRenewal;
export const selectPaymentStatus = (state: RootState) =>
  state.renewal.paymentStatus;
export const selectMembershipData = (state: RootState) =>
  state.renewal.membershipData;
export const selectPlayerMembership = (state: RootState) =>
  state.renewal.playerMembership;
export const selectLoadingPlayerMembership = (state: RootState) =>
  state.renewal.loadingPlayerMembership;
export const selectMembershipPlanName = (state: RootState) =>
  state.renewal.membershipPlanName;
export const selectIsMembership = (state: RootState) =>
  state.renewal.isMemberShip;
export const selectDataRenewMembership = (state: RootState) =>
  state.renewal.renewPlan;
export const selectGiftCodeRenewalUsage = (state: RootState) =>
  state.renewal.giftCodeUsage;
export const selectStatusUsageBucks = (state: RootState) =>
  state.renewal.statusUsageBucks;

export default renewalSlice.reducer;
