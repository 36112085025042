import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { CustomizableOptionInput } from '../models/shop';
import {
  ADD_PRODUCT_BUNDLE_SUBSCRIPTION_TO_CART,
  ADD_PRODUCT_BUNDLE_TO_CART,
  ADD_PRODUCT_SIMPLE_TO_CART,
  ADD_PRODUCT_SUBSCRIPTION_TO_CART,
  ADD_PRODUCT_VIRTUAL_SUBSCRIPTION_TO_CART,
  ADD_PRODUCT_VIRTUAL_TO_CART,
} from '../utils/mutations';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { setStatusAddCart } from './getValueAddToCart';
import { getCustomerCart, selectCustomerCart } from './shop';
import { signOut } from './user';

interface VariableProductSimple {
  quantity: number;
  sku: string;
  customizable_options?: [CustomizableOptionInput];
}

export const addProductSimpleToCart = createAsyncThunk(
  'addCart/productSimple',
  async (payload: VariableProductSimple, { getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as any);
      if (cart) {
        const {
          data: { data, errors },
        } = await request.post('graphql', {
          query: print(ADD_PRODUCT_SIMPLE_TO_CART),
          variables: {
            cartId: cart.id,
            quantity: payload.quantity,
            sku: payload.sku,
          },
        });
        if (
          data.addSimpleProductsToCart !== null &&
          data.addSimpleProductsToCart.cart
        ) {
          return data;
        } else {
          return errors[0];
        }
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ProductSimpleSubscription {
  cartItem: any;
}

export const addProductSimpleSubscriptionToCart = createAsyncThunk(
  'addCart/productSimpleSubscription',
  async (payload: ProductSimpleSubscription, { getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as any);
      if (cart) {
        const {
          data: { data, errors },
        } = await request.post('graphql', {
          query: print(ADD_PRODUCT_SUBSCRIPTION_TO_CART),
          variables: {
            cartId: cart.id,
            cartItem: payload.cartItem,
          },
        });
        if (
          data.addSimpleProductsToCart !== null &&
          data.addSimpleProductsToCart.cart
        ) {
          return data;
        } else {
          return errors[0];
        }
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

type ProductBundle = {
  cartItems: any;
};
export const addProductBundleToCart = createAsyncThunk(
  'addCart/addProductBundleToCart',
  async (payload: ProductBundle, { dispatch, getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          query: print(ADD_PRODUCT_BUNDLE_TO_CART),
          variables: {
            cartId: cart.id,
            cartItems: payload.cartItems,
          },
        });
        if (data.errors) {
          dispatch(setStatusAddCart('addFailed'));
          return data.errors[0];
        } else {
          dispatch(setStatusAddCart('addSuccess'));
          dispatch(getCustomerCart());
          return data;
        }
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ProductBundleSubscription {
  cartItem: any;
}

export const addProductBundleSubscriptionToCart = createAsyncThunk(
  'addCart/addProductBundleSubscriptionToCart',
  async (
    payload: ProductBundleSubscription,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          query: print(ADD_PRODUCT_BUNDLE_SUBSCRIPTION_TO_CART),
          variables: {
            cartId: cart.id,
            cartItem: payload.cartItem,
          },
        });
        if (data.errors) {
          dispatch(setStatusAddCart('addFailed'));
          return data.errors[0];
        } else {
          dispatch(setStatusAddCart('addSuccess'));
          dispatch(getCustomerCart());
          return data;
        }
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

type VariableProductVirtualSubscription = {
  quantity: number;
  sku: string;
  optionId?: number;
  valueString?: string;
};

export const addProductVirtualSubscriptionToCart = createAsyncThunk(
  'addCart/addProductVirtualSubscriptionToCart',
  async (
    payload: VariableProductVirtualSubscription,
    { getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as any);
      if (cart) {
        const { data } = await request.post('graphql', {
          query: print(ADD_PRODUCT_VIRTUAL_SUBSCRIPTION_TO_CART),
          variables: {
            cartId: cart.id,
            quantity: payload.quantity,
            sku: payload.sku,
            optionId: payload.optionId,
            valueString: payload.valueString,
          },
        });
        return data;
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addProductVirtualToCart = createAsyncThunk(
  'addCart/addProductVirtualToCart',
  async (
    payload: VariableProductVirtualSubscription,
    { getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as any);
      if (cart) {
        const { data } = await request.post('graphql', {
          query: print(ADD_PRODUCT_VIRTUAL_TO_CART),
          variables: {
            cartId: cart.id,
            quantity: payload.quantity,
            sku: payload.sku,
          },
        });
        return data;
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface VariableProductSimpleState {
  loading: boolean;
  isAddCart: string;
  data: any;
}

const initialState: VariableProductSimpleState = {
  loading: false,
  isAddCart: '',
  data: null,
};

export const addSimpleProductToCartSlice = createSlice({
  name: 'productSimple',
  initialState,
  reducers: {
    changeStatusIsAddCart: (state, action) => {
      state.isAddCart = '';
    },
    changeStatusIsAddCartProductSubscription: (state, action) => {
      state.isAddCart = '';
    },
    clearDataAddCart: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProductSimpleToCart.pending, (state, action) => {
        state.loading = true;
        state.isAddCart = 'wait';
      })
      .addCase(addProductSimpleToCart.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        if (action.payload?.message) {
          state.isAddCart = 'exit';
        } else state.isAddCart = 'added';
      });
    builder
      .addCase(addProductSimpleSubscriptionToCart.pending, (state, action) => {
        state.loading = true;
        state.isAddCart = 'wait';
      })
      .addCase(
        addProductSimpleSubscriptionToCart.fulfilled,
        (state, action) => {
          state.loading = false;
          state.data = action.payload;
          if (action.payload?.message) {
            state.isAddCart = 'exit';
          } else state.isAddCart = 'added';
        }
      );
    builder
      .addCase(addProductBundleToCart.pending, (state, action) => {
        state.loading = true;
        state.isAddCart = 'wait';
      })
      .addCase(addProductBundleToCart.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.isAddCart = 'added';
      });
    builder
      .addCase(addProductBundleSubscriptionToCart.pending, (state, action) => {
        state.loading = true;
        state.isAddCart = 'wait';
      })
      .addCase(
        addProductBundleSubscriptionToCart.fulfilled,
        (state, action) => {
          state.loading = false;
          state.data = action.payload;
          state.isAddCart = 'added';
        }
      );
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  changeStatusIsAddCart,
  clearDataAddCart,
  changeStatusIsAddCartProductSubscription,
} = addSimpleProductToCartSlice.actions;
export const selectAddProductSubscriptionToCart = (state: RootState) =>
  state.productSimple.isAddCart;
export const selectDataAddCartProductSimple = (state: RootState) =>
  state.productSimple.data;
export const selectAddProductSimpleToCart = (state: RootState) =>
  state.productSimple.isAddCart;
export const loadingAddProductSimpleToCart = (state: RootState) =>
  state.productSimple.loading;
export default addSimpleProductToCartSlice.reducer;
