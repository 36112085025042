import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface ChangePasswordData {
  oldPassword: string;
  newPassword: string;
}

export const fetchChangePassword = createAsyncThunk(
  'changePass/changePassword',
  async (payload: ChangePasswordData, thunkAPI) => {
    try {
      const response = await request.post('/api/player/change-password', {
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
      });
      let data = await response;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

interface ChangePasswordState {
  isChanged: string;
  error: any;
  data: any;
}

const initialState: ChangePasswordState = {
  isChanged: '',
  error: null,
  data: null,
};

export const changePasswordSlice = createSlice({
  name: 'changePass',
  initialState,
  reducers: {
    changeChangePasswordError: (state, action) => {
      state.error = action.payload;
    },
    changeChangePasswordStatus: (state, action) => {
      state.isChanged = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChangePassword.pending, (state, action) => {
        state.isChanged = 'wait';
      })
      .addCase(fetchChangePassword.fulfilled, (state, action) => {
        state.isChanged = 'changed';
        state.data = action.payload;
      })
      .addCase(fetchChangePassword.rejected, (state, action) => {
        state.error = action.payload;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { changeChangePasswordError, changeChangePasswordStatus } =
  changePasswordSlice.actions;

export const selectChangePassword = (state: RootState) =>
  state.changePass.isChanged;
export const selectChangePasswordErrorCode = (state: RootState) =>
  state.changePass.error;
export default changePasswordSlice.reducer;
