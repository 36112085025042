import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { SkyTrakState } from '../models/skytrak';
import {
  REDEEM_MEMBERSHIP_GIFT_CARD,
  REORDER_ITEM,
  VALIDATE_MEMBERSHIP_GIFT_CARD,
} from '../utils/mutations';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { UserData } from './../models/user';
import { signOut } from './user';

export const fetchMembershipSkyTrak = createAsyncThunk(
  'skyTrak/fetchMembership',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        '/api/skytrak/membership?source=simulation,legacy,skytrak'
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchMyDeviceSkyTrak = createAsyncThunk(
  'skyTrak/fetchMyDevice',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/skytrak/devices');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const checkDeviceByESN = createAsyncThunk(
  'skyTrak/checkDeviceByESN',
  async (esn: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`api/skytrak/device/check?esn=${esn}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const registerDeviceSkyTrak = createAsyncThunk(
  'skyTrak/registerDevice',
  async (payload: ParamRegisterDevice, { rejectWithValue }) => {
    try {
      const { data } = await request.post(
        `/api/skytrak/device/register`,
        payload
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const unRegisterDeviceSkyTrak = createAsyncThunk(
  'skyTrak/unRegisterDevice',
  async (payload: { deviceId: number; esn: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.post(
        `/api/skytrak/device/unregister`,
        payload
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchPurchasedHistorySkyTrak = createAsyncThunk(
  'skyTrak/fetchPurchasedHistorySkyTrak',
  async (
    payload: {
      startDate?: string;
      endDate?: string;
      limit: number;
      offset: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.get(`/api/skytrak/purchase`, {
        params: payload,
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateStatusCustomerMembershipsSkyTrak = createAsyncThunk(
  'skyTrak/updateStatusCustomerMembershipsSkyTrak',
  async (
    payload: { id: number; isAutoRenew: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(
        `/api/skytrak/membership/status`,
        payload
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateCustomerSkyTrak = createAsyncThunk(
  'skyTrak/updateCustomerSkyTrak',
  async (payload: UserData, { rejectWithValue }) => {
    try {
      const { data } = await request.put(`api/skytrak/customer`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;

      return rejectWithValue(error?.response?.data);
    }
  }
);

export const changePasswordSkyTrak = createAsyncThunk(
  'skyTrak/changePasswordSkyTrak',
  async (
    payload: { oldPassword: string; newPassword: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(
        `/api/skytrak/customer/change-password`,
        payload
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const reportDeviceSkyTrak = createAsyncThunk(
  'skyTrak/reportDeviceSkyTrak',
  async (
    payload: {
      deviceId: number;
      blackListCode: string;
      blackListComment: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(`/api/skytrak/device/report`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const reOrderItemsSkyTrak = createAsyncThunk(
  'skyTrak/reOrderItemsSkyTrak',
  async (orderNumber: string, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(REORDER_ITEM),
        variables: {
          orderNumber,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const validateMembershipGiftCard = createAsyncThunk(
  'skyTrak/validateMembershipGiftCard',
  async (gift_code: string, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(VALIDATE_MEMBERSHIP_GIFT_CARD),
        variables: {
          gift_code,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const redeemMembershipGiftCard = createAsyncThunk(
  'skyTrak/redeemMembershipGiftCard',
  async (
    payload: { gift_code: string; customer_email: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(REDEEM_MEMBERSHIP_GIFT_CARD),
        variables: {
          gift_code: payload.gift_code,
          customer_email: payload.customer_email,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const renewalPackageLegacyUS = createAsyncThunk(
  'skyTrak/renewalPackageLegacyUS',
  async (
    payload: {
      customerEmail: string;
      acceptjsKey: string;
      acceptjsValue: string;
      cart: { sku: string; options?: {}; qty: number }[];
      nextCharge: string;
      activeDate: string;
      referenceLegacyId: number;
      infoCard: {
        cc_type: string;
        ccv: number;
        cc_last_4: string;
        cc_exp_month: string;
        cc_exp_year: string;
        cc_cid: number;
      };
      customerAddressId: number;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        `/rest/STSVEN/V1/subscription/chargeFuture`,
        {
          request: {
            customerEmail: payload.customerEmail,
            acceptjsKey: payload.acceptjsKey,
            acceptjsValue: payload.acceptjsValue,
            cart: payload.cart,
            nextCharge: payload.nextCharge,
            activeDate: payload.activeDate,
            referenceLegacyId: payload.referenceLegacyId,
            infoCard: payload.infoCard,
            customerAddressId: payload.customerAddressId,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const renewalPackageLegacyCA = createAsyncThunk(
  'skyTrak/renewalPackageLegacyCA',
  async (
    payload: {
      customerEmail: string;
      cart: { sku: string; options?: {}; qty: number }[];
      infoCard: {
        cc_type: string;
        ccv: number;
        cc_last_4: string;
        cc_number: string;
        cc_number_enc: string;
        cc_exp_month: string;
        cc_exp_year: string;
        cc_cid: number;
      };
      referenceLegacyId: number;
      activeDate: string;
      nextCharge: string;
      customerAddressId: number;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        `/rest/STSVCA/V1/subscription/chargeMoneris`,
        {
          request: {
            customerEmail: payload.customerEmail,
            cart: payload.cart,
            nextCharge: payload.nextCharge,
            activeDate: payload.activeDate,
            referenceLegacyId: payload.referenceLegacyId,
            infoCard: payload.infoCard,
            customerAddressId: payload.customerAddressId,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const upgradeMembershipSkytrak = createAsyncThunk(
  'skyTrak/upgradeMembership',
  async (
    payload: {
      customerEmail: string;
      payment_method: string;
      acceptjsKey?: string;
      acceptjsValue?: string;
      cart: { sku: string; options?: {}; qty: number }[];
      infoCard: {
        ccv: number;
        cc_last_4: string;
        cc_number: string;
        cc_number_enc: string;
        cc_exp_month: string;
        cc_exp_year: string;
        cc_cid: number;
        cc_type: string;
      };
      nextCharge: string;
      activeDate: string;
      referenceLegacyId: number;
      customerAddressId: number;
      storeCode: string;
      subscriptionID: null | number;
      typeProduct: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        `/rest/${payload.storeCode}/V1/subscription/upgradePlan`,
        {
          request: {
            customerEmail: payload.customerEmail,
            cart: payload.cart,
            acceptjsKey: payload.acceptjsKey,
            acceptjsValue: payload.acceptjsValue,
            nextCharge: payload.nextCharge,
            activeDate: payload.activeDate,
            referenceLegacyId: payload.referenceLegacyId,
            infoCard: payload.infoCard,
            payment_method: payload.payment_method,
            customerAddressId: payload.customerAddressId,
            subscriptionID: payload.subscriptionID,
            typeProduct: payload.typeProduct,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCountriesEU = createAsyncThunk(
  'skyTrak/getCountriesEU',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/STSVEN/rest/all/V1/getCountryEU`);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ParamRegisterDevice {
  deviceId: number;
  esn: string;
  customerId: number;
  membershipId: number | null;
  customerMembershipId: number | null;
  isFirstTime: boolean;
}

const initialState: SkyTrakState = {
  statusOpenEditDevice: false,
  dataMembershipSkyTrak: null,
  dataDeviceSkyTrak: null,
  statusOpenRegisterDevice: false,
  dataOwnDeviceByESN: null,
  statusRegisterDevice: 'invalidESN',
  statusActiveMembership: 'selectDevice',
  membershipActive: null,
  skyTrakCode: 0,
  esnInput: '',
  statusOpenActiveMembership: false,
  dataAllOwnDeviceByESN: [],
  dataPurchasedHistorySkyTrak: null,
  sortPurchasedByProperties: 'createdAt',
  fromDatePurchase: '',
  toDatePurchase: '',
  statusPurchase: 'all',
  statusOpenAddAddress: false,
  statusOpenEditAddress: false,
  statusOpenDeleteAddress: false,
  statusOpenSellTransfer: false,
  addressEdit: null,
  addressDelete: null,
  reportDevice: null,
  statusOpenReportDevice: false,
  loadingDeviceSkyTrak: false,
  loadingMembershipSkyTrak: false,
  loadingPurchaseHistorySkyTrak: false,
  sellTransferDevice: null,
  upGradeDevice: null,
  statusOpenUpGradeDevice: false,
  statusOpenBuyMembership: false,
  chooseBuyMembership: null,
  statusBuyMembership: 'selectPlans',
  statusOpenPrePaidMembership: false,
  statusPrePaidMembership: 'inputCodeCard',
  idMembershipPrepaid: 0,
  nameMembershipPrepaid: '',
  giftCodeTypePrepaid: null,
  statusPrepaidSimulatorMembership: 'membership',
  statusOpenSendInfoPayment: false,
  membershipRenewal: null,
  idMembershipRenewed: 0,
  statusUpgradeMembership: false,
  membershipUpgrade: null,
  idMembershipUpgraded: 0,
  countriesEU: [],
  codeResponse: 0,
  deviceBlocked: false,
  statusNeedChangeCountry: false,
};

export const skyTrakSlice = createSlice({
  name: 'skyTrak',
  initialState,
  reducers: {
    changeStatusOpenEditDevice: (state, action) => {
      state.statusOpenEditDevice = action.payload;
    },
    changeStatusOpenRegisterDevice: (state, action) => {
      state.statusOpenRegisterDevice = action.payload;
    },
    changeStatusRegisterDevice: (state, action) => {
      state.statusRegisterDevice = action.payload;
    },
    changeStatusActiveMembership: (state, action) => {
      state.statusActiveMembership = action.payload;
    },
    changeStatusOpenActiveMembership: (state, action) => {
      state.statusOpenActiveMembership = action.payload;
    },
    changeActiveMembership: (state, action) => {
      state.membershipActive = action.payload;
    },
    changeESNInput: (state, action) => {
      state.esnInput = action.payload;
    },
    resetDataOwnDeviceByESN: (state) => {
      state.dataOwnDeviceByESN = null;
      state.dataAllOwnDeviceByESN = [];
      state.codeResponse = 0;
      state.deviceBlocked = false;
    },
    changePropertySortBy: (state, action) => {
      state.sortPurchasedByProperties = action.payload;
    },
    changeFromDatePurchase: (state, action) => {
      state.fromDatePurchase = action.payload;
    },
    changeToDatePurchase: (state, action) => {
      state.toDatePurchase = action.payload;
    },
    changeStatusPurchase: (state, action) => {
      state.statusPurchase = action.payload;
    },
    changeStatusOpenAddAddress: (state, action) => {
      state.statusOpenAddAddress = action.payload;
    },
    changeStatusOpenEditAddress: (state, action) => {
      state.statusOpenEditAddress = action.payload;
    },
    changeStatusOpenDeleteAddress: (state, action) => {
      state.statusOpenDeleteAddress = action.payload;
    },
    changeAddressEdit: (state, action) => {
      state.addressEdit = action.payload;
    },
    changeAddressDelete: (state, action) => {
      state.addressDelete = action.payload;
    },
    changeReportDevice: (state, action) => {
      state.reportDevice = action.payload;
    },
    changeStatusOpenReportDevice: (state, action) => {
      state.statusOpenReportDevice = action.payload;
    },
    changeStatusOpenSellTransfer: (state, action) => {
      state.statusOpenSellTransfer = action.payload;
    },
    changeSellTransferDevice: (state, action) => {
      state.sellTransferDevice = action.payload;
    },
    changeStatusOpenUpGradeDevice: (state, action) => {
      state.statusOpenUpGradeDevice = action.payload;
    },
    changeUpGradeDevice: (state, action) => {
      state.upGradeDevice = action.payload;
    },
    changeStatusOpenBuyMembership: (state, action) => {
      state.statusOpenBuyMembership = action.payload;
    },
    changeChooseBuyMembership: (state, action) => {
      state.chooseBuyMembership = action.payload;
    },
    changeStatusBuyMembership: (state, action) => {
      state.statusBuyMembership = action.payload;
    },
    changeStatusOpenPrePaidMembership: (state, action) => {
      state.statusOpenPrePaidMembership = action.payload;
    },
    changeStatusPrePaidMembership: (state, action) => {
      state.statusPrePaidMembership = action.payload;
    },
    changeStatusPrepaidSimulatorMembership: (state, action) => {
      state.statusPrepaidSimulatorMembership = action.payload;
    },
    changeStatusOpenSendInfoPayment: (state, action) => {
      state.statusOpenSendInfoPayment = action.payload;
    },
    changeMembershipRenewal: (state, action) => {
      state.membershipRenewal = action.payload;
    },
    changeIdMembershipRenewed: (state, action) => {
      state.idMembershipRenewed = action.payload;
    },
    changeStatusUpgradeMembership: (state, action) => {
      state.statusUpgradeMembership = action.payload;
    },
    changeMembershipUpgrade: (state, action) => {
      state.membershipUpgrade = action.payload;
    },
    changeIdMembershipUpgraded: (state, action) => {
      state.idMembershipUpgraded = action.payload;
    },
    resetPropertyPurchaseFilter: (state) => {
      state.fromDatePurchase = '';
      state.toDatePurchase = '';
      state.sortPurchasedByProperties = 'createdAt';
      state.statusPurchase = 'all';
    },
    changeStatusNeedChangeCountry: (state, action) => {
      state.statusNeedChangeCountry = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMembershipSkyTrak.pending, (state, action) => {
      state.loadingMembershipSkyTrak = true;
    });
    builder.addCase(fetchMembershipSkyTrak.fulfilled, (state, action) => {
      state.loadingMembershipSkyTrak = false;
      state.dataMembershipSkyTrak = action.payload.data;
    });
    builder.addCase(fetchPurchasedHistorySkyTrak.pending, (state, action) => {
      state.loadingPurchaseHistorySkyTrak = true;
    });
    builder.addCase(fetchPurchasedHistorySkyTrak.fulfilled, (state, action) => {
      state.loadingPurchaseHistorySkyTrak = false;
      state.dataPurchasedHistorySkyTrak = action.payload.data;
    });
    builder.addCase(fetchMyDeviceSkyTrak.pending, (state, action) => {
      state.loadingDeviceSkyTrak = true;
    });
    builder.addCase(fetchMyDeviceSkyTrak.fulfilled, (state, action) => {
      state.loadingDeviceSkyTrak = false;
      state.dataDeviceSkyTrak = action.payload.data;
    });
    builder.addCase(validateMembershipGiftCard.fulfilled, (state, action) => {
      if (
        action.payload.data?.membershipValidateGiftCard?.is_valid &&
        action.payload.data?.membershipValidateGiftCard?.sku_id
      )
        state.idMembershipPrepaid =
          action.payload.data?.membershipValidateGiftCard?.sku_id;
      state.nameMembershipPrepaid =
        action.payload.data?.membershipValidateGiftCard?.sku_name;
      state.giftCodeTypePrepaid =
        action.payload.data?.membershipValidateGiftCard?.gift_code_type;
    });
    builder.addCase(updateCustomerSkyTrak.fulfilled, (state, action) => {
      state.codeResponse = action.payload.code;
    });
    builder.addCase(registerDeviceSkyTrak.fulfilled, (state, action) => {
      state.codeResponse = action.payload.code;
    });
    builder.addCase(checkDeviceByESN.fulfilled, (state, action) => {
      state.codeResponse = action.payload.code;
      state.dataOwnDeviceByESN = action.payload.data;
      state.skyTrakCode = action.payload.code;

      if (action.payload.data.device) {
        state.deviceBlocked = action.payload.data.device.blocked;
        if (!action.payload.data.device.blocked) {
          const idx = state.dataAllOwnDeviceByESN.findIndex(
            (i) => i.deviceId === action.payload.data.device.deviceId
          );
          if (idx >= 0) {
            state.dataAllOwnDeviceByESN[idx] = action.payload.data.device;
          } else {
            state.dataAllOwnDeviceByESN.push(action.payload.data.device);
          }
        }
      }
    });
    builder.addCase(checkDeviceByESN.rejected, (state, action) => {
      state.codeResponse = (action.payload as { code: number }).code;
    });
    builder.addCase(getCountriesEU.fulfilled, (state, action) => {
      state.countriesEU = action.payload.data;
    });
    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loadingDeviceSkyTrak = false;
        state.loadingMembershipSkyTrak = false;
        state.loadingPurchaseHistorySkyTrak = false;
      }
    );
  },
});

export const {
  changeStatusOpenEditDevice,
  changeStatusOpenRegisterDevice,
  changeStatusRegisterDevice,
  changeStatusOpenActiveMembership,
  changeActiveMembership,
  changeStatusActiveMembership,
  changeESNInput,
  resetDataOwnDeviceByESN,
  changePropertySortBy,
  changeFromDatePurchase,
  changeToDatePurchase,
  changeStatusPurchase,
  changeStatusOpenAddAddress,
  changeStatusOpenEditAddress,
  changeStatusOpenDeleteAddress,
  changeAddressEdit,
  changeAddressDelete,
  changeStatusOpenReportDevice,
  changeReportDevice,
  changeStatusOpenSellTransfer,
  changeSellTransferDevice,
  changeStatusOpenUpGradeDevice,
  changeUpGradeDevice,
  changeStatusOpenBuyMembership,
  changeChooseBuyMembership,
  changeStatusBuyMembership,
  resetPropertyPurchaseFilter,
  changeStatusPrePaidMembership,
  changeStatusOpenPrePaidMembership,
  changeStatusPrepaidSimulatorMembership,
  changeStatusOpenSendInfoPayment,
  changeMembershipRenewal,
  changeIdMembershipRenewed,
  changeMembershipUpgrade,
  changeStatusUpgradeMembership,
  changeIdMembershipUpgraded,
  changeStatusNeedChangeCountry,
} = skyTrakSlice.actions;

export const selectStatusOpenEditDevice = (state: RootState) =>
  state.skyStrak.statusOpenEditDevice;
export const selectDataMembershipSkyTrak = (state: RootState) =>
  state.skyStrak.dataMembershipSkyTrak;
export const selectDataDeviceSkyTrak = (state: RootState) =>
  state.skyStrak.dataDeviceSkyTrak;
export const selectStatusOpenRegisterDevice = (state: RootState) =>
  state.skyStrak.statusOpenRegisterDevice;
export const selectStatusRegisterDevice = (state: RootState) =>
  state.skyStrak.statusRegisterDevice;
export const selectDataOwnDeviceByESN = (state: RootState) =>
  state.skyStrak.dataOwnDeviceByESN;
export const selectSkyTrakCode = (state: RootState) =>
  state.skyStrak.skyTrakCode;
export const selectStatusActiveMembership = (state: RootState) =>
  state.skyStrak.statusActiveMembership;
export const selectStatusOpenActiveMembership = (state: RootState) =>
  state.skyStrak.statusOpenActiveMembership;
export const selectMembershipActive = (state: RootState) =>
  state.skyStrak.membershipActive;
export const selectESNInput = (state: RootState) => state.skyStrak.esnInput;
export const selectAllDataOwnDeviceByESN = (state: RootState) =>
  state.skyStrak.dataAllOwnDeviceByESN;
export const selectDataPurchasedHistorySkyTrak = (state: RootState) =>
  state.skyStrak.dataPurchasedHistorySkyTrak;
export const selectPropertySortByPurchase = (state: RootState) =>
  state.skyStrak.sortPurchasedByProperties;
export const selectFromDatePurchase = (state: RootState) =>
  state.skyStrak.fromDatePurchase;
export const selectToDatePurchase = (state: RootState) =>
  state.skyStrak.toDatePurchase;
export const selectStatusPurchase = (state: RootState) =>
  state.skyStrak.statusPurchase;

export const selectStatusOpenAddAddress = (state: RootState) =>
  state.skyStrak.statusOpenAddAddress;
export const selectStatusOpenEditAddress = (state: RootState) =>
  state.skyStrak.statusOpenEditAddress;
export const selectStatusOpenDeleteAddress = (state: RootState) =>
  state.skyStrak.statusOpenDeleteAddress;
export const selectAddressEdit = (state: RootState) =>
  state.skyStrak.addressEdit;
export const selectAddressDelete = (state: RootState) =>
  state.skyStrak.addressDelete;
export const selectReportDevice = (state: RootState) =>
  state.skyStrak.reportDevice;
export const selectStatusOpenReportDevice = (state: RootState) =>
  state.skyStrak.statusOpenReportDevice;
export const selectLoadingDeviceSkyTrak = (state: RootState) =>
  state.skyStrak.loadingDeviceSkyTrak;
export const selectLoadingMembershipSkyTrak = (state: RootState) =>
  state.skyStrak.loadingMembershipSkyTrak;
export const selectLoadingPurchaseHistorySkyTrak = (state: RootState) =>
  state.skyStrak.loadingPurchaseHistorySkyTrak;

export const selectStatusOpenSellTransfer = (state: RootState) =>
  state.skyStrak.statusOpenSellTransfer;
export const selectSellTransferDevice = (state: RootState) =>
  state.skyStrak.sellTransferDevice;
export const selectStatusOpenUpGradeDevice = (state: RootState) =>
  state.skyStrak.statusOpenUpGradeDevice;
export const selectUpGradeDevice = (state: RootState) =>
  state.skyStrak.upGradeDevice;
export const selectStatusOpenBuyMembership = (state: RootState) =>
  state.skyStrak.statusOpenBuyMembership;
export const selectChooseBuyMembership = (state: RootState) =>
  state.skyStrak.chooseBuyMembership;
export const selectStatusBuyMembership = (state: RootState) =>
  state.skyStrak.statusBuyMembership;
export const selectStatusOpenPrePaidMembership = (state: RootState) =>
  state.skyStrak.statusOpenPrePaidMembership;
export const selectStatusPrePaidMembership = (state: RootState) =>
  state.skyStrak.statusPrePaidMembership;
export const selectIdPrePaidMembership = (state: RootState) =>
  state.skyStrak.idMembershipPrepaid;
export const selectNamePrePaidMembership = (state: RootState) =>
  state.skyStrak.nameMembershipPrepaid;
export const selectGiftCodeTypePrepaid = (state: RootState) =>
  state.skyStrak.giftCodeTypePrepaid;
export const selectStatusPrepaidSimulatorMembership = (state: RootState) =>
  state.skyStrak.statusPrepaidSimulatorMembership;
export const selectStatusOpenSendInfoPayment = (state: RootState) =>
  state.skyStrak.statusOpenSendInfoPayment;
export const selectMembershipRenewal = (state: RootState) =>
  state.skyStrak.membershipRenewal;
export const selectIdMembershipRenewed = (state: RootState) =>
  state.skyStrak.idMembershipRenewed;
export const selectStatusUpGradeMembership = (state: RootState) =>
  state.skyStrak.statusUpgradeMembership;
export const selectMembershipUpGrade = (state: RootState) =>
  state.skyStrak.membershipUpgrade;
export const selectIdMembershipUpgraded = (state: RootState) =>
  state.skyStrak.idMembershipUpgraded;
export const selectCountriesEU = (state: RootState) =>
  state.skyStrak.countriesEU;
export const selectCodeResponseSkytrak = (state: RootState) =>
  state.skyStrak.codeResponse;
export const selectStatusDeviceBlocked = (state: RootState) =>
  state.skyStrak.deviceBlocked;
export const selectStatusNeedChangeCountry = (state: RootState) =>
  state.skyStrak.statusNeedChangeCountry;

export default skyTrakSlice.reducer;
