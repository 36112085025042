import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../utils/store';

interface ValueAddCartState {
  qty: number;
  customizableId: number;
  valueString: [];
  parentSku: string;
  sku: string;
  price: number;
  productName: string;
  productPrice: number;
  optionSelected: number;
  stockStatus: boolean;
  status: string;
  bundleSelectOption: [];
  bundleRadioOption: [];
  bundleMultiOption: [];
  bundleCheckBoxOption: [];
  isSelectDefault: boolean;
  isCheckBoxDefault: boolean;
  isRadioDefault: boolean;
  isMultiDefault: boolean;
  bundleTotalCheckBox: [];
  bundleTotalSelect: [];
  bundleTotalRadio: [];
  bundleTotalMulti: [];
  isSubscription: boolean;
  subscriptionOptionPrice: number;
  isOneTime: boolean;
  isSubscribe: boolean;
  selectedSubscriptionOption: string;
  parentSubscriptionId: number;
  childSubscriptionId: number;
  summarySelected: any;
  isInstallmentPrice: string;
  isAdjustmentPrice: string;
  customizableOptionPrice: number;
  isAddCart: string;
  isCustomizable: boolean;
  clearSelectOption: string;
  statusChangeConfigurable: string;
  bundleOptionValueRequired: [];
  bundleOptionValue: [];
  variantVideo: boolean;
}

const initialState: ValueAddCartState = {
  qty: 1,
  customizableId: 0,
  valueString: [],
  parentSku: '',
  sku: '',
  price: 0,
  productName: '',
  productPrice: 0,
  optionSelected: 0,
  stockStatus: false,
  status: '',
  bundleSelectOption: [],
  bundleRadioOption: [],
  bundleMultiOption: [],
  bundleCheckBoxOption: [],
  isSelectDefault: false,
  isCheckBoxDefault: false,
  isRadioDefault: false,
  isMultiDefault: false,
  bundleTotalCheckBox: [],
  bundleTotalSelect: [],
  bundleTotalRadio: [],
  bundleTotalMulti: [],
  isSubscription: false,
  subscriptionOptionPrice: 0,
  isOneTime: false,
  isSubscribe: false,
  selectedSubscriptionOption: '',
  parentSubscriptionId: 0,
  childSubscriptionId: 0,
  summarySelected: null,
  isInstallmentPrice: '',
  isAdjustmentPrice: '',
  customizableOptionPrice: 0,
  isAddCart: 'addSuccess',
  isCustomizable: false,
  clearSelectOption: '',
  statusChangeConfigurable: '',
  bundleOptionValueRequired: [],
  bundleOptionValue: [],
  variantVideo: false,
};

export const valueAddCartSlice = createSlice({
  name: 'selectQuantity',
  initialState,
  reducers: {
    checkSelectBundleOption: (state, action) => {
      state.bundleSelectOption = action.payload;
    },
    checkSelectBundleRadioOption: (state, action) => {
      state.bundleRadioOption = action.payload;
    },
    checkSelectBundleMultiOption: (state, action) => {
      state.bundleMultiOption = action.payload;
    },
    checkSelectBundleCheckboxOption: (state, action) => {
      state.bundleCheckBoxOption = action.payload;
    },
    checkStatusQuantity: (state, action) => {
      state.status = action.payload;
    },
    checkBundleCheckBoxOptionStatus: (state, action) => {
      state.isCheckBoxDefault = action.payload;
    },
    checkBundleSelectOptionStatus: (state, action) => {
      state.isSelectDefault = action.payload;
    },
    checkBundleRadioOptionStatus: (state, action) => {
      state.isRadioDefault = action.payload;
    },
    checkBundleMultiOptionStatus: (state, action) => {
      state.isMultiDefault = action.payload;
    },
    checkBundleCheckBoxTotalPrice: (state, action) => {
      state.bundleTotalCheckBox = action.payload;
    },
    checkBundleSelectTotalPrice: (state, action) => {
      state.bundleTotalSelect = action.payload;
    },
    checkBundleRadioTotalPrice: (state, action) => {
      state.bundleTotalRadio = action.payload;
    },
    checkBundleMultiTotalPrice: (state, action) => {
      state.bundleTotalMulti = action.payload;
    },
    checkProductSubscription: (state, action) => {
      state.isSubscription = action.payload;
    },
    setPriceChooseOptionSubscription: (state, action) => {
      state.subscriptionOptionPrice = action.payload;
    },
    checkSubscriptionIsOneTime: (state, action) => {
      state.isOneTime = action.payload;
    },
    checkSubscriptionIsSubscribe: (state, action) => {
      state.isSubscribe = action.payload;
    },
    setSelectedSubscriptionOption: (state, action) => {
      state.selectedSubscriptionOption = action.payload;
    },
    changeSelectQuantity: (state, action) => {
      state.qty = action.payload;
    },
    changeSelectProductName: (state, action) => {
      state.productName = action.payload;
    },
    changeSelectProductPrice: (state, action) => {
      state.productPrice = action.payload;
    },
    changeSelectCustomizable: (state, action) => {
      state.customizableId = action.payload;
    },
    changeSelectSubscriptionId: (state, action) => {
      state.parentSubscriptionId = action.payload;
    },
    changeSelectSubscriptionOptionId: (state, action) => {
      state.childSubscriptionId = action.payload;
    },
    changeSelectCustomizableCheckbox: (state, action) => {
      state.valueString = action.payload;
    },
    changeSelectConfigurableParentSku: (state, action) => {
      state.parentSku = action.payload;
    },
    changeSelectConfigurableSku: (state, action) => {
      state.sku = action.payload;
    },
    changeSelectConfigurablePrice: (state, action) => {
      state.price = action.payload;
    },
    changeConfigurableOptionSelected: (state, action) => {
      state.optionSelected = action.payload;
    },
    changeProductStockStatus: (state, action) => {
      state.stockStatus = action.payload;
    },
    changeSelectedConfigurableOption: (state, action) => {
      state.summarySelected = action.payload;
    },
    changeStatusIsInstallmentPrice: (state, action) => {
      state.isInstallmentPrice = action.payload;
    },
    changeStatusIsAdjustmentPrice: (state, action) => {
      state.isAdjustmentPrice = action.payload;
    },
    setCustomizableOptionPrice: (state, action) => {
      state.customizableOptionPrice = action.payload;
    },
    setStatusAddCart: (state, action) => {
      state.isAddCart = action.payload;
    },
    checkCustomizableOptionStatus: (state, action) => {
      state.isCustomizable = action.payload;
    },
    checkClearSelectOption: (state, action) => {
      state.clearSelectOption = action.payload;
    },
    checkStatusChangeConfigurable: (state, action) => {
      state.statusChangeConfigurable = action.payload;
    },
    setValueBundleOptionRequired: (state, action) => {
      state.bundleOptionValueRequired = action.payload;
    },
    setValueBundleOption: (state, action) => {
      state.bundleOptionValue = action.payload;
    },
    setStatusVariantShowVideo: (state, action) => {
      state.variantVideo = action.payload;
    },
    clearSpecificValueAddCart: (state, action) => {
      state.productPrice = action.payload;
    },
    clearValueAddCart: () => ({ ...initialState }),
  },
  extraReducers: {},
});

export const {
  checkBundleCheckBoxOptionStatus,
  checkBundleSelectOptionStatus,
  checkBundleRadioOptionStatus,
  checkBundleMultiOptionStatus,
  checkSelectBundleOption,
  checkSelectBundleRadioOption,
  checkSelectBundleMultiOption,
  checkSelectBundleCheckboxOption,
  checkBundleCheckBoxTotalPrice,
  checkBundleSelectTotalPrice,
  checkBundleRadioTotalPrice,
  checkBundleMultiTotalPrice,
  checkProductSubscription,
  changeSelectQuantity,
  changeSelectCustomizable,
  changeSelectCustomizableCheckbox,
  changeSelectConfigurableParentSku,
  changeSelectConfigurableSku,
  changeSelectConfigurablePrice,
  changeSelectProductName,
  changeSelectProductPrice,
  changeConfigurableOptionSelected,
  changeProductStockStatus,
  checkStatusQuantity,
  setPriceChooseOptionSubscription,
  checkSubscriptionIsOneTime,
  checkSubscriptionIsSubscribe,
  setSelectedSubscriptionOption,
  changeSelectSubscriptionId,
  changeSelectSubscriptionOptionId,
  changeSelectedConfigurableOption,
  changeStatusIsInstallmentPrice,
  changeStatusIsAdjustmentPrice,
  setCustomizableOptionPrice,
  clearValueAddCart,
  setStatusAddCart,
  checkCustomizableOptionStatus,
  checkClearSelectOption,
  checkStatusChangeConfigurable,
  setValueBundleOptionRequired,
  setValueBundleOption,
  clearSpecificValueAddCart,
  setStatusVariantShowVideo,
} = valueAddCartSlice.actions;

export const selectStatusVariantShowVideo = (state: RootState) =>
  state.selectValueAddCart.variantVideo;

export const selectStatusAddCart = (state: RootState) =>
  state.selectValueAddCart.isAddCart;

export const selectSubscriptionOptionPrice = (state: RootState) =>
  state.selectValueAddCart.subscriptionOptionPrice;

export const selectSelectedSubscriptionOption = (state: RootState) =>
  state.selectValueAddCart.selectedSubscriptionOption;

export const selectStatusQuantity = (state: RootState) =>
  state.selectValueAddCart.status;

export const selectBundleOption = (state: RootState) =>
  state.selectValueAddCart.bundleSelectOption;

export const selectBundleRadioOption = (state: RootState) =>
  state.selectValueAddCart.bundleRadioOption;

export const selectBundleMultiOption = (state: RootState) =>
  state.selectValueAddCart.bundleMultiOption;

export const selectBundleCheckBoxOption = (state: RootState) =>
  state.selectValueAddCart.bundleCheckBoxOption;

export const selectBundleCheckBoxOptionStatus = (state: RootState) =>
  state.selectValueAddCart.isCheckBoxDefault;

export const selectBundleSelectOptionStatus = (state: RootState) =>
  state.selectValueAddCart.isSelectDefault;

export const selectBundleRadioOptionStatus = (state: RootState) =>
  state.selectValueAddCart.isRadioDefault;

export const selectBundleMultiOptionStatus = (state: RootState) =>
  state.selectValueAddCart.isMultiDefault;

export const selectBundleCheckBoxTotal = (state: RootState) =>
  state.selectValueAddCart.bundleTotalCheckBox;

export const selectBundleSelectTotal = (state: RootState) =>
  state.selectValueAddCart.bundleTotalSelect;

export const selectBundleRadioTotal = (state: RootState) =>
  state.selectValueAddCart.bundleTotalRadio;

export const selectBundleMultiTotal = (state: RootState) =>
  state.selectValueAddCart.bundleTotalMulti;

export const selectProductSubscription = (state: RootState) =>
  state.selectValueAddCart.isSubscription;

export const selectSubscriptionIsOneTime = (state: RootState) =>
  state.selectValueAddCart.isOneTime;

export const selectSubscriptionIsSubscribe = (state: RootState) =>
  state.selectValueAddCart.isSubscribe;

export const selectParentSubscriptionId = (state: RootState) =>
  state.selectValueAddCart.parentSubscriptionId;

export const selectChildSubscriptionId = (state: RootState) =>
  state.selectValueAddCart.childSubscriptionId;

export const selectQuantity = (state: RootState) =>
  state.selectValueAddCart.qty;

export const selectProductName = (state: RootState) =>
  state.selectValueAddCart.productName;

export const selectProductPrice = (state: RootState) =>
  state.selectValueAddCart.productPrice;

export const selectCustomizable = (state: RootState) =>
  state.selectValueAddCart.customizableId;

export const selectCustomizableValue = (state: RootState) =>
  state.selectValueAddCart.valueString;

export const selectConfigurableParentSku = (state: RootState) =>
  state.selectValueAddCart.parentSku;

export const selectConfigurableSku = (state: RootState) =>
  state.selectValueAddCart.sku;

export const selectConfigurablePrice = (state: RootState) =>
  state.selectValueAddCart.price;

export const selectConfigurableOptionSelected = (state: RootState) =>
  state.selectValueAddCart.optionSelected;

export const selectProductStockStatus = (state: RootState) =>
  state.selectValueAddCart.stockStatus;

export const selectSelectedOptionSummary = (state: RootState) =>
  state.selectValueAddCart.summarySelected;

export const selectIsInstallmentPrice = (state: RootState) =>
  state.selectValueAddCart.isInstallmentPrice;

export const selectIsAdjustmentPrice = (state: RootState) =>
  state.selectValueAddCart.isAdjustmentPrice;

export const selectCustomizableOptionPrice = (state: RootState) =>
  state.selectValueAddCart.customizableOptionPrice;

export const selectCustomizableOptionStatus = (state: RootState) =>
  state.selectValueAddCart.isCustomizable;

export const selectStatusClearSelectOption = (state: RootState) =>
  state.selectValueAddCart.clearSelectOption;

export const selectStatusChangeConfigurable = (state: RootState) =>
  state.selectValueAddCart.statusChangeConfigurable;

export const selectValueBundleOptionRequired = (state: RootState) =>
  state.selectValueAddCart.bundleOptionValueRequired;

export const selectValueBundleOption = (state: RootState) =>
  state.selectValueAddCart.bundleOptionValue;

export default valueAddCartSlice.reducer;
