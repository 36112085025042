import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { JapanCenter, PrefConfiguration } from '../models/register';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchPrefConfiguration = createAsyncThunk(
  'dataJapan/fetchPrefConfiguration',
  async (_, thunkAPI) => {
    try {
      const { data } = await request.get('/api/pref-configuration');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchCenterJapan = createAsyncThunk(
  'dataJapan/fetchCenterJapan',
  async (_, thunkAPI) => {
    try {
      const { data } = await request.get('/api/centers?region=jp');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

interface DataJapanState {
  loading: boolean;
  error: null | number;
  data: null | PrefConfiguration;
  japanCenter: null | JapanCenter[];
}

const initialState: DataJapanState = {
  loading: false,
  error: null,
  data: null,
  japanCenter: null,
};

export const dataJapanSlice = createSlice({
  name: 'dataJapan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrefConfiguration.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchPrefConfiguration.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchCenterJapan.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCenterJapan.fulfilled, (state, action) => {
        state.japanCenter = action.payload.data;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectPrefData = (state: RootState) => state.dataJapan.data;
export const selectListJapanCenterData = (state: RootState) =>
  state.dataJapan.japanCenter;
export default dataJapanSlice.reducer;
