import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { RootCategoryState } from '../models/shop';
import { GET_ROOT_CATEGORY_ID } from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const getRootCategory = createAsyncThunk(
  'rootCategory/getRootCategory',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('graphql', {
        params: {
          query: print(GET_ROOT_CATEGORY_ID),
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const rootCategorySlice = createSlice({
  name: 'rootCategory',
  initialState: { detail: null } as RootCategoryState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRootCategory.fulfilled, (state, action) => {
      state.detail = action.payload;
    });
  },
});

export const selectRootCategory = (state: RootState) =>
  state.rootCategory.detail;

export default rootCategorySlice.reducer;
