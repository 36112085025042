import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import {
  BillingAddressInput,
  Cart,
  CategoryInterface,
  Customer,
  CustomerAddress,
  CustomerAddressInput,
  Maybe,
  MutationUpdateCustomerAddressArgs,
  PaymentMethodInput,
  ProductInterface,
  SetShippingAddressesOnCartInput,
} from '../models/shop';
import {
  ADD_PRODUCT_VIRTUAL_SUBSCRIPTION_TO_CART,
  ADD_PRODUCT_VIRTUAL_TO_CART,
  CREATE_CUSTOMER_ADDRESS,
  CREATE_EMPTY_CART,
  DEACTIVE_CUSTOMER_CART,
  DELETE_CUSTOMER_ADDRESS,
  SET_BILLING_ADDRESS_ON_CART,
  SET_PAYMENT_METHOD_AND_PLACE_ORDER,
  SET_SHIPPING_ADDRESSES_ON_CART,
  UPDATE_CUSTOMER_ADDRESS,
} from '../utils/mutations';
import {
  GET_CUSTOMER_CART,
  GET_CUSTOMER_INFO,
  GET_PRODUCT_BY_CATEGORY_ID_RELEVANCE,
  GET_PRODUCTS_BY_URL_KEY,
} from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const getProductGofltecByCategoryId = createAsyncThunk(
  'shopGolftecPlus/getProductGolftecPlusByCategoryId',
  async (
    payload: {
      id: string[];
      pageSize: number;
      currentPage: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_PRODUCT_BY_CATEGORY_ID_RELEVANCE),
        variables: {
          id: payload.id,
          pageSize: payload.pageSize,
          currentPage: payload.currentPage,
          sortEnum: 'ASC',
        },
      });

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCustomerGolftecPlusInfo = createAsyncThunk(
  'shopGolftecPlus/getCustomerGolftecPlusInfo',
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { customer },
        },
      } = await request.post('graphql', {
        query: print(GET_CUSTOMER_INFO),
      });
      return customer;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deActiveCustomerCart = createAsyncThunk(
  'shopGolftecPlus/deActiveCustomerCart',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(DEACTIVE_CUSTOMER_CART),
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createEmptyCart = createAsyncThunk(
  'shopGolftecPlus/createEmptyCart',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(CREATE_EMPTY_CART),
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCustomerGolftecPlusCart = createAsyncThunk(
  'shopGolftecPlus/getCustomerGolftecPlusCart',
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { customerCart },
        },
      } = await request.post('graphql', { query: print(GET_CUSTOMER_CART) });
      return customerCart;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createCustomerAddressGolftecPlus = createAsyncThunk(
  'shopGolftecPlus/createCustomerAddressGolftecPlus',
  async (
    payload: {
      customerAddressInput: CustomerAddressInput;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(CREATE_CUSTOMER_ADDRESS),
        variables: { customerAddressInput: payload.customerAddressInput },
      });
      if (data.errors) {
        return rejectWithValue(data.errors);
      }

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

type VariableProductVirtualSubscription = {
  quantity: number;
  sku: string;
  optionId?: number;
  valueString?: string;
  cartId: string;
};

export const addProductVirtualSubscriptionToCartGolftecPlus = createAsyncThunk(
  'shopGolftecPlus/addProductVirtualSubscriptionToCartGolftecPlus',
  async (
    payload: VariableProductVirtualSubscription,
    { getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(ADD_PRODUCT_VIRTUAL_SUBSCRIPTION_TO_CART),
        variables: {
          cartId: payload.cartId,
          quantity: payload.quantity,
          sku: payload.sku,
          optionId: payload.optionId,
          valueString: payload.valueString,
        },
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateCustomerGolftecPlusAddress = createAsyncThunk(
  'shopGolftecPlus/updateCustomerGolftecPlusAddress',
  async (
    payload: {
      update: MutationUpdateCustomerAddressArgs;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(UPDATE_CUSTOMER_ADDRESS),
        variables: { id: payload.update.id, input: payload.update.input },
      });

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteCustomerGolftecPlusAddress = createAsyncThunk(
  'shopGolftecPlus/deleteCustomerGolftecPlusAddress',
  async (payload: { id: number }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(DELETE_CUSTOMER_ADDRESS),
        variables: { id: payload.id },
      });

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setBillingAddressGolftecPlusOnCart = createAsyncThunk(
  'shopGolftecPlus/setBillingAddressGolftecPlusOnCart',
  async (
    payload: {
      billingAddressInput: BillingAddressInput;
      cartId: string;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(SET_BILLING_ADDRESS_ON_CART),
        variables: {
          billingAddress: payload.billingAddressInput,
          cartId: payload.cartId,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setShippingAddressesGolftecPlusOnCart = createAsyncThunk(
  'shopGolftecPlus/setShippingAddressesGolftecPlusOnCart',
  async (
    payload: {
      input: SetShippingAddressesOnCartInput;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(SET_SHIPPING_ADDRESSES_ON_CART),
        variables: {
          input: {
            ...payload.input,
            cart_id: payload.input.cart_id,
          },
        },
      });

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setPaymentMethodAndPlaceOrderGolftecPlus = createAsyncThunk(
  'shopGolftecPlus/setPaymentMethodAndPlaceOrderGolftecPlus',
  async (
    payload: {
      cart_id: string;
      paymentInfo: PaymentMethodInput;
      card_name?: string;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(SET_PAYMENT_METHOD_AND_PLACE_ORDER),
        variables: {
          input: {
            cart_id: payload.cart_id,
            payment_method: payload.paymentInfo,
          },
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addProductVirtualGolftecPlusToCart = createAsyncThunk(
  'shopGolftecPlus/addProductVirtualGolftecPlusToCart',
  async (
    payload: {
      quantity: number;
      sku: string;
      cartId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(ADD_PRODUCT_VIRTUAL_TO_CART),
        variables: {
          cartId: payload.cartId,
          quantity: payload.quantity,
          sku: payload.sku,
        },
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getProductByUrlKeyGolftecPlus = createAsyncThunk(
  'shopGolftecPlus/getProductByUrlKeyGolftecPlus',
  async (url_key: string[], { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_PRODUCTS_BY_URL_KEY),
        variables: {
          url_key,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ShopGolftecPlusState {
  dataByCategory: null | CategoryInterface[];
  customer: null | Customer;
  loadingAddress: boolean;
  cart: null | Cart;
  chooseCustomerAddressShippingId: null | number;
  chooseCustomerAddressBillingId: null | number;
  defaultAddress: string;
  productByUrlKey: null | ProductInterface[];
  loadingGetProductByUrlKey: boolean;
  statusOpenAddAddress: boolean;
  statusOpenEditAddress: boolean;
  statusOpenDeleteAddress: boolean;
  addressEdit: Maybe<CustomerAddress> | null;
  addressDelete: Maybe<CustomerAddress> | null;
  statusReadyLottie: boolean;
}

const initialState: ShopGolftecPlusState = {
  dataByCategory: null,
  customer: null,
  loadingAddress: false,
  cart: null,
  chooseCustomerAddressShippingId: null,
  chooseCustomerAddressBillingId: null,
  defaultAddress: '',
  productByUrlKey: null,
  loadingGetProductByUrlKey: false,
  statusOpenAddAddress: false,
  statusOpenEditAddress: false,
  statusOpenDeleteAddress: false,
  addressEdit: null,
  addressDelete: null,
  statusReadyLottie: false,
};

const shopGolftecPlusSlice = createSlice({
  name: 'shopGolftecPlus',
  initialState,
  reducers: {
    changeChooseCustomerAddressShippingGolftecPlusId: (state, action) => {
      state.chooseCustomerAddressShippingId = action.payload;
    },
    changeChooseCustomerAddressBillingGolftecPlusId: (state, action) => {
      state.chooseCustomerAddressBillingId = action.payload;
    },
    changeDefaultAddressGolftecPlus: (state, action) => {
      state.defaultAddress = action.payload;
    },
    changeStatusOpenAddAddress: (state, action) => {
      state.statusOpenAddAddress = action.payload;
    },
    changeStatusOpenEditAddress: (state, action) => {
      state.statusOpenEditAddress = action.payload;
    },
    changeStatusOpenDeleteAddress: (state, action) => {
      state.statusOpenDeleteAddress = action.payload;
    },
    changeAddressEdit: (state, action) => {
      state.addressEdit = action.payload;
    },
    changeAddressDelete: (state, action) => {
      state.addressDelete = action.payload;
    },
    changeStatusReadyLottie: (state, action) => {
      state.statusReadyLottie = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProductGofltecByCategoryId.fulfilled,
      (state, action) => {
        if (action.payload.data?.categories?.items) {
          state.dataByCategory = action.payload.data?.categories.items;
        }
      }
    );
    builder.addCase(getCustomerGolftecPlusInfo.pending, (state, action) => {
      state.loadingAddress = true;
    });
    builder.addCase(getCustomerGolftecPlusInfo.fulfilled, (state, action) => {
      state.customer = action.payload;
      state.loadingAddress = false;
    });
    builder
      .addCase(getCustomerGolftecPlusCart.pending, (state) => {
        // state.loadingAddress = true;
      })
      .addCase(getCustomerGolftecPlusCart.fulfilled, (state, action) => {
        state.cart = action.payload;
        // state.loadingAddress = false;
      });
    builder
      .addCase(createCustomerAddressGolftecPlus.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(createCustomerAddressGolftecPlus.fulfilled, (state, action) => {
        state.loadingAddress = false;
      });
    builder
      .addCase(updateCustomerGolftecPlusAddress.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(updateCustomerGolftecPlusAddress.fulfilled, (state, action) => {
        state.loadingAddress = false;
      });
    builder
      .addCase(deleteCustomerGolftecPlusAddress.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(deleteCustomerGolftecPlusAddress.fulfilled, (state, action) => {
        state.loadingAddress = false;
      });
    builder
      .addCase(getProductByUrlKeyGolftecPlus.pending, (state, action) => {
        state.loadingGetProductByUrlKey = true;
      })
      .addCase(getProductByUrlKeyGolftecPlus.fulfilled, (state, action) => {
        if (action.payload.data?.products?.items?.length > 0) {
          state.productByUrlKey = action.payload.data?.products?.items;
        }
        state.loadingGetProductByUrlKey = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loadingGetProductByUrlKey = false;
        state.loadingAddress = false;
      }
    );
  },
});

export const {
  changeDefaultAddressGolftecPlus,
  changeChooseCustomerAddressShippingGolftecPlusId,
  changeChooseCustomerAddressBillingGolftecPlusId,
  changeAddressEdit,
  changeAddressDelete,
  changeStatusOpenAddAddress,
  changeStatusOpenEditAddress,
  changeStatusOpenDeleteAddress,
  changeStatusReadyLottie,
} = shopGolftecPlusSlice.actions;

export const selectDefaultAddressGolftecPlus = (state: RootState) =>
  state.shop.defaultAddress;
export const selectDataProductByCategoryGolftecPlus = (state: RootState) =>
  state.shopGolftecPlus.dataByCategory;
export const selectCustomerGolftecPlus = (state: RootState) =>
  state.shopGolftecPlus.customer;
export const selectLoadingAddressGolftecPlus = (state: RootState) =>
  state.shopGolftecPlus.loadingAddress;
export const selectCustomerCartGolftecPlus = (state: RootState) =>
  state.shopGolftecPlus.cart;
export const selectCustomerAddressShippingGolftecPlusId = (state: RootState) =>
  state.shopGolftecPlus.chooseCustomerAddressShippingId;
export const selectCustomerAddressBillingGolftecPlusId = (state: RootState) =>
  state.shopGolftecPlus.chooseCustomerAddressBillingId;
export const selectCustomerAddressesGolftecPlus = (state: RootState) =>
  state.shopGolftecPlus.customer?.addresses;
export const selectProductByUrlKeyGolftecPlus = (state: RootState) =>
  state.shopGolftecPlus.productByUrlKey;
export const selectStatusOpenAddAddress = (state: RootState) =>
  state.shopGolftecPlus.statusOpenAddAddress;
export const selectStatusOpenEditAddress = (state: RootState) =>
  state.shopGolftecPlus.statusOpenEditAddress;
export const selectStatusOpenDeleteAddress = (state: RootState) =>
  state.shopGolftecPlus.statusOpenDeleteAddress;
export const selectAddressEdit = (state: RootState) =>
  state.shopGolftecPlus.addressEdit;
export const selectAddressDelete = (state: RootState) =>
  state.shopGolftecPlus.addressDelete;
export const selectLoadingGetProductByUrlKeyGolftecPlus = (state: RootState) =>
  state.shopGolftecPlus.loadingGetProductByUrlKey;
export const selectStatusReadyLottie = (state: RootState) =>
  state.shopGolftecPlus.statusReadyLottie;

export default shopGolftecPlusSlice.reducer;
