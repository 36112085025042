import { ChakraProvider, localStorageManager } from '@chakra-ui/react';
import '@fontsource/roboto';
import '@fontsource/roboto-condensed';
import loadable from '@loadable/component';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './css/general.css';
import './css/golftec-font.css';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './utils/store';
import extendedTheme from './utils/theme';

const LanguageProvider = loadable(
  () => import('./components/LanguageProvider')
);
const ScrollToTop = loadable(() => import('./components/ScrollToTop'));
const App = loadable(() => import('./App'));
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js') // Đường dẫn tới service worker file bạn đã tạo ở bước trước
      .then((registration) => {
        console.log('Service Worker registered:', registration);
      })
      .catch((error) => {
        console.error('Error registering Service Worker:', error);
      });
  });
}
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ChakraProvider
          theme={extendedTheme}
          colorModeManager={localStorageManager}
        >
          <LanguageProvider>
            <BrowserRouter>
              <ScrollToTop />
              <App />
            </BrowserRouter>
          </LanguageProvider>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
