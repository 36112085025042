import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ReferralState } from '../models/referral';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface FetchContentReferral {
  referralPlayerId: string;
}

export const fetchContentReferral = createAsyncThunk(
  'referral/fetchContentReferral',
  async (payload: FetchContentReferral, thunkAPI) => {
    try {
      const { data } = await request.get(
        `/api/player?pid=${payload.referralPlayerId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: ReferralState = {
  loading: false,
  error: null,
  data: null,
};

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentReferral.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchContentReferral.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectReferralData = (state: RootState) => state.referral.data;
export const loadingReferral = (state: RootState) => state.referral.loading;
export default referralSlice.reducer;
