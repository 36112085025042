import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { fetchBayData } from './playerScore';
import { signOut } from './user';

interface AddPlayerScoreData {
  typeScore: string;
  value: number;
}

export const fetchAddPlayerScore = createAsyncThunk(
  'addScore/addPlayerScore',
  async (payload: AddPlayerScoreData, { dispatch, rejectWithValue }) => {
    try {
      const response = await request.post('/api/player-score', {
        typeScore: payload.typeScore,
        value: payload.value,
      });
      let data = await response;
      if (response.status === 200) {
        dispatch(fetchBayData());
        return data;
      } else {
        return rejectWithValue(data);
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface AddPlayerScoreState {
  loading: boolean | '';
  error: any;
  data: any | AddPlayerScoreData;
}

const initialState: AddPlayerScoreState = {
  loading: false,
  error: null,
  data: null,
};

export const addPlayerScoreSlice = createSlice({
  name: 'addScore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddPlayerScore.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAddPlayerScore.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAddPlayerScore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const loadingAddPlayerScore = (state: RootState) =>
  state.addScore.loading;
export default addPlayerScoreSlice.reducer;
