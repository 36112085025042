import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { TechPutState } from '../models/putterFitting';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchTechPutData = createAsyncThunk(
  'techPut/techPutData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/tecput/session');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchTechPutDetail = createAsyncThunk(
  'detail/techPutDetail',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/tecput/session/detail?id=${id}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: TechPutState = {
  loading: false,
  error: null,
  dataTechPut: null,
  selectedItem: null,
  selectedQuickView: null,
  loadingDetail: false,
  detailFutting: null,
};

export const techPutSlice = createSlice({
  name: 'techPut',
  initialState,
  reducers: {
    changeSelectPutterFittingQuickView: (state, action) => {
      state.selectedQuickView = action.payload;
    },
    changeSelectPutterFitting: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTechPutDetail.pending, (state, action) => {
        state.loadingDetail = true;
      })
      .addCase(fetchTechPutDetail.fulfilled, (state, action) => {
        state.loadingDetail = false;
        state.detailFutting = action.payload.data;
      })
      .addCase(fetchTechPutData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchTechPutData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.dataTechPut = data;
        state.loading = false;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
          state.loading = false;
          state.loadingDetail = false;
        }
      );
  },
});

export const { changeSelectPutterFittingQuickView, changeSelectPutterFitting } =
  techPutSlice.actions;
export const selectPutterFitting = (state: RootState) =>
  state.techPut.selectedItem;
export const selectPutterFittingQuickView = (state: RootState) =>
  state.techPut.selectedQuickView;
export const selectTechPutData = (state: RootState) =>
  state.techPut.dataTechPut;
export const loadingTechPutData = (state: RootState) => state.techPut.loading;
export const loadingTechPutDetail = (state: RootState) =>
  state.techPut.loadingDetail;
export const selectDetailPutterFitting = (state: RootState) =>
  state.techPut.detailFutting;
export default techPutSlice.reducer;
