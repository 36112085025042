import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { HomeState } from '../models/home';
import { dataCol } from '../pages/Referral/data';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchContentDynamic = createAsyncThunk(
  'dynamic/contentDynamic',
  async (_, thunkAPI) => {
    try {
      return dataCol;
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: HomeState = {
  loading: false,
  error: null,
  data: null,
};

export const contentDynamicSlice = createSlice({
  name: 'dynamic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentDynamic.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchContentDynamic.fulfilled, (state, action) => {
        // @ts-ignore
        state.data = action.payload.data;
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectDataDynamic = (state: RootState) => state.home.data;
export const loadingDataDynamic = (state: RootState) => state.home.loading;
export default contentDynamicSlice.reducer;
