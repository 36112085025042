import { MessageFormatElement } from '@formatjs/icu-messageformat-parser';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const fetchTranslations = createAsyncThunk(
  'language/fetchTranslation',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('api/translation?channel=web3');
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

interface TranslationType {
  [key: string]:
    | Record<string, string>
    | Record<string, MessageFormatElement[]>;
}

interface LanguageState {
  locale: string;
  translations: TranslationType;
}

const initialState: LanguageState = {
  locale: navigator.language.substr(0, 2),
  translations: {
    en: {},
    fr: {},
    ja: {},
    zh: {}
  }
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setBaseLocale: (state, action) => {
      state.locale = action.payload;
    }
    // setTranslations: (state, action) => {
    //   state.translations = action.payload
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTranslations.fulfilled, (state, action) => {
      state.translations = action.payload.data.translations;
    });
  }
});

export const { setLocale, setBaseLocale } = languageSlice.actions;

export const selectBaseLocale = (state: RootState) => state.language.locale;
export const selectLocale = (state: RootState) => state.language.locale.split('-')[0];

export default languageSlice.reducer;
