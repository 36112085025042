import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const requestWaitingSlot = createAsyncThunk(
  'waitingSlotModal/requestWaitingSlot',
  async (waitingSlotId: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/v2/appointments/detail?timeZone=${
          Intl.DateTimeFormat().resolvedOptions().timeZone
        }&id=${waitingSlotId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestWaitingSlotConfirmation = createAsyncThunk(
  'waitingSlotModal/requestWaitingSlotConfirmation',
  async (waitingSlotId: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/schedule/confirmWaitingList/${waitingSlotId}`
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface WaitingSlotData {
  appointmentState: string;
  centerName: string;
  centerNameKanji: string;
  coachName: string;
  coachFirstNameNative: string;
  coachLastNameNative: string;
  lessonType: string;
  lessonTypeJp: string;
  startTime: string;
  endTime: string;
  street1: string;
  street2: string;
  date: string;
  dateTime: string;
  isAddAdditionalFee: string;
}

interface WaitingSlotState {
  loading: boolean;
  errorCode: number;
  data: null | WaitingSlotData;
}

const initialState: WaitingSlotState = {
  loading: false,
  errorCode: 0,
  data: null,
};

export const waitingSlotSlice = createSlice({
  name: 'waitingSlotModal',
  initialState,
  reducers: {
    resetWaitingSlot: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestWaitingSlot.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(requestWaitingSlot.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        if (action.payload.data.appointmentState === 'booked') {
          state.errorCode = 50006;
        } else {
          state.errorCode = action.payload.code;
        }
      })
      .addCase(requestWaitingSlotConfirmation.fulfilled, (state, action) => {
        state.errorCode = action.payload.code;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.loading = false;
          if (action.payload)
            state.errorCode = (action.payload as { code: number }).code;
        }
      );
  },
});

export const { resetWaitingSlot } = waitingSlotSlice.actions;

export const selectErrorCodeWaitingSlot = (state: RootState) =>
  state.waitingSlotModal.errorCode;

export const selectDataWaitingSlot = (state: RootState) =>
  state.waitingSlotModal.data;

export const selectLoadingWaitingSlot = (state: RootState) =>
  state.waitingSlotModal.loading;

export default waitingSlotSlice.reducer;
