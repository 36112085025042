import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { MySwingsState } from '../models/mySwings';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';


export const fetchMySwingsData = createAsyncThunk(
  'mySwings/fetchMySwingsData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/v2/url-download/my-swings');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchMySwingDetail = createAsyncThunk(
  'mySwings/fetchMySwingDetail',
  async (id:string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/v2/url-download/my-swing/${id}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const mySwingsSendToCoach = createAsyncThunk(
  'mySwings/mySwingsSendToCoach',
  async (payload:{id:string;comment:string}, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/send-coach/my-swing?id=${payload.id}`,{ 
        comment: payload.comment,
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);



const initialState: MySwingsState = {
  loadingMySwings: false,
  error:null,
  loadingMySwingById: false,
  selectedMySwings:null, 
  dataMySwings: null
};

export const mySwingsSlice = createSlice({
  name: 'mySwings',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchMySwingsData.pending, (state, action) => {
      state.loadingMySwings = true;
    })
    .addCase(fetchMySwingsData.fulfilled, (state, action) => {
      state.loadingMySwings = false;
      state.dataMySwings = action.payload.data;
    })
    .addCase(fetchMySwingDetail.pending, (state, action) => {
      state.loadingMySwingById = true;
    })
    .addCase(fetchMySwingDetail.fulfilled, (state, action) => {
      state.loadingMySwingById = false;
      state.selectedMySwings = action.payload.data;
    })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
          state.loadingMySwings = false;
        }
      );
  },
});
export const selectLoadingMySwingById = (state: RootState) => state.mySwing.loadingMySwingById;
export const selectMySwingSelected = (state:RootState) => state.mySwing.selectedMySwings;
export const selectLoadingMySwings = (state: RootState) => state.mySwing.loadingMySwings;
export const selectMySwingsData = (state: RootState) => state.mySwing.dataMySwings;
export default mySwingsSlice.reducer;
