import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchBayData = createAsyncThunk(
  'bay/bayStats',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('api/player-score/dashboard');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export interface AvgSkill {
  type: string;
  value: number;
}

export interface MostRecentSC {
  id: number | null;
  type: string;
  score: number;
  avg: number;
  time?: string;
}
export interface GamePlanScore {
  currentScore: number;
  goalScore: number;
  deviation: number;
  isOverDays: boolean;
  history: GamePlanHistory[];
}

export interface GamePlanHistory {
  id: number;
  playerId: number;
  currentScore: number;
  goalScore: number;
  gameTrackingId: number | null;
  createdDate: Date;
}

export interface ChallengeSkillGameTracking {
  currentScore: number;
  currentType: string;
  currentId: number;
  goalScoreMin: number;
  goalScoreMax: number;
  avgSkill: AvgSkill[];
  mostRecentSC: MostRecentSC[];
}

export interface InBayStats {
  totalSwing: number;
  swingDriver: SwingDriver;
}

export interface SwingDriver {
  value: number;
  time?: string;
}

interface BayData {
  challengeSkill: ChallengeSkillGameTracking;
  gamePlanScore: GamePlanScore;
  inBayStats: InBayStats;
}

interface BayState {
  loading: boolean;
  error: null | number;
  data: null | BayData;
}

const initialState: BayState = {
  loading: false,
  error: null,
  data: null,
};

export const baySlice = createSlice({
  name: 'bay',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBayData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchBayData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.data = { ...data };
        state.loading = false;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
        }
      );
  },
});

export const loadingBayData = (state: RootState) => state.bay.loading;

export const selectBayData = (state: RootState) => state.bay.data as BayData;

export default baySlice.reducer;
