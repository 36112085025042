import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface ReferralFriend {
  centerId: string;
  coverage: boolean;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  postalCode: string;
  recieveEmailFlg: boolean;
  referrer: string;
  source: string;
}

export const fetchAddReferralFriend = createAsyncThunk(
  'referralFriend/fetchAddReferralFriend',
  async (payload: ReferralFriend, thunkAPI) => {
    try {
      const response = await request.post('/api/contact', {
        centerId: payload.centerId,
        coverage: payload.coverage,
        email: payload.email,
        firstname: payload.firstname,
        lastname: payload.lastname,
        phone: payload.phone,
        postalCode: payload.postalCode,
        recieveEmailFlg: payload.recieveEmailFlg,
        referrer: payload.referrer,
        source: payload.source,
      });
      let data = await response;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

interface ReferralFriendJapan {
  centerId: string;
  coverage: boolean;
  email: string;
  firstname: string;
  lastname: string;
  altFirstName: string;
  altLastName: string;
  birthDate: number;
  birthMonth: number;
  birthYear: number;
  pref: string;
  phone: string;
  zipCode: string;
  recieveEmailFlg: boolean;
  referrer: string;
  source: string;
}

export const fetchAddReferralFriendJapan = createAsyncThunk(
  'referralFriend/fetchAddReferralFriendJapan',
  async (payload: ReferralFriendJapan, thunkAPI) => {
    try {
      const response = await request.post('/api/contact', {
        altFirstName: payload.altFirstName,
        altLastName: payload.altLastName,
        birthDate: payload.birthDate,
        birthMonth: payload.birthMonth,
        birthYear: payload.birthYear,
        centerId: payload.centerId,
        coverage: payload.coverage,
        email: payload.email,
        firstname: payload.firstname,
        lastname: payload.lastname,
        phone: payload.phone,
        pref: payload.pref,
        recieveEmailFlg: payload.recieveEmailFlg,
        referrer: payload.referrer,
        source: payload.source,
        zipCode: payload.zipCode,
      });
      let data = await response;
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

interface ReferralFriendState {
  loading: boolean;
  error: any;
  data: any;
  locationId: string;
  japanLocationId: string;
}

const initialState: ReferralFriendState = {
  loading: false,
  error: null,
  data: null,
  locationId: '',
  japanLocationId: '',
};

export const referralFriendSlice = createSlice({
  name: 'referralFriend',
  initialState,
  reducers: {
    setReferralLocationId: (state, action) => {
      state.locationId = action.payload;
    },
    setReferralJapanLocationId: (state, action) => {
      state.japanLocationId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddReferralFriend.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAddReferralFriend.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAddReferralFriend.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchAddReferralFriendJapan.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAddReferralFriendJapan.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAddReferralFriendJapan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { setReferralLocationId, setReferralJapanLocationId } =
  referralFriendSlice.actions;

export const selectLocationIdReferral = (state: RootState) =>
  state.referralFriend.locationId;
export const selectJapanLocationIdReferral = (state: RootState) =>
  state.referralFriend.japanLocationId;
export const selectSubmitReferralError = (state: RootState) =>
  state.referralFriend.error;
export const loadingAddReferralFriend = (state: RootState) =>
  state.referralFriend.loading;
export default referralFriendSlice.reducer;
