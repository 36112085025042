import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { Lesson } from '../models/lesson';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export interface RestoreLessonData {
  lessonId: string;
}

export const fetchRestoreLesson = createAsyncThunk(
  'restore/restoreLesson',
  async (payload: RestoreLessonData, thunkAPI) => {
    try {
      const dataObj = {
        lessonId: payload.lessonId,
        channel: 2,
      };

      const response = await request.post(
        '/api/player/lessons/restore-lesson',
        dataObj
      );
      let data = await response;
      if (response.status === 200) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      let error = e as AxiosError;
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

interface RestoreLessonState {
  loading: boolean;
  error: any;
  data: null | Lesson;
  openModal: boolean;
}

const initialState: RestoreLessonState = {
  loading: false,
  error: null,
  data: null,
  openModal: false,
};

export const restoreLessonSlice = createSlice({
  name: 'restore',
  initialState,
  reducers: {
    changeOpenModalRestoreLesson: (state, action) => {
      state.openModal = action.payload;
    },
    changeErrorRestoreLesson: (state, action) => {
      state.error = action.payload;
    },
    changeLoadingRestoreLesson: (state, action) => {
      state.loading = action.payload;
    },
    resetDataRestore: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRestoreLesson.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchRestoreLesson.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchRestoreLesson.rejected, (state, action) => {
        state.error = action.payload;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  changeOpenModalRestoreLesson,
  changeErrorRestoreLesson,
  changeLoadingRestoreLesson,
  resetDataRestore,
} = restoreLessonSlice.actions;
export const selectOpenRestoreLesson = (state: RootState) =>
  state.restore.openModal;

export const selectDataRestoreLesson = (state: RootState) => state.restore.data;
export const selectErrorRestoreLesson = (state: RootState) =>
  state.restore.error;
export const loadingRestoreLesson = (state: RootState) => state.restore.loading;
export default restoreLessonSlice.reducer;
