import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface ChangeEmailConfirmCode {
  code: string;
}

export const fetchConfirmCode = createAsyncThunk(
  'confirmCode/fetchConfirmCode',
  async (payload: ChangeEmailConfirmCode, { getState, rejectWithValue }) => {
    try {
      const response = await request.put(`/api/change-email/verify`, {
        code: payload.code,
      });
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ConfirmCodeState {
  loading: boolean;
  error: any;
  isVerify: string;
}

const initialState: ConfirmCodeState = {
  loading: false,
  error: null,
  isVerify: '',
};

export const confirmCodeSlice = createSlice({
  name: 'confirmCode',
  initialState,
  reducers: {
    changeChangeConfirmCodeError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfirmCode.pending, (state, action) => {
        state.loading = true;
        state.isVerify = 'wait';
      })
      .addCase(fetchConfirmCode.fulfilled, (state, action) => {
        state.loading = false;
        state.isVerify = 'confirm';
      })
      .addCase(fetchConfirmCode.rejected, (state, action) => {
        state.error = action.payload;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { changeChangeConfirmCodeError } = confirmCodeSlice.actions;

export const checkChangeConfirmCode = (state: RootState) =>
  state.confirmCode.isVerify;
export const selectErrorConfirmCode = (state: RootState) =>
  state.confirmCode.error;
export default confirmCodeSlice.reducer;
