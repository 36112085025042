import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import {
  BillingAddressInput,
  Cart,
  CategoryInterface,
  Customer,
  CustomerAddressInput,
  MutationUpdateCustomerAddressArgs,
  PaymentMethodInput,
  SetShippingAddressesOnCartInput,
} from '../models/shop';
import { CountryData } from '../models/shopCustom';
import {
  ADD_PRODUCT_VIRTUAL_TO_CART,
  CARD_LOOKUP_MONERIS,
  CREATE_CUSTOMER_ADDRESS,
  CREATE_EMPTY_CART,
  DEACTIVE_CUSTOMER_CART,
  DELETE_CUSTOMER_ADDRESS,
  PROCESS_MPI_3D_AUTHENTICATION,
  SET_BILLING_ADDRESS_ON_CART,
  SET_PAYMENT_METHOD_AND_PLACE_ORDER,
  SET_SHIPPING_ADDRESSES_ON_CART,
  UPDATE_CUSTOMER_ADDRESS,
} from '../utils/mutations';
import {
  GET_COUNTRIES,
  GET_CUSTOMER_CART,
  GET_CUSTOMER_INFO,
  GET_PRODUCT_BY_CATEGORY_ID_RELEVANCE,
} from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const getProductSkytrakByCategoryId = createAsyncThunk(
  'shopSKytrak/getProductSkytrakByCategoryId',
  async (
    payload: {
      id: string[];
      pageSize: number;
      currentPage: number;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(GET_PRODUCT_BY_CATEGORY_ID_RELEVANCE),
          variables: {
            id: payload.id,
            pageSize: payload.pageSize,
            currentPage: payload.currentPage,
            sortEnum: 'ASC',
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCustomerSkytrakInfo = createAsyncThunk(
  'shopSKytrak/getCustomerSkytrakInfo',
  async (payload: { isSkytrakGolftec: boolean }, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { customer },
        },
      } = await request.post(
        'graphql',
        {
          query: print(GET_CUSTOMER_INFO),
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return customer;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deActiveCustomerCart = createAsyncThunk(
  'shopSKytrak/deActiveCustomerCart',
  async (
    payload: { isSkytrakGolftec: boolean } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(DEACTIVE_CUSTOMER_CART),
        },
        {
          params: {
            isSkytrakGolftec: payload?.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createEmptyCart = createAsyncThunk(
  'shopSKytrak/createEmptyCart',
  async (
    payload: { isSkytrakGolftec: boolean } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(CREATE_EMPTY_CART),
        },
        {
          params: {
            isSkytrakGolftec: payload?.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCustomerSkytrakCart = createAsyncThunk(
  'shopSKytrak/getCustomerSkytrakCart',
  async (
    payload: { isSkytrakGolftec: boolean } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: { customerCart },
        },
      } = await request.post(
        'graphql',
        { query: print(GET_CUSTOMER_CART) },
        {
          params: {
            isSkytrakGolftec: payload?.isSkytrakGolftec,
          },
        }
      );
      return customerCart;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cardLookUpMoneris = createAsyncThunk(
  'shopSkytrak/cardLookUpMoneris',
  async (
    payload: {
      cart_id: string;
      cc_number: string;
      store_id: number;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(CARD_LOOKUP_MONERIS),
          variables: {
            cartId: payload.cart_id,
            ccNumber: payload.cc_number,
            storeId: payload.store_id,
          },
        },
        {
          params: { isSkytrakGolftec: payload.isSkytrakGolftec },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const processMpi3DAuthentication = createAsyncThunk(
  'shopSkytrak/processMpi3DAuthentication',
  async (
    payload: {
      incrementId: string;
      cardName: string;
      ccExpMonth: string;
      ccExpYear: string;
      ccNumber: string;
      userAgent: string;
      uRL: string;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(PROCESS_MPI_3D_AUTHENTICATION),
          variables: {
            incrementId: payload.incrementId,
            cardName: payload.cardName,
            ccExpMonth: payload.ccExpMonth,
            ccExpYear: payload.ccExpYear,
            ccNumber: payload.ccNumber,
            userAgent: payload.userAgent,
            uRL: payload.uRL,
          },
        },
        {
          params: { isSkytrakGolftec: payload.isSkytrakGolftec },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createCustomerAddressSkytrak = createAsyncThunk(
  'shopSkytrak/createCustomerAddressSkytrak',
  async (
    payload: {
      customerAddressInput: CustomerAddressInput;
      isSkytrakGolftec: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(CREATE_CUSTOMER_ADDRESS),
          variables: { customerAddressInput: payload.customerAddressInput },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      if (data.errors) {
        return rejectWithValue(data.errors);
      }

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateCustomerSkytrakAddress = createAsyncThunk(
  'shopSkytrak/updateCustomerSkytrakAddress',
  async (
    payload: {
      update: MutationUpdateCustomerAddressArgs;
      isSkytrakGolftec: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(UPDATE_CUSTOMER_ADDRESS),
          variables: { id: payload.update.id, input: payload.update.input },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteCustomerSkytrakAddress = createAsyncThunk(
  'shopSkytrak/deleteCustomerSkytrakAddress',
  async (
    payload: { id: number; isSkytrakGolftec: boolean },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(DELETE_CUSTOMER_ADDRESS),
          variables: { id: payload.id },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setBillingAddressSkytrakOnCart = createAsyncThunk(
  'shopSkytrak/setBillingAddressSkytrakOnCart',
  async (
    payload: {
      billingAddressInput: BillingAddressInput;
      cartId: string;
      isSkytrakGolftec: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(SET_BILLING_ADDRESS_ON_CART),
          variables: {
            billingAddress: payload.billingAddressInput,
            cartId: payload.cartId,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setShippingAddressesSkytrakOnCart = createAsyncThunk(
  'shopSkytrak/setShippingAddressesSkytrakOnCart',
  async (
    payload: {
      input: SetShippingAddressesOnCartInput;
      isSkytrakGolftec: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(SET_SHIPPING_ADDRESSES_ON_CART),
          variables: {
            input: {
              ...payload.input,
              cart_id: payload.input.cart_id,
            },
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setPaymentMethodAndPlaceOrderSkytrak = createAsyncThunk(
  'shopSkytrak/setPaymentMethodAndPlaceOrderSkytrak',
  async (
    payload: {
      cart_id: string;
      paymentInfo: PaymentMethodInput;
      card_name?: string;
      isSkytrakGolftec: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(SET_PAYMENT_METHOD_AND_PLACE_ORDER),
          variables: {
            input: {
              cart_id: payload.cart_id,
              payment_method: payload.paymentInfo,
            },
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addProductVirtualSkytrakToCart = createAsyncThunk(
  'shopSkytrak/addProductVirtualSkytrakToCart',
  async (
    payload: {
      quantity: number;
      sku: string;
      cartId: string;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(ADD_PRODUCT_VIRTUAL_TO_CART),
          variables: {
            cartId: payload.cartId,
            quantity: payload.quantity,
            sku: payload.sku,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCountries = createAsyncThunk(
  'shop/getCountries',
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { countries },
        },
      } = await request.post('graphql', {
        query: print(GET_COUNTRIES),
      });
      if (countries) {
        return countries;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ShopSkytrakState {
  dataByCategory: null | CategoryInterface[];
  customer: null | Customer;
  loadingAddress: boolean;
  cart: null | Cart;
  chooseCustomerAddressShippingId: null | number;
  chooseCustomerAddressBillingId: null | number;
  defaultAddress: string;
  countriesData: CountryData[] | null;
}

const initialState: ShopSkytrakState = {
  dataByCategory: null,
  customer: null,
  loadingAddress: false,
  cart: null,
  chooseCustomerAddressShippingId: null,
  chooseCustomerAddressBillingId: null,
  defaultAddress: '',
  countriesData: null,
};

const shopSkytrakSlice = createSlice({
  name: 'shopSkytrak',
  initialState,
  reducers: {
    changeChooseCustomerAddressShippingSkytrakId: (state, action) => {
      state.chooseCustomerAddressShippingId = action.payload;
    },
    changeChooseCustomerAddressBillingSkytrakId: (state, action) => {
      state.chooseCustomerAddressBillingId = action.payload;
    },
    changeDefaultAddressSkytrak: (state, action) => {
      state.defaultAddress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProductSkytrakByCategoryId.fulfilled,
      (state, action) => {
        if (action.payload.data?.categories?.items) {
          state.dataByCategory = action.payload.data?.categories.items;
        }
      }
    );
    builder.addCase(getCustomerSkytrakInfo.pending, (state, action) => {
      state.loadingAddress = true;
    });
    builder.addCase(getCustomerSkytrakInfo.fulfilled, (state, action) => {
      state.customer = action.payload;
      state.loadingAddress = false;
    });
    builder
      .addCase(getCustomerSkytrakCart.pending, (state) => {
        // state.loadingAddress = true;
      })
      .addCase(getCustomerSkytrakCart.fulfilled, (state, action) => {
        state.cart = action.payload;
        // state.loadingAddress = false;
      });
    builder
      .addCase(createCustomerAddressSkytrak.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(createCustomerAddressSkytrak.fulfilled, (state, action) => {
        state.loadingAddress = false;
      });
    builder
      .addCase(updateCustomerSkytrakAddress.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(updateCustomerSkytrakAddress.fulfilled, (state, action) => {
        state.loadingAddress = false;
      });
    builder
      .addCase(deleteCustomerSkytrakAddress.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(deleteCustomerSkytrakAddress.fulfilled, (state, action) => {
        state.loadingAddress = false;
      });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countriesData = action.payload;
    });
    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loadingAddress = false;
      }
    );
  },
});

export const {
  changeDefaultAddressSkytrak,
  changeChooseCustomerAddressShippingSkytrakId,
  changeChooseCustomerAddressBillingSkytrakId,
} = shopSkytrakSlice.actions;

export const selectDefaultAddressSkytrak = (state: RootState) =>
  state.shop.defaultAddress;
export const selectDataProductByCategorySkytrak = (state: RootState) =>
  state.shopSkytrak.dataByCategory;
export const selectCustomerSkytrak = (state: RootState) =>
  state.shopSkytrak.customer;
export const selectLoadingAddressSkytrak = (state: RootState) =>
  state.shopSkytrak.loadingAddress;
export const selectCustomerCartSkytrak = (state: RootState) =>
  state.shopSkytrak.cart;
export const selectCustomerAddressShippingSkytrakId = (state: RootState) =>
  state.shopSkytrak.chooseCustomerAddressShippingId;
export const selectCustomerAddressBillingSkytrakId = (state: RootState) =>
  state.shopSkytrak.chooseCustomerAddressBillingId;
export const selectCustomerAddressesSkytrak = (state: RootState) =>
  state.shopSkytrak.customer?.addresses;
export const selectCountriesData = (state: RootState) =>
  state.shopSkytrak.countriesData;

export default shopSkytrakSlice.reducer;
