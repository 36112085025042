import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface CoorListCourse {
  lat: string;
  long: string;
}

export const fetchListCourse = createAsyncThunk(
  'course/listCourse',
  async (payload: CoorListCourse, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/game-tracking/course?lat=${payload.lat}&long=${payload.long}&isLimit=true`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface fetchSearchCourseParams {
  name: string;
  lat: string;
  long: string;
}

export const fetchSearchCourse = createAsyncThunk(
  'course/listCourse',
  async (payload: fetchSearchCourseParams, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/game-tracking/course?name=${payload.name}&lat=${payload.lat}&long=${payload.long}&isLimit=true`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface CourseData {
  id: string;
  courseName: string;
  latitude: number;
  longitude: number;
  distance: number;
  holes: number;
  address: string;
  addressNL: null;
  city: string;
  cityNL: string;
  state: string;
  country: string;
}

interface RecommendedCourse {
  courseName: string;
  igolfId: string;
}

interface ListCourseState {
  loading: boolean;
  error: null | number;
  data: null | CourseData[];
  recommendedCourse: null | RecommendedCourse;
}

const initialState: ListCourseState = {
  loading: false,
  error: null,
  data: null,
  recommendedCourse: null,
};

export const listCourseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    changeRecommendedCourse: (state, action) => {
      state.recommendedCourse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListCourse.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchListCourse.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { changeRecommendedCourse } = listCourseSlice.actions;

export const selectListCourseData = (state: RootState) => state.listCourse.data;
export const selectRecommendedCourse = (state: RootState) =>
  state.listCourse.recommendedCourse;
export const loadingListCourseData = (state: RootState) =>
  state.listCourse.loading;
export default listCourseSlice.reducer;
