import { gql } from 'graphql.macro';
import {
  BillingAddressSubscriptionFragment,
  CartPageFragment,
  ShippingAddressSubscriptionFragment,
} from './fragments';

export const ADD_PRODUCT_SIMPLE_TO_CART = gql`
  mutation addProductSimpleToCart(
    $cartId: String!
    $quantity: Float!
    $sku: String!
  ) {
    addSimpleProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [{ data: { quantity: $quantity, sku: $sku } }]
      }
    ) {
      cart {
        id
        items {
          product {
            name
            sku
          }
          quantity
        }
        email
        ...CartPageFragment
        billing_address {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
        }
        shipping_addresses {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          items_weight
          selected_shipping_method {
            amount {
              currency
              value
            }
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
          pickup_location_code
        }
        selected_payment_method {
          code
          purchase_order_number
          title
        }
        alert {
          type
          message
          icon
        }
      }
    }
  }
  ${CartPageFragment}
`;

export const ADD_PRODUCT_CUSTOMIZABLE_TO_CART = gql`
  mutation addProductCustomizableToCart(
    $cartId: String!
    $quantity: Float!
    $sku: String!
    $id: Int!
    $value_string: String!
  ) {
    addSimpleProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [
          {
            data: { quantity: $quantity, sku: $sku }
            customizable_options: [{ id: $id, value_string: $value_string }]
          }
        ]
      }
    ) {
      cart {
        alert {
          type
          message
          icon
        }
        id
        email
        ...CartPageFragment
        billing_address {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
        }
        shipping_addresses {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          items_weight
          selected_shipping_method {
            amount {
              currency
              value
            }
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
          pickup_location_code
        }
        selected_payment_method {
          code
          purchase_order_number
          title
        }
      }
    }
  }
  ${CartPageFragment}
`;

export const ADD_PRODUCT_SUBSCRIPTION_TO_CART = gql`
  mutation addProductSubscriptionToCart(
    $cartId: String!
    $cartItem: [SimpleProductCartItemInput]!
  ) {
    addSimpleProductsToCart(
      input: { cart_id: $cartId, cart_items: $cartItem }
    ) {
      cart {
        id
      }
    }
  }
`;

export const ADD_PRODUCT_CONFIGURABLE_SUBSCRIPTION_TO_CART = gql`
  mutation addProductConfigurableSubscriptionToCart(
    $cartId: String!
    $cartItem: [ConfigurableProductCartItemInput]!
  ) {
    addConfigurableProductsToCart(
      input: { cart_id: $cartId, cart_items: $cartItem }
    ) {
      cart {
        id
      }
    }
  }
`;

export const ADD_PRODUCT_BUNDLE_SUBSCRIPTION_TO_CART = gql`
  mutation addProductBundleSubscriptionToCart(
    $cartId: String!
    $cartItem: [BundleProductCartItemInput]!
  ) {
    addBundleProductsToCart(
      input: { cart_id: $cartId, cart_items: $cartItem }
    ) {
      cart {
        id
      }
    }
  }
`;

export const ADD_PRODUCT_CONFIGURABLE_TO_CART = gql`
  mutation addProductConfigurableToCart(
    $cartId: String!
    $quantity: Float!
    $sku: String!
    $parentSku: String!
  ) {
    addConfigurableProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [
          { data: { quantity: $quantity, sku: $sku }, parent_sku: $parentSku }
        ]
      }
    ) {
      cart {
        alert {
          type
          message
          icon
        }
        id
        email
        ...CartPageFragment
        billing_address {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
        }
        shipping_addresses {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          items_weight
          selected_shipping_method {
            amount {
              currency
              value
            }
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
          pickup_location_code
        }
        selected_payment_method {
          code
          purchase_order_number
          title
        }
      }
    }
  }
  ${CartPageFragment}
`;

export const ADD_PRODUCT_BUNDLE_TO_CART = gql`
  mutation addProductBundleToCart(
    $cartId: String!
    $cartItems: [BundleProductCartItemInput]!
  ) {
    addBundleProductsToCart(
      input: { cart_id: $cartId, cart_items: $cartItems }
    ) {
      cart {
        alert {
          type
          message
          icon
        }
        id
        email
        ...CartPageFragment
        billing_address {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
        }
        shipping_addresses {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          items_weight
          selected_shipping_method {
            amount {
              currency
              value
            }
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
          pickup_location_code
        }
        selected_payment_method {
          code
          purchase_order_number
          title
        }
      }
    }
  }
  ${CartPageFragment}
`;

export const UPDATE_QUANTITY_MINI_CART = gql`
  mutation updateQuantityMiniCart(
    $cartId: String!
    $itemId: Int!
    $quantity: Float!
  ) {
    updateCartItems(
      input: {
        cart_id: $cartId
        cart_items: [{ cart_item_id: $itemId, quantity: $quantity }]
      }
    ) {
      cart {
        id
        email
        ...CartPageFragment
        billing_address {
          city
          company
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
        }
        shipping_addresses {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          items_weight
          selected_shipping_method {
            amount {
              currency
              value
            }
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
          pickup_location_code
        }
        selected_payment_method {
          code
          purchase_order_number
          title
        }
      }
    }
  }
  ${CartPageFragment}
`;

export const REMOVE_ITEM_IN_CART = gql`
  mutation removeItem($cartId: String!, $itemId: Int!) {
    removeItemFromCart(input: { cart_id: $cartId, cart_item_id: $itemId }) {
      cart {
        id
        email
        ...CartPageFragment
        billing_address {
          city
          company
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
        }
        shipping_addresses {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          items_weight
          selected_shipping_method {
            amount {
              currency
              value
            }
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
          pickup_location_code
        }
        selected_payment_method {
          code
          purchase_order_number
          title
        }
      }
    }
  }
  ${CartPageFragment}
`;

export const ADD_PRODUCTS_TO_CART = gql`
  mutation ($cartId: String!, $cartItems: CartItemInput!) {
    addProductsToCart(cartId: $cartId, cartItems: [$cartItems]) {
      cart {
        id
      }
    }
  }
`;

export const UPDATE_CART_ITEMS = gql`
  mutation ($input: UpdateCartItemsInput!) {
    updateCartItems(input: $input) {
      cart {
        id
      }
    }
  }
`;

export const REMOVE_ITEM_FROM_CART = gql`
  mutation ($removeItemFromCartInput: RemoveItemFromCartInput) {
    removeItemFromCart(input: $removeItemFromCartInput) {
      cart {
        id
      }
    }
  }
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation ($customerAddressInput: CustomerAddressInput!) {
    createCustomerAddress(input: $customerAddressInput) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation ($id: Int!, $input: CustomerAddressInput!) {
    updateCustomerAddress(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation ($id: Int!) {
    deleteCustomerAddress(id: $id)
  }
`;

export const SET_BILLING_ADDRESS_ON_CART = gql`
  mutation ($billingAddress: BillingAddressInput!, $cartId: String!) {
    setBillingAddressOnCart(
      input: { billing_address: $billingAddress, cart_id: $cartId }
    ) {
      cart {
        id
        billing_address {
          firstname
          lastname
          company
          street
          city
          region {
            code
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
        }
      }
    }
  }
`;

export const SET_SHIPPING_ADDRESSES_ON_CART = gql`
  mutation ($input: SetShippingAddressesOnCartInput) {
    setShippingAddressesOnCart(input: $input) {
      cart {
        id
        shipping_addresses {
          city
          company
          country {
            code
            label
          }
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
        }
      }
    }
  }
`;

export const SET_SHIPPING_METHODS_ON_CART = gql`
  mutation ($input: SetShippingMethodsOnCartInput) {
    setShippingMethodsOnCart(input: $input) {
      cart {
        id
      }
    }
  }
`;

export const APPLY_COUPON = gql`
  mutation ($input: ApplyCouponToCartInput) {
    applyCouponToCart(input: $input) {
      cart {
        id
      }
    }
  }
`;

export const REMOVE_COUPON_FROM_CART = gql`
  mutation ($input: RemoveCouponFromCartInput) {
    removeCouponFromCart(input: $input) {
      cart {
        id
      }
    }
  }
`;

export const SET_PAYMENT_METHOD_ON_CART = gql`
  mutation ($input: SetPaymentMethodOnCartInput) {
    setPaymentMethodOnCart(input: $input) {
      cart {
        id
      }
    }
  }
`;

export const PLACE_ORDER = gql`
  mutation ($input: PlaceOrderInput) {
    placeOrder(input: $input) {
      order {
        order_id
        order_number
      }
    }
  }
`;

export const CHANGE_STATUS_SUBSCRIPTION = gql`
  mutation ($entity_id: Int!, $status: String!) {
    changeSubscriptionStatus(entity_id: $entity_id, status: $status) {
      entity_id
      increment_id
      quote_id
      description
      customer_id
      created_at
      updated_at
      next_run
      last_run
      last_notified
      subtotal
      length
      run_count
      status
      frequency_count
      frequency_unit
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation ($entity_id:Int!, $billing_address: CustomerAddressInput, $shipping_address:CustomerAddressInput) {
    updateSubscription(input : {entity_id:$entity_id, billing_address: $billing_address, shipping_address:$shipping_address}) {
      quote {
          ...BillingAddressSubscriptionFragment
          ...ShippingAddressSubscriptionFragment
        }                 
    }
    ${ShippingAddressSubscriptionFragment}
    ${BillingAddressSubscriptionFragment}
  }
`;

export const REORDER_ITEM = gql`
  mutation ($orderNumber: String!) {
    reorderItems(orderNumber: $orderNumber) {
      cart {
        id
        items {
          uid
          product {
            sku
          }
          quantity
          prices {
            price {
              value
            }
          }
        }
      }
      userInputErrors {
        code
        message
        path
      }
    }
  }
`;

export const ADD_PRODUCT_VIRTUAL_SUBSCRIPTION_TO_CART = gql`
  mutation (
    $cartId: String!
    $quantity: Float!
    $sku: String!
    $optionId: Int
    $valueString: String!
  ) {
    addVirtualProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [
          {
            data: { quantity: $quantity, sku: $sku }
            customizable_options: { id: $optionId, value_string: $valueString }
          }
        ]
      }
    ) {
      cart {
        id
        items {
          product {
            name
            sku
          }
          quantity
          ... on VirtualCartItem {
            customizable_options {
              label
              values {
                value
              }
            }
          }
        }
        prices {
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const ADD_PRODUCT_VIRTUAL_TO_CART = gql`
  mutation ($cartId: String!, $quantity: Float!, $sku: String!) {
    addVirtualProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [{ data: { quantity: $quantity, sku: $sku } }]
      }
    ) {
      cart {
        id
        items {
          product {
            name
            sku
          }
          quantity
        }
        prices {
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`;

export const SET_PAYMENT_METHOD_AND_PLACE_ORDER = gql`
  mutation ($input: SetPaymentMethodAndPlaceOrderInput) {
    setPaymentMethodAndPlaceOrder(input: $input) {
      order {
        order_id
        order_number
      }
    }
  }
`;

export const PLACE_AM_RMA_CUSTOMER_RETURN_REQUEST = gql`
  mutation ($input: PlaceAmRmaReturnRequestInput) {
    placeAmRmaCustomerReturnRequest(input: $input) {
      request_id
      order_id
      order_increment_id
      store_id
      created_at
      modified_at
      status {
        status_id
        state
        label
        title
        color
      }
      customer_id
      customer_name
      url_hash
      manager_id
      custom_fields
      rating
      rating_comment
      note
      request_items {
        request_item_id
        request_id
        order_item_id
        qty
        request_qty
        reason_id
        condition_id
        resolution_id
        product_name
        product_url_key
        item_status
      }
      tracking_numbers {
        tracking_id
        request_id
        tracking_code
        tracking_number
        is_customer
      }
      shipping_label
    }
  }
`;

export const CLOSE_RETURN_REQUEST = gql`
  mutation ($id: Int!) {
    closeAmRmaCustomerReturnRequest(id: $id) {
      error
      message
    }
  }
`;

export const ADD_TRACKING_NUMBER = gql`
  mutation ($input: AddAmRmaTrackingNumberInput) {
    addAmRmaTrackingNumber(input: $input) {
      is_customer
      request_id
      tracking_code
      tracking_id
      tracking_number
    }
  }
`;

export const REMOVE_TRACKING_NUMBER = gql`
  mutation ($input: RemoveAmRmaTrackingNumberInput) {
    removeAmRmaTrackingNumber(input: $input) {
      error
      message
    }
  }
`;

export const SEND_RETURN_MESSAGE = gql`
  mutation ($input: SendAmRmaMessageInput) {
    sendAmRmaMessage(input: $input) {
      created
      files {
        filename
        filepath
      }
      is_manager
      is_system
      message
      message_id
      username
    }
  }
`;

export const DELETE_RETURN_MESSAGE = gql`
  mutation ($input: DeleteAmRmaMessageInput) {
    deleteAmRmaMessage(input: $input) {
      error
      message
    }
  }
`;

export const SEND_RATE_REQUEST = gql`
  mutation ($input: RateAmRmaReturnRequest) {
    rateAmRmaReturnRequest(input: $input) {
      error
      message
    }
  }
`;

export const VALIDATE_MEMBERSHIP_GIFT_CARD = gql`
  mutation ($gift_code: String!) {
    membershipValidateGiftCard(gift_code: $gift_code) {
      is_valid
      message
      gift_code_type
      sku
      sku_id
      sku_name
      sku_visibility
    }
  }
`;

export const REDEEM_MEMBERSHIP_GIFT_CARD = gql`
  mutation ($gift_code: String!, $customer_email: String!) {
    redeemMembershipGiftCard(
      gift_code: $gift_code
      customer_email: $customer_email
    ) {
      increment_id
      message
    }
  }
`;

export const CARD_LOOKUP_MONERIS = gql`
  mutation ($cartId: String!, $ccNumber: String!, $storeId: Int!) {
    cardLookup(
      input: { cart_id: $cartId, cc_number: $ccNumber, store_id: $storeId }
    ) {
      can_use_3d
    }
  }
`;

export const PROCESS_MPI_3D_AUTHENTICATION = gql`
  mutation (
    $incrementId: String!
    $cardName: String!
    $ccExpMonth: String!
    $ccExpYear: String!
    $ccNumber: String!
    $userAgent: String!
    $uRL: String!
  ) {
    processMpi3DAuthentication(
      increment_id: $incrementId
      card_data: {
        card_holder_name: $cardName
        cc_exp_month: $ccExpMonth
        cc_exp_year: $ccExpYear
        cc_number: $ccNumber
      }
      user_agent: $userAgent
      challenge_callback_url: $uRL
    ) {
      error_message
      MessageType
      ResponseCode
      Message
      ReceiptId
      ThreeDSMethodURL
      ThreeDSMethodData
      ChallengeURL
      ChallengeData
      TransStatus
      ThreeDSServerTransId
      DSTransId
      ECI
      Cavv
      TransStatusReason
      CardholderInfo
      receipt
      Mpi2Response
    }
  }
`;

// export const CLEAR_CUSTOMER_CART = gql`
// mutation ($cartId: String!) {
//   clearCustomerCart(
//       cartUid: $cartId
//     ) {
//         status
//     }
// }
// `;

export const LOGOUT_M2 = gql`
  mutation {
    revokeCustomerToken {
      result
    }
  }
`;

export const GET_DYNAMIC_CONTENT = gql`
  mutation ($fieldName: String!, $value: String!) {
    getContentConstructorProShop(field_name: $fieldName, value: $value) {
      desktop {
        category_id
        content
        id
        items {
          image
          slogan
          description
          button {
            label
            href
          }
          image_slider {
            rawValue
          }
        }
        product_url
        subtitle
        title
        type
        text_position
        text_align
        video_url
        video_url_embed
      }
    }
  }
`;

export const DEACTIVE_CUSTOMER_CART = gql`
  mutation {
    deActiveAllCustomerCart
  }
`;

export const CREATE_EMPTY_CART = gql`
  mutation {
    createEmptyCart
  }
`;

export const APPLY_GIFT_CODE_TO_CART = gql`
  mutation msApplyGiftCodeToCart(
    $cart_id: ID!
    $gift_code: String!
    $amount_applied: Float!
  ) {
    msApplyGiftCodeToCart(
      cart_id: $cart_id
      gift_code: $gift_code
      amount_applied: $amount_applied
    ) {
      cart {
        id
        items {
          product {
            name
            sku
          }
          quantity
        }
        email
        ...CartPageFragment
        billing_address {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
        }
        shipping_addresses {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          items_weight
          selected_shipping_method {
            amount {
              currency
              value
            }
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
          pickup_location_code
        }
        selected_payment_method {
          code
          purchase_order_number
          title
        }
        alert {
          type
          message
          icon
        }
      }
    }
  }
  ${CartPageFragment}
`;

export const REMOVE_GIFT_CODE_IN_CART = gql`
  mutation msRemoveGiftCodeFromCart(
    $cart_id: ID!
    $gift_code: String!
    $is_remove_all: Boolean!
  ) {
    msRemoveGiftCodeFromCart(
      cart_id: $cart_id
      gift_code: $gift_code
      is_remove_all: $is_remove_all
    ) {
      cart {
        id
        items {
          product {
            name
            sku
          }
          quantity
        }
        email
        ...CartPageFragment
        billing_address {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
        }
        shipping_addresses {
          city
          company
          country {
            code
            label
          }
          customer_notes
          firstname
          lastname
          postcode
          region {
            code
            label
            region_id
          }
          street
          telephone
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              value
              currency
            }
            price_incl_tax {
              value
              currency
            }
          }
          items_weight
          selected_shipping_method {
            amount {
              currency
              value
            }
            base_amount {
              currency
              value
            }
            carrier_code
            carrier_title
            method_code
            method_title
          }
          pickup_location_code
        }
        selected_payment_method {
          code
          purchase_order_number
          title
        }
        alert {
          type
          message
          icon
        }
      }
    }
  }

  ${CartPageFragment}
`;
