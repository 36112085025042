import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql/index';
import { GET_DYNAMIC_CONTENT } from '../utils/mutations';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchContentHomeShop = createAsyncThunk(
  'dynamicShop/fetchContentHomeShop',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('api/v3/layout/shop');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchContentShopDynamic = createAsyncThunk(
  'shop/fetchContentShopDynamic',
  async (
    payload: {
      fieldName: string;
      value: string;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_DYNAMIC_CONTENT),
        variables: {
          fieldName: payload.fieldName,
          value: payload.value,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface DynamicHomeShop {
  desktop: [];
}

interface DynamicHomeShopState {
  loading: boolean;
  error: null | number;
  data: null | DynamicHomeShop;
}

const initialState: DynamicHomeShopState = {
  loading: false,
  error: null,
  data: null,
};

export const dynamicHomeShopSlice = createSlice({
  name: 'dynamicShop',
  initialState,
  reducers: {
    clearDynamicContentShop: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentShopDynamic.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchContentShopDynamic.fulfilled, (state, action) => {
        const { data } = action.payload;
        if (data.getContentConstructorProShop) {
          state.data = data.getContentConstructorProShop;
        } else {
          state.data = data;
        }
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { clearDynamicContentShop } = dynamicHomeShopSlice.actions;

export const selectDataDynamicHomeShop = (state: RootState) =>
  state.dynamicShop.data;
export const loadingDynamicHomeShop = (state: RootState) =>
  state.dynamicShop.loading;
export default dynamicHomeShopSlice.reducer;
