import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface ChangeEmailData {
  emailSubscription: any;
  phone?: string;
  email?:string;
}

export const fetchChangeEmailData = createAsyncThunk(
  'changeEmail/changeEmailData',
  async (payload: ChangeEmailData, { getState, rejectWithValue }) => {
    try {
      const response = await request.put(`/api/change-email/update`, {
        emailSubscription: payload.emailSubscription,
        email: payload.email,
        phone: payload.phone,
      });
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ChangeEmailDataState {
  loading: boolean;
  error: any;
  isChanged: string;
  tabChanged: string;
}

const initialState: ChangeEmailDataState = {
  loading: false,
  error: null,
  isChanged: '',
  tabChanged: '',
};

export const updatePlayerDataSlice = createSlice({
  name: 'changeEmail',
  initialState,
  reducers: {
    selectChangeTabMyAccount: (state, action) => {
      state.tabChanged = action.payload;
    },
    clearErrorChangeEmailData: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChangeEmailData.pending, (state, action) => {
        state.loading = true;
        state.isChanged = 'wait';
      })
      .addCase(fetchChangeEmailData.fulfilled, (state, action) => {
        state.loading = false;
        state.isChanged = 'changed';
      })
      .addCase(fetchChangeEmailData.rejected, (state, action) => {
        state.loading = false;
        state.isChanged = '';
        state.error = action.payload;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { selectChangeTabMyAccount, clearErrorChangeEmailData } =
  updatePlayerDataSlice.actions;

export const selectAccountTabChange = (state: RootState) =>
  state.changeEmail.tabChanged;
export const loadingChangeEmailData = (state: RootState) =>
  state.changeEmail.loading;
export const checkChangeEmailData = (state: RootState) =>
  state.changeEmail.isChanged;
export const selectErrorChangeEmailData = (state: RootState) =>
  state.changeEmail.error;
export default updatePlayerDataSlice.reducer;
