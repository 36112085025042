import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PlayTrackingRecommendedData } from '../models/clubsOfBags';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const fetchClubsOfBags = createAsyncThunk(
  'clubOfBag/fetchClubsOfBags',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/clubs');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: PlayTrackingRecommendedData = {
  data: [],
  infoClubRecommended: 0,
  isOpen: false,
};

export const clubOfBagSlice = createSlice({
  name: 'clubOfBag',
  initialState,
  reducers: {
    selectClubRecommended: (state, action) => {
      state.infoClubRecommended = action.payload;
    },
    selectOpenClubRecommended: (state, action) => {
      state.isOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClubsOfBags.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.data = data;
    });
  },
});

export const { selectClubRecommended, selectOpenClubRecommended } =
  clubOfBagSlice.actions;

export const selectInfoClubRecommended = (state: RootState) =>
  state.clubOfBag.infoClubRecommended;
export const selectStatusOpenClubRecommend = (state: RootState) =>
  state.clubOfBag.isOpen;
export const selectClubOfBag = (state: RootState) => state.clubOfBag.data;
export default clubOfBagSlice.reducer;
