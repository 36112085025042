function returnBaseUrl() {
  let baseUrl = '';

  switch (window.location.hostname) {
    case 'clubhouseqa.golftec.cn':
      baseUrl = 'https://clubhouseqa.golftec.cn:8090';
      break;
    case 'clubhouse.golftec.cn':
      baseUrl = 'https://clubhouse.golftec.cn:8090';
      break;
    case 'clubhouseqa.golftec.com.sg':
      baseUrl = 'https://api-clubhouseqa3.golftec.com.sg';
      break;
    case 'clubhouse.golftec.com.sg':
      baseUrl = 'https://api-clubhouse.golftec.com.sg';
      break;
    case 'clubhouseqa.golftec.com.hk':
      baseUrl = 'https://api-clubhouseqa3.golftec.com.hk';
      break;
    case 'clubhouse.golftec.com.hk':
      baseUrl = 'https://api-clubhouse.golftec.com.hk';
      break;
    case 'clubhouseqa.golftec.ca':
      baseUrl = 'https://api-clubhousequebecstag.golftec.com';
      break;
    case 'clubhouse.golftec.ca':
      baseUrl = 'https://api-clubhousequebec.golftec.com';
      break;
    case 'clubhouse.golftec.com':
      baseUrl = 'https://api-clubhouseprod.golftec.com';
      break;
    case 'clubhousefieldtest.skytrakgolf.com':
      baseUrl = 'https://api-clubhouseprod.golftec.com';
      break;
    case 'clubhousedev.golftec.com':
      baseUrl = 'https://api-clubhousedev.golftec.com';
      break;
    case 'clubhouseqa.golftec.com':
      baseUrl = 'https://api-clubhousestag.golftec.com';
      break;
    case 'clubhousedev.golfdigest.co.jp':
      baseUrl = 'https://api-clubhousedev.golfdigest.co.jp';
      break;
    case 'clubhouseqa.golfdigest.co.jp':
      baseUrl = 'https://api-clubhouseqa.golfdigest.co.jp';
      break;
    case 'clubhouse.golfdigest.co.jp':
      baseUrl = 'https://api-clubhouse.golfdigest.co.jp';
      break;
    case 'clubhouse.skytrakgolf.com':
      baseUrl = 'https://api-clubhousefieldtest.golftec.com';
      break;
    case 'clubhouseqa.skytrakgolf.com':
      baseUrl = 'https://api-clubhousestag.golftec.com';
      break;
    case 'clubhousefieldtest.golftec.com':
      baseUrl = 'https://api-clubhouseprod.golftec.com';
      break;
    case 'clubhouseqa.golftec.ae':
      baseUrl = 'https://api-clubhouseqa.golftec.ae';
      break;
    case 'clubhouse.golftec.ae':
      baseUrl = 'https://api-clubhouse.golftec.ae';
      break;
    case 'clubhouseqa.golftec.co.za':
      baseUrl = 'https://api-clubhouseqa.golftec.co.za';
      break;
    case 'clubhouse.golftec.co.za':
      baseUrl = 'https://api-clubhouse.golftec.co.za';
      break;
    default:
      // baseUrl = 'https://api-clubhousefieldtest.golftec.com';
      //  baseUrl = 'https://api-clubhousedev.golftec.com';
      baseUrl = 'https://api-clubhousestag.golftec.com';
    // baseUrl = 'https://api-clubhouseprod.golftec.com';
  }
  return baseUrl;
}

export default returnBaseUrl;
