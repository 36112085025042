import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const requestRecommendedGears = createAsyncThunk(
  'recommendedGears/requestRecommendedGears',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/gear/recommended`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestRecommendedGearImages = createAsyncThunk(
  'recommendedGears/requestRecommendedGearImages',
  async (params: ParamsRecommendedGearsImage[], { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/gear/image`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestRecommendedGearOrder = createAsyncThunk(
  'recommendedGears/requestRecommendedGearOrder',
  async (params: ParamsRecommendedGearOrder, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/payment/invoke`, params);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestRecommendedGearStatus = createAsyncThunk(
  'recommendedGears/requestRecommendedGearStatus',
  async (requestRecommendedGearSessionId: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/payment/status?requestId=${requestRecommendedGearSessionId}`
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface ReveicedOrderRecommendedGear {
  id: string;
  url: string;
  favoriteUrl: string;
  total: number;
  email: string;
  selectedItems: RecommendedGearsFitting[];
  type: string;
  tax: number;
}
export interface ParamsRecommendedGearOrder {
  storeId: number;
  type: string;
  gear: RecommendedGearsData;
}
export interface ParamsRecommendedGearsImage {
  janCode: string;
  groupCode: string;
}

export interface RecommendedGearImage {
  image: '';
  janCode: string;
  groupCode: string;
}

export interface RecommendedGearsCoach {
  jCoachId: string;
  created: string;
  avatarUrl: string;
  realName: string;
  firstNameNative: string;
  lastNameNative: string;
}

export interface RecommendedGearsFitting {
  janCode: string;
  groupCode: string;
  expired: boolean;
  purchased: boolean;
  price: number;
  id: number;
  name: string;
  head: string;
  shaft: string;
  grip: string;
  type: string;
}

export interface RecommendedGearsData {
  class: string;
  date: string;
  expires: string;
  id: string;
  name: string;
  status: string;
  coach: RecommendedGearsCoach;
  discountRate: string;
  transactionNumber: string;
  listFitting: RecommendedGearsFitting[];
}

type RecommendedGearsState = {
  recommendedGears: null | RecommendedGearsData[];
  selectedRecommendedGear: null | RecommendedGearsData;
  recommendGearImage: null | RecommendedGearImage[];
  statusPaymenRecommendedGears: boolean;
  reveicedOrderGear: null | ReveicedOrderRecommendedGear;
  loadingRecommendedGears: boolean;
};

const initialState: RecommendedGearsState = {
  recommendedGears: null,
  selectedRecommendedGear: null,
  recommendGearImage: null,
  statusPaymenRecommendedGears: false,
  reveicedOrderGear: null,
  loadingRecommendedGears: false,
};

export const recommendedGearSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    changeSelectRecommendedGear: (state, action) => {
      state.selectedRecommendedGear = action.payload;
    },
    changeStatusPaymenRecommendedGears: (state, action) => {
      state.statusPaymenRecommendedGears = action.payload;
    },
    setReveicedOrderGear: (state, action) => {
      state.reveicedOrderGear = action.payload;
    },
    resetRecommendedGears: (state) => ({
      ...initialState,
      recommendedGears: state.recommendedGears,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestRecommendedGears.pending, (state, action) => {
      state.loadingRecommendedGears = true;
    });
    builder.addCase(requestRecommendedGears.fulfilled, (state, action) => {
      state.loadingRecommendedGears = false;
      state.recommendedGears = action.payload.data;
    });
    builder.addCase(requestRecommendedGears.rejected, (state, action) => {
      state.loadingRecommendedGears = false;
    });
    builder.addCase(requestRecommendedGearImages.fulfilled, (state, action) => {
      state.recommendGearImage = action.payload.data;
    });

    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  changeStatusPaymenRecommendedGears,
  changeSelectRecommendedGear,
  resetRecommendedGears,
  setReveicedOrderGear,
} = recommendedGearSlice.actions;

export const selectRecommendedGears = (state: RootState) =>
  state.recommendedGear.recommendedGears;

export const selectChoosenRecommendedGears = (state: RootState) =>
  state.recommendedGear.selectedRecommendedGear;

export const selectRecommendedGearImages = (state: RootState) =>
  state.recommendedGear.recommendGearImage;

export const selectStatusPaymenRecommendedGears = (state: RootState) =>
  state.recommendedGear.statusPaymenRecommendedGears;

export const selectReveicedOrderGear = (state: RootState) =>
  state.recommendedGear.reveicedOrderGear;

export const selectLoadingRecommendedGears = (state: RootState) =>
  state.recommendedGear.loadingRecommendedGears;

export default recommendedGearSlice.reducer;
