import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { NavigationMenu } from '../models/shop';
import {
  GET_PRODUCT_BY_SPECIAL_CATEGORY_ID,
  GET_SPECIAL_CATEGORIES,
} from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { CategoryInterface } from './../models/shop';

export const getSpecialCategoriesByTag = createAsyncThunk(
  'specialCategories/getSpecialCategoriesByTag',
  async (rootId: string, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_SPECIAL_CATEGORIES),
        variables: {
          id: rootId,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getProductBySpecialCategoryId = createAsyncThunk(
  'specialCategories/getProductBySpecialCategoryId ',
  async (payload: string[], { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_PRODUCT_BY_SPECIAL_CATEGORY_ID),
        variables: {
          id: payload,
          pageSize: 4,
          currentPage: 1,
          featuredPageSize: 4,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface specialCategoriesByTagState {
  loadingCategoryList: boolean;
  dataCategoryList: null | NavigationMenu;
  dataCategoryInterface: null | CategoryInterface[];
  loadingdataProductSpecialProductCategory: boolean;
}

const initialState: specialCategoriesByTagState = {
  loadingCategoryList: false,
  dataCategoryList: null,
  dataCategoryInterface: null,
  loadingdataProductSpecialProductCategory: false,
};

const specialCategoriesByTagSlice = createSlice({
  name: 'specialCategoriesByTag',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSpecialCategoriesByTag.pending, (state, action) => {
        state.loadingCategoryList = true;
      })

      .addCase(getSpecialCategoriesByTag.fulfilled, (state, action) => {
        state.loadingCategoryList = false;
        if (
          action.payload.data?.categoryList &&
          action.payload.data.categoryList.length > 0
        ) {
          state.dataCategoryList = action.payload.data.categoryList[0];
        }
      })
      .addCase(getSpecialCategoriesByTag.rejected, (state, action) => {
        state.loadingCategoryList = false;
      })
      .addCase(getProductBySpecialCategoryId.fulfilled, (state, action) => {
        state.loadingdataProductSpecialProductCategory = false;
        if (action.payload.data?.categories?.items) {
          state.dataCategoryInterface = action.payload.data?.categories.items;
        }
      })
      .addCase(getProductBySpecialCategoryId.pending, (state, action) => {
        state.loadingdataProductSpecialProductCategory = true;
      })
      .addCase(getProductBySpecialCategoryId.rejected, (state, action) => {
        state.loadingdataProductSpecialProductCategory = false;
      });
  },
});

export const selectSpecialCategoriesByTag = (state: RootState) =>
  state.specialCategoriesByTag.dataCategoryList;
export const loadingSpecialCategoriesByTag = (state: RootState) =>
  state.specialCategoriesByTag.loadingCategoryList;
export const selectSpecialProductCategoriesById = (state: RootState) =>
  state.specialCategoriesByTag.dataCategoryInterface;
export const loadingSpecialProductCategoriesById = (state: RootState) =>
  state.specialCategoriesByTag.loadingdataProductSpecialProductCategory;

export default specialCategoriesByTagSlice.reducer;
