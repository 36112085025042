import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import addAboutReducer from '../slices/addAbout';
import addPlayerScore from '../slices/addPlayerScore';
import addProductConfigurableToCartReducer from '../slices/addProductConfigurableToCart';
import addProductCustomizableToCartReducer from '../slices/addProductCustomizableToCart';
import addSimpleProductToCartReducer from '../slices/addProductSimpleToCart';
import referralFriendReducer from '../slices/addReferralFriend';
import gameTrackingReducer from '../slices/gameTracking';
import appointmentReducer from '../slices/appointment';
import challengeReducer from '../slices/challenge';
import changeEmailDataReducer from '../slices/changeEmail';
import confirmCodeReducer from '../slices/changeEmailConfirmCode';
import verifyEmailReducer from '../slices/changeEmailVerify';
import changePasswordReducer from '../slices/changePassword';
import configReducer from '../slices/config';
import downloadVideoReducer from '../slices/downloadVideo';
import dynamicHomeReducer from '../slices/dynamicContentHome';
import dynamicHomeShopReducer from '../slices/dynamicContentHomeShop';
import aboutReducer from '../slices/getAbout';
import bannerCategoryReducer from '../slices/getBannerCategory';
import categoryReducer from '../slices/getCategoryById';
import techfitReducer from '../slices/getClubFitting';
import clubOfBagReducer from '../slices/getClubsOfBags';
import dataJapanReducer from '../slices/getDataForJapan';
import listBuckReducer from '../slices/getListBucks';
import listCouponReducer from '../slices/getListCoupon';
import navigationMenuReducer from '../slices/getNavigationMenu';
import playGameHoleReducer from '../slices/getPlayGameHoleData';
import playGolfConfigReducer from '../slices/getPlayGolfConfig';
import playTrackingReducer from '../slices/getPlayTrackingDetail';
import productByCategoryReducer from '../slices/getProductByCategoryId';
import productDescriptionReducer from '../slices/getProductDescription';
import productDetailReducer from '../slices/getProductDetail';
import purchaseHistoryReducer from '../slices/getPurchased';
import referralReducer from '../slices/getReferral';
import searchProductReducer from '../slices/getResultsSearchProduct';
import getReturnsReducer from '../slices/getReturns';
import rootCategoryReducer from '../slices/getRootCategoryId';
import sharedVideoLessonReducer from '../slices/getSharedVideoLesson';
import valueAddCartReducer from '../slices/getValueAddToCart';
import languageReducer from '../slices/language';
import lessonPlanReducer from '../slices/lessonPlan';
import listCourseReducer from '../slices/listCourse';
import listLeaderBoardReducer from '../slices/listLeaderBoard';
import configurationReducer from '../slices/loadConfiguration';
import contentDynamicReducer from '../slices/loadHomeDynamic';
import lastLessonReducer from '../slices/loadLatestLastLesson';
import lessonByIdReducer from '../slices/loadLessonById';
import lessonHistoryReducer from '../slices/loadLessonHistory';
import notificationReducer from '../slices/notification';
import bayReducer from '../slices/playerScore';
import recommendedGearReducer from '../slices/recommendedGear';
import registerReducer from '../slices/register';
import removeItemInCartReducer from '../slices/removeItemInCart';
import renewalReducer from '../slices/renewal';
import renewalJapanReducer from '../slices/renewalJapan';
import resetPasswordReducer from '../slices/resetPassword';
import restoreLessonReducer from '../slices/restoreLesson';
import scheduleReducer from '../slices/schedule';
import shareVideoReducer from '../slices/shareVideo';
import shopReducer from '../slices/shop';
import skyTrakReducer from '../slices/skytrak';
import statHistoryReducer from '../slices/statHistory';
import subscriptionsHistoryReducer from '../slices/subscriptionsHistory';
import supportReducer from '../slices/support';
import updatePlayerDataReducer from '../slices/updatePlayerData';
import updateQuantityMiniCartReducer from '../slices/updateQuantityMiniCart';
import userReducer from '../slices/user';
import waitingSlotModalReducer from '../slices/waitingSlotModal';
import subCategoryReducer from '../slices/getSubCategory';
import techPutReducer from '../slices/getPutterFitting';
import myBagsReducer from '../slices/myBags';
import mySwingsReducer from '../slices/mySwings';
import specialCategoriesByTagReducer from '../slices/getSpecialCategoriesByTag';
import scoreCardReducer from '../slices/scoreCard';
import myRoundReducer from '../slices/myRound';
import activitySkyTrakReducer from '../slices/activitySkytrak';
import shopGolftecPlusReducer from '../slices/shopGolftecPlus';
import shopSkytrakReducer from '../slices/shopSkytrak';
import golftecPlusReducer from '../slices/golftecPlus';
import videoLibraryReducer from '../slices/videoLibrary';

const rootReducer = combineReducers({
  appointment: appointmentReducer,
  config: configReducer,
  language: languageReducer,
  user: userReducer,
  challenge: challengeReducer,
  bay: bayReducer,
  addScore: addPlayerScore,
  listCourse: listCourseReducer,
  gameTracking: gameTrackingReducer,
  configuration: configurationReducer,
  listLeaderBoard: listLeaderBoardReducer,
  techfit: techfitReducer,
  about: aboutReducer,
  addAbout: addAboutReducer,
  lessonById: lessonByIdReducer,
  lastLesson: lastLessonReducer,
  history: lessonHistoryReducer,
  schedule: scheduleReducer,
  downloadVideo: downloadVideoReducer,
  shareVideo: shareVideoReducer,
  restore: restoreLessonReducer,
  register: registerReducer,
  shop: shopReducer,
  home: contentDynamicReducer,
  productByCategoryId: productByCategoryReducer,
  category: categoryReducer,
  searchProduct: searchProductReducer,
  bannerCategory: bannerCategoryReducer,
  productDetail: productDetailReducer,
  password: resetPasswordReducer,
  productSimple: addSimpleProductToCartReducer,
  productCustomizable: addProductCustomizableToCartReducer,
  productConfigurable: addProductConfigurableToCartReducer,
  rootCategory: rootCategoryReducer,
  navigationMenu: navigationMenuReducer,
  selectValueAddCart: valueAddCartReducer,
  renewal: renewalReducer,
  updateQuantity: updateQuantityMiniCartReducer,
  removeItem: removeItemInCartReducer,
  updatePlayer: updatePlayerDataReducer,
  changePass: changePasswordReducer,
  changeEmail: changeEmailDataReducer,
  verifyEmail: verifyEmailReducer,
  confirmCode: confirmCodeReducer,
  dynamicShop: dynamicHomeShopReducer,
  dynamicHome: dynamicHomeReducer,
  support: supportReducer,
  purchaseHistory: purchaseHistoryReducer,
  notification: notificationReducer,
  productDescription: productDescriptionReducer,
  listBuck: listBuckReducer,
  referral: referralReducer,
  referralFriend: referralFriendReducer,
  sharedVideo: sharedVideoLessonReducer,
  renewalJapan: renewalJapanReducer,
  recommendedGear: recommendedGearReducer,
  dataJapan: dataJapanReducer,
  listCoupon: listCouponReducer,
  waitingSlotModal: waitingSlotModalReducer,
  lessonPlan: lessonPlanReducer,
  subscriptionHistory: subscriptionsHistoryReducer,
  subCategory: subCategoryReducer,
  techPut: techPutReducer,
  myBags: myBagsReducer,
  mySwing: mySwingsReducer,
  specialCategoriesByTag: specialCategoriesByTagReducer,
  scoreCard: scoreCardReducer,
  myRound: myRoundReducer,
  playGameHole: playGameHoleReducer,
  clubOfBag: clubOfBagReducer,
  playTracking: playTrackingReducer,
  skyStrak: skyTrakReducer,
  returns: getReturnsReducer,
  statHistory: statHistoryReducer,
  activitySkytrak: activitySkyTrakReducer,
  playGolfConfig: playGolfConfigReducer,
  shopSkytrak: shopSkytrakReducer,
  golftecPlus: golftecPlusReducer,
  shopGolftecPlus: shopGolftecPlusReducer,
  videoLibrary: videoLibraryReducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['user', 'config', 'language'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
